import React, { useState } from 'react';
import { Box, Typography,  Switch, FormControlLabel, TextField, Button, CircularProgress, Checkbox, FormGroup, FormLabel, FormControl, Avatar, Grid } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Papa from 'papaparse'; // Assuming PapaParse is installed for CSV parsing
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info'; // Import the InfoIcon


function ContactsCreationForm({ authToken, userEmail, ...props}) {
    const [isBulkUpload, setIsBulkUpload] = useState(false);
    const [expanded, setExpanded] = useState(true); // State to control Accordion expansion

    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyUrl, setCompanyUrl] = useState('');

    const [selectedFile, setSelectedFile] = useState(null); // State for selected CSV file
    const [csvError, setCsvError] = useState(false); // Initialize as an empty string
    const [csvData, setCsvData] = useState([]); // State to hold parsed CSV data

    const [location, setLocation] = useState('');
    const [assignToMe, setAssignToMe] = useState(true);
    const [contactType, setContactType] = useState({
        decisionMakers: true,
        hrLeaders: false,
    });

    // New state variable to manage the disabled state of the Process button
    const [isProcessDisabled, setIsProcessDisabled] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleAccordionChange = (isExpanded) => {
        setExpanded(isExpanded);
    };

    const handleToggleChange = (event) => {
        setIsBulkUpload(event.target.checked);
        // Reset CSV error state when switching modes
        setCsvError(false);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file.name); // Update this line to store the file name
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                parseCsv(text)
                    .then(data => {
                        console.log("Parsed data:", data);
                        // Enable the Process button if CSV parsing is successful
                        setIsProcessDisabled(false);
                        // Proceed with bulk upload
                        handleBulkUpload(data);
                    })
                    .catch(error => {
                        console.error(error);
                        // Disable the Process button if CSV parsing fails
                        setIsProcessDisabled(true);
                        // Optionally set CSV error message
                        setCsvError(error);
                    });
            };
            reader.readAsText(file);
        }
    };
            

    const parseCsv = (csvText) => {
        console.log("Parsing CSV...");
        console.log(csvText);
        return new Promise((resolve, reject) => {
            Papa.parse(csvText, {
                header: true,
                // delimiter: ";", // If your CSV uses semicolons instead of commas
                delimiter: ",", // Ensure this matches the actual delimiter used in your CSV
                quoteChar: '"', // Default is double quotes, but you can change this if needed
                escapeChar: '"', // Default is double quotes, but you can change this if needed
                skipEmptyLines: true, // This will ignore empty lines

                complete: (results) => {
                    const { data, errors } = results;
                    console.log("Parsed Data: ", data);
                    console.error("Parsing Errors: ", errors);
                    if (errors.length > 0) {
                        console.error('CSV parsing error:', errors);
                        reject("CSV parsing error");
                    } else {
                        const isValid = data.every(row => {
                            console.log(row); // Temporarily log each row to inspect its data
                            const isEmptyRow = Object.values(row).every(value => value === "");
                            if (isEmptyRow) return true; // Skip validation for empty rows
                            return row['name'] && row['url'] && row['location'];
                        });
                        if (!isValid) {
                            reject("Invalid CSV format");
                        } else {
                            // No need to set CSV error or data state here; handle that in .then() or .catch()
                            resolve(data); // Resolve with parsed data
                        }
                    }
                },
            });
        });
    };

    

    const handleBulkUpload = async (companies) => {
        setLoading(true);
        setIsProcessDisabled(true); // Disable the Process button

        // Map over companies to add contactType, status, and assignTo fields
        const companiesWithDetails = companies.map(company => ({
            ...company, // Spread the existing company details
            contactType: Object.keys(contactType).filter(key => contactType[key]),
            status: 'new', // Set the status for all companies as 'new'
            assignTo: assignToMe ? userEmail : '' // Set the assignee based on the assignToMe flag
        }));
        
        try {
            const response = await fetch(`${API_BASE_URL}/save-bulk-companies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ companies: companiesWithDetails }), // Wrap in an object if your backend expects it
            });
    
            if (!response.ok) {
                console.log('Failed to upload companies');
                throw new Error('Failed to upload companies');
            }
    
            // Handle success
            const responseData = await response.json();
            console.log('Bulk upload successful:', responseData);
            props.onCompanyProcessed(); // Notify parent to refresh company list
            setSnackbarMessage('Companies processed successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error uploading companies:', error);
            setSnackbarMessage('Failed to upload companies');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
            setIsProcessDisabled(false); // Re-enable the Process button
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (!authToken) {
            console.error("User token not available");
            setLoading(false);
            return;
        }

        const company_details = {
            name: companyName,
            url: companyUrl,
            location: location,
            contactType: Object.keys(contactType).filter(key => contactType[key]),
            status: 'new',
            assignTo: assignToMe ? userEmail : '' // Include the email address of the logged-in user
        };
        console.log("Company:", company_details);

        setTimeout(async () => { // Simulate a network request delay

            try {
                // Send the company object to the backend
                const response = await fetch(`${API_BASE_URL}/save-company-details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    body: JSON.stringify(company_details),
                });
                if (!response.ok) {
                    throw new Error('Failed to process company');
                }
                // Reset form fields
                setCompanyName('');
                setCompanyUrl('');
                setLocation('');
                setContactType({
                    decisionMakers: false,
                    hrLeaders: false,
                });

                // get the response data
                const responseData = await response.json(); // Read the response body once and store it
                if (responseData.status === "success") {
                    console.log('Company details saved:', responseData);
                    // Collapse the accordion
                    setExpanded(false);
                    // Notify parent component to refetch companies if needed
                    if (props.onCompanyProcessed) {
                        props.onCompanyProcessed();
                    }
                    // Show success message
                    setSnackbarMessage('Company processed successfully');
                    setSnackbarOpen(true);
                } else {
                    console.error('Failed to process company:', responseData);
                    setSnackbarMessage('Failed to process company');
                    setSnackbarOpen(true);
                }   
            } catch (error) {
                // Handle any errors
                setSnackbarMessage('Failed to process company');
                setSnackbarOpen(true);
                console.error('Error processing company:', error);
            } finally {
                setLoading(false);
            }
        }, 3000); // Simulate a network request delay
    };

    const handleProcessButtonClick = async () => {
        // Call handleBulkUpload with the stored CSV data
        if (csvData.length > 0) {
            await handleBulkUpload(csvData);
        } else {
            console.error("No CSV data to process");
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleChange = (event) => {
        setContactType({
        ...contactType,
        [event.target.name]: event.target.checked,
        });
        console.log(contactType)
    };

    return (

        <Accordion expanded={expanded} onChange={() => handleAccordionChange(!expanded)} >
            <AccordionSummary 
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="panel1a-content" 
                id="panel1a-header"
                sx={{
                backgroundColor: 'primary.light', // Use your theme colors
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
                '&:hover': {
                    backgroundColor: 'primary.main', // Darken on hover
                    color: 'white', // Set text color to white
                },
                }}
            >
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: 'white' }}>
                Add Companies to Process
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4, mt:2 }}>
                        <FormControlLabel
                            control={<Switch checked={isBulkUpload} onChange={handleToggleChange} />}
                            label="Individual Company"
                            labelPlacement="start"
                            sx={{ mr: 2 }}
                        />
                        <FormControlLabel
                            control={<></>}
                            label="Bulk Upload"
                            labelPlacement="end"
                            sx={{  }}
                        />
                    </Box>

                    <FormControl component="fieldset">
                        <FormLabel component="legend">Type of Contact You Want</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                            control={<Checkbox checked={contactType.decisionMakers} onChange={handleChange} name="decisionMakers" />}
                            label="Decision Makers"
                            />
                            <FormControlLabel
                            control={<Checkbox checked={contactType.hrLeaders} onChange={handleChange} name="hrLeaders" />}
                            label="HR Leaders"
                            />
                        </FormGroup>
                    </FormControl>

                    {!isBulkUpload ? (
                    <Box>
                        <TextField
                        label="Company Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        />
                        <TextField
                        label="URL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={companyUrl}
                        onChange={(e) => setCompanyUrl(e.target.value)}
                        />
                        <TextField
                        label="Location"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        />
                    </Box>
                    ) : (
                    <Box sx={{ mb: 2, mt: 2 }}>
                        <Button 
                            variant="contained" 
                            component="label" 
                            fullWidth
                        >
                            Upload CSV
                            <input 
                                type="file" 
                                hidden
                                onChange={handleFileChange}
                                accept=".csv"                
                            />
                        </Button>
                        {selectedFile && (
                            <Typography sx={{ mt: 2 }}>File selected: {selectedFile}</Typography>
                        )}
                        {csvError && (
                        <Typography color="error" sx={{ mt: 2 }}>CSV parsing error. Please check the file format.</Typography>
                        )}

                        <Alert
                                icon={<InfoIcon fontSize="inherit" />}
                                severity="info"
                                sx={{ mt: 2, mb: 2 }}
                            >
                                <Typography component="div" variant="body2"> {/* Change here */}
                                    Please upload a CSV file with the following format:
                                    <ul>
                                        <li>Columns: "name", "url", "location"</li>
                                        <li>Each row represents one company</li>
                                        <li>Ensure there are no extra spaces or special characters in the headers</li>
                                        <li>Location values that include commas should be enclosed in double quotes (e.g., "San Francisco, CA")</li>
                                    </ul>
                                </Typography>
                            </Alert>
                    </Box>
                    )}
                   
                    
                    <FormGroup>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar src="/pipedrive_logo.png" sx={{ width: 44, height: 44, ml: 1, mr:2, mt:3 }} />
                            <FormControlLabel 
                            control={<Checkbox checked={assignToMe} onChange={(e) => setAssignToMe(e.target.checked)} />} 
                            label="Automatically assign to me in Pipedrive" 
                            />
                        </Box>
                    </FormGroup>

                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <Avatar src="/rocketreach_logo.jpeg" sx={{ width: 56, height: 56, mr: 0 }} />
                        <Checkbox checked={true} disabled={true} />
                        <Typography>Data source: RocketReach</Typography>
                    </Box>

                    <Grid container justifyContent="center" sx={{ mt: 4 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={isBulkUpload ? handleProcessButtonClick : handleSubmit} // Use handleProcessButtonClick for bulk upload
                            disabled={!!(loading || (isBulkUpload && csvError))} // Use !! to ensure a boolean value
                            sx={{ width: '50%' }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Add'}
                        </Button>
                    </Grid>
                    {/* Snackbar for success/error messages */}
                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.startsWith('Failed') ? 'error' : 'success'}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default ContactsCreationForm;

