import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    Switch,
    Box,
    CircularProgress,
} from '@mui/material';
import { format } from 'date-fns';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getAuth, onAuthStateChanged } from 'firebase/auth';



const MydraNewCoursesPage = () => {
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProduction, setIsProduction] = useState(true);
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null); // Assuming you want to store the user's email as well
    const navigate = useNavigate();

    const isInitialLoad = useRef(true); // Correct placement of useRef

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    console.log('User logged in:', user.email);
                    setAuthToken(token);
                });
            } else {
                console.log('User not logged in');
                // Optionally redirect to login page here
            }
        });
    }, []);

    useEffect(() => {

        setIsLoading(true);

        const fetchCourses = async () => {
            if (authToken) {  // Ensure the token is available before fetching
                const formattedStartDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]; // 7 days ago
                // const formattedEndDate = new Date().toISOString().split('T')[0]; // today
                // Create a new Date object for "today" and add one day to it
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1); // Adds one day
                const formattedEndDate = tomorrow.toISOString().split('T')[0]; // tomorrow

                try {
                    const url = `${API_BASE_URL}/mydra/courses-by-date?createdAtStart=${formattedStartDate}&createdAtEnd=${formattedEndDate}&env=${isProduction ? 'Production' : 'Staging'}`;
                    const response = await fetch(url, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'Content-Type': 'application/json',
                            'X-User-Email': userEmail, // Use the email parameter
                            'X-Environment': isProduction ? 'Production' : 'Staging',
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    setCourses(data);
                    console.log('Courses:', data);
                } catch (error) {
                    console.error('Failed to fetch courses:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchCourses();
        const interval = setInterval(fetchCourses, 30000); // Fetch every 30 seconds
        return () => clearInterval(interval); // Clear interval on component unmount

    }, [authToken, isProduction, userEmail]);

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleRowClick = (courseId) => {
        // Pass the environment as a query parameter if it is not Production
        // navigate(`/mydra/course/${courseId}${!isProduction ? '?env=Staging' : ''}`);
        const url = `/mydra/course/${courseId}${!isProduction ? '?env=Staging' : ''}`;
        console.log('Navigating to:', url);
        window.open(url, '_blank'); // '_blank' opens the URL in a new tab

    };

    const tableRowStyle = {
        cursor: 'pointer', // Change cursor to pointer on hover
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Adds a light grey background on hover
        }
    };

    const formatDateCohorts = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
      };


    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography 
                variant="h4" 
                align="center"
                sx={{
                    my: 4,
                    fontWeight: 'bold',
                    color: 'primary.main',
                  }}
            >
                    Recently Created Courses
            </Typography>
            
            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            {isLoading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Provider</TableCell>
                                {/* <TableCell>Provider Name</TableCell> */}
                                <TableCell>Course Name</TableCell>
                                <TableCell>Cost</TableCell>
                                <TableCell>Next Cohort</TableCell>
                                <TableCell>Category</TableCell> {/* Add this line */}
                                <TableCell>Course Id</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Published</TableCell>           
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {courses.map((course, index) => (
                                <TableRow key={course._id} onClick={() => handleRowClick(course._id)} sx={tableRowStyle}>
                                    <TableCell>{index + 1}</TableCell> {/* Display the index, starting from 1 */}
                                    {/* <TableCell>
                                        {course.educationProvider.logoUrl ? (
                                            <Box display="flex" alignItems="center">
                                                <img
                                                    src={course.educationProvider.logoUrl}
                                                    alt="Provider Logo"
                                                    style={{ width: 50, height: 50, marginRight: 8 }} // Adjust size and spacing as needed
                                                />     
                                            </Box>
                                        ) : (
                                            <Typography variant="body2">No Logo Available</Typography>
                                        )}
                                    </TableCell>
                                    <TableCell>{course.educationProvider.name}</TableCell> */}
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            {course.educationProvider.logoUrl ? (
                                            <img
                                                src={course.educationProvider.logoUrl}
                                                alt="Provider Logo"
                                                style={{ width: 40, height: 40, marginRight: 8 }}
                                            />
                                            ) : (
                                            <Box width={40} height={40} bgcolor="grey.300" marginRight={1} display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant="caption">No Logo</Typography>
                                            </Box>
                                            )}
                                            <Typography variant="body2">{course.educationProvider.name}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{course.name}</TableCell>
                                    <TableCell>
                                    {course.cohorts && course.cohorts[0]?.cost
                                        ? `${(course.cohorts[0].cost.amount / 100).toFixed(2)} ${course.cohorts[0].cost.currency}`
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        {course.cohorts && course.cohorts[0]?.startDate && course.cohorts[0]?.endDate
                                            ? `${formatDateCohorts(course.cohorts[0].startDate)} - ${formatDateCohorts(course.cohorts[0].endDate)}`
                                            : 'Self-paced'}
                                    </TableCell>

                                    <TableCell>{course.categories.map(category => category.name).join(', ')}</TableCell> {/* Add this line */}
                                    <TableCell>{course._id}</TableCell>
                                    <TableCell>{format(new Date(course.createdAt), 'yyyy-MM-dd HH:mm')}</TableCell> {/* Update this line */}
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body2" component="span">
                                                {course.published ? "Yes" : "No"}
                                            </Typography>
                                            <FiberManualRecordIcon
                                                sx={{ color: course.published ? 'green' : 'red', ml: 1 }}
                                            />
                                        </Box>
                                    </TableCell>                                    
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default MydraNewCoursesPage;

