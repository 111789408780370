import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    Container, 
    Typography, 
    Box, 
    CircularProgress, 
    Button, 
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    LinearProgress,
    Snackbar,
    Alert,
    Chip
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'; // Import the back arrow icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the check circle icon
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import EducationProviderInfo from '../EducationProviderInfo';
import EducationProviderIdentifiedData from '../EducationProviderIdentifiedData/EducationProviderIdentifiedData';
import { useTheme } from '@mui/material/styles';

// backend authentification
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import app from '../../firebaseConfig';

const EducationProviderDetails = () => {
    const { site_id } = useParams(); // Get the site_id from the URL parameters
    const [provider, setProvider] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const theme = useTheme(); // Access theme for consistent styling

    const [statusAnalysis, setStatusAnalysis] = useState('');
    const [statusClassification, setStatusClassification] = useState('');
    const [statusCrawling, setStatusCrawling] = useState('');

    const [matchedCoursesCount, setMatchedCoursesCount] = useState(0);

    // console.log('Status Crawling:', statusCrawling);

    // Identified data for this provider
    const [identifiedData, setIdentifiedData] = useState({
        identified_urls: [],
        linkedin_urls: [],
        email_links: [],
    });

    let timeoutId; // Timeout ID for the setTimeout call
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;    
    const [token, setToken] = useState(null); // New state for token
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [isProduction, setIsProduction] = useState(true);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isMatching, setIsMatching] = useState(false); // State for matching button
    const [connectionStatus, setConnectionStatus] = useState({
        Staging: '',
        Production: '',
    });

    const db = getFirestore(app);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    


    // backend authentification
    const getToken = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            return await user.getIdToken();
        } else {
            console.log('User not logged in');
            navigate('/login'); // Redirect to login page if user is not logged in
            return null;
        }
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        const fetchToken = async () => {
            const currentUserToken = await getToken(); // Assuming getToken() is your method to get the current user's token
            setToken(currentUserToken);
        };
    
        fetchToken();
    }, []);

    useEffect(() => {
        if (statusCrawling === 'processing') {

            // wait for 5 seconds before starting polling
            setTimeout(() => {
                console.log('Starting polling...');
                startPolling();
            }
            , 5000); // 5 seconds delay
            
        }
    }, [statusCrawling]);

    useEffect(() => {
        const initialFetch = async () => {
            await fetchAndUpdateProviderDetails();
            startPolling();
        };
        initialFetch();
    
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [site_id]);
    
    

    useEffect(() => {
        const fetchProviders = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
                'X-Environment': isProduction ? 'Production' : 'Staging',
                'X-User-Email': userEmail || '',
            };
            try {
                const response = await fetch(`${API_BASE_URL}/mydra/education-providers`, { headers });
                if (response.ok) {
                    const data = await response.json();
                    console.log('Education Providers:', data.data);
                    setProviders(data.data || []);
                } else {
                    console.error('Failed to fetch education providers');
                }
            } catch (error) {
                console.error('Error fetching education providers:', error);
            }
        };
    
        if (authToken && userEmail) {
            console.log('Fetching education providers');
            fetchProviders();
        }
    }, [authToken, userEmail, isProduction]);

    useEffect(() => {
        const calculateMatchedCourses = () => {
            const matchedCount = identifiedData.identified_urls.filter(course => 
                course.mydra_status && course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'uploaded'
            ).length;
            setMatchedCoursesCount(matchedCount);
        };
    
        if (identifiedData.identified_urls.length > 0) {
            calculateMatchedCourses();
        }
    }, [identifiedData, isProduction]);


    const fetchAndUpdateProviderDetails = async () => {
        const token = await getToken(); // Get the current user's ID token
        if (!token) return; // Exit if there's no token (user not logged in)
        
        try{    
            const response = await fetch(`${API_BASE_URL}/provider/${site_id}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            const data = await response.json();
            
            console.log('Provider:', data);

            setProvider(data);
            setStatusAnalysis(data.status_analysis || '');
            setStatusClassification(data.status_classification || '');
            setStatusCrawling(data.status_crawling_process || '');
            console.log('Status Crawling (inside fetchAndUpdateProviderDetails):', data.status_crawling_process);

            // set connection status
            setConnectionStatus({
                Staging: data.mydra_education_provider?.Staging || '',
                Production: data.mydra_education_provider?.Production || '',
            });

            // Fetch identified data
            fetchIdentifiedData(token);

            setLoading(false);
            console.log('Provider:', data);
            console.log('Status:', data.status);

            if (
                data.status_ep_analysis === 'completed' && 
                data.status_crawling_process !== 'processing'
            ) {
                console.log('Clearing timeout');
                clearTimeout(timeoutId); // Clear the timeout
            } else {
                // Continue polling if status_ep_analysis is 'processing'
                console.log('Setting timeout');
                // print stastus crawling and status analysis
                console.log("status_crawling_process: ", data.status_crawling_process);
                console.log("status_ep_analysis: ", data.status_analysis);

                timeoutId = setTimeout(fetchAndUpdateProviderDetails, 10000);
            }
        
        } catch (error) {
            console.error('Error fetching provider details:', error);
            setError(error.toString());
            setLoading(false);
        }
    };

    const fetchIdentifiedData = async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL}/provider/${site_id}/identified-data`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            console.log('Identified Data:', data);
            setIdentifiedData({
                identified_urls: data.identified_urls || [],
                linkedin_urls: data.linkedin_urls || [],
                email_links: data.email_links || [],
            });

        } catch (error) {
            console.error('Error fetching identified data:', error);
            setError(error.toString());
        }
    };

    const handleCrawlClick = async () => {
        // Update the status to processing

        // print the status of the crawling proces
        console.log('Status Crawling (inside handleCrawlClick) - 1:', statusCrawling);
        setStatusCrawling('processing');
        // print the status of the crawling proces
        console.log('Status Crawling (inside handleCrawlClick) - 2 :', statusCrawling);
    
        console.log('Triggering crawl for site_id:', site_id);

        const token = await getToken();
        if (!token) return;

        // print the status of the crawling proces
        console.log('Status Crawling (inside handleCrawlClick) - 3:', statusCrawling);
        
        try {
            const response = await fetch(`${API_BASE_URL}/crawl-provider`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url: provider.url, site_id: site_id }),
            });

            // print the status of the crawling proces
            console.log('Status Crawling (inside handleCrawlClick) - 4:', statusCrawling);

            // introduce a 5 seconds delay before starting polling
            await new Promise((resolve) => setTimeout(resolve, 5000));

            // print the status of the crawling proces
            console.log('Status Crawling (inside handleCrawlClick) - 5:', statusCrawling);


            const data = await response.json();

            // print the status of the crawling proces
            console.log('Status Crawling (inside handleCrawlClick) - 6:', statusCrawling);

            if (data.message === "Job triggered successfully") {
                console.log('Crawling started successfully');

                setSnackbarMessage('Crawling started successfully.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);

                // print the status of the crawling proces
                console.log('Status Crawling (inside handleCrawlClick) - 7:', statusCrawling);

                // Delay before starting polling
                setTimeout(() => {
                    console.log('Starting polling...');
                    // print the status of the crawling proces
                    console.log('Status Crawling (inside handleCrawlClick) - 8:', statusCrawling);
                    startPolling();
                }, 5000); // 5 seconds delay
            } else {
                console.error('Failed to start crawling:', data.message);
                // print the status of the crawling proces
                console.log('Status Crawling (inside handleCrawlClick) - 9:', statusCrawling);
                throw new Error(data.message || 'Failed to start crawling');
            }
        } catch (error) {
            console.error('Error starting crawling:', error);
            setError(error.toString());
        }
    };

    const handleConnectClick = async () => {
        if (selectedProvider) {
            setIsConnecting(true);

            try {
                const siteDocRef = doc(db, 'sites', site_id);
                // Get the current value of mydra_education_provider field
                const siteDoc = await getDoc(siteDocRef);
                const currentData = siteDoc.data();

                // Update the specific environment (staging or production) with the selected provider ID
                const updateData = {
                    mydra_education_provider: {
                    ...currentData.mydra_education_provider, // Spread the existing data (if any)
                    [isProduction ? 'Production' : 'Staging']: selectedProvider, // Use selectedProvider directly as the provider's _id
                    },
                };
                await updateDoc(siteDocRef, updateData);
                console.log('Education provider connected successfully');

                 // Update the connections document for the current environment
                const environment = isProduction ? 'production' : 'staging';
                const connectionsDocRef = doc(db, 'mydra_ep_connections', environment);
                const connectionsUpdate = {};
                connectionsUpdate[selectedProvider] = site_id; // Set education_provider_id as key and site_id as value

                await updateDoc(connectionsDocRef, connectionsUpdate);


                 // Simulate a delay of 2 seconds
                await new Promise((resolve) => setTimeout(resolve, 2000));
                // Optionally, you can show a success message to the user
                // Update the connection status
                setConnectionStatus({
                    ...connectionStatus,
                    [isProduction ? 'Production' : 'Staging']: selectedProvider,
                });

            } catch (error) {
                console.error('Error connecting education provider:', error);
                alert('Failed to connect education provider');
            } finally {
                setIsConnecting(false);
            }
        }
    };

    const matchCourses = async () => {
        setIsMatching(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/match_courses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                },
                body: JSON.stringify({
                    site_id: site_id,
                    environment: isProduction ? 'Production' : 'Staging',
                    courses: identifiedData.identified_urls,
                    educationProviderId: isProduction ? connectionStatus.Production : connectionStatus.Staging,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to match courses');
            }
    
            const responseData = await response.json();
            console.log('Matched Courses:', responseData.matched_courses);

            // Update the count of matched courses
            setMatchedCoursesCount(responseData.matched_courses.length);

            setSnackbarMessage('Courses matched successfully.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
    
            // Refetch identified data
            fetchIdentifiedData(authToken);
        } catch (error) {
            console.error('Error matching courses:', error);
            setSnackbarMessage('Failed to match courses.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setIsMatching(false);
        }
    };
    
    const startPolling = () => {
        let pollingCount = 0;
        const maxInitialPollingTime = 120000; // 2 minutes
        const maxExtendedPollingTime = 600000; // 10 minutes
        const initialPollingInterval = 10000; // 10 seconds
        const extendedPollingInterval = 30000; // 30 seconds
    
        const poll = async () => {
            console.log(`Polling count: ${pollingCount}, Status: ${statusCrawling}`);
    
            if (pollingCount < maxInitialPollingTime / initialPollingInterval) {
                await fetchAndUpdateProviderDetails();
                if (statusCrawling === 'processing') {
                    timeoutId = setTimeout(poll, initialPollingInterval);
                    pollingCount++;
                } else {
                    clearTimeout(timeoutId);
                }
            } else if (pollingCount < (maxInitialPollingTime + maxExtendedPollingTime) / extendedPollingInterval) {
                await fetchAndUpdateProviderDetails();
                if (statusCrawling === 'processing') {
                    timeoutId = setTimeout(poll, extendedPollingInterval);
                    pollingCount++;
                } else {
                    clearTimeout(timeoutId);
                }
            } else {
                clearTimeout(timeoutId);
            }
        };
    
        if (statusCrawling === 'processing') {
            poll();
        }
    };

    if (loading) {
      return (
        <Container>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
            <CircularProgress />
          </Box>
        </Container>
      );
    }

    if (error) {
      return (
        <Container>
          <Typography variant="h6" color="error">Error: {error}</Typography>
        </Container>
      );
    }

    const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    
    const getContentTypeDescription = (contentType) => {
        switch (contentType) {
            case 'instructor-led':
                return 'Instructor-led';
            case 'recorded':
                return 'Recorded';
            case 'both':
                return 'Both, instructor-led and recorded';
            default:
                return 'Not specified'; // or any default text you prefer
        }
    };

    const generateMydraHubUrl = (providerId, providerName) => {
        return `https://www.mydrahub.com/mydra/provider/${providerId}/${encodeURIComponent(providerName)}`;
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 6 }}>

          <Button
              variant="outlined" // Outlined button style
              startIcon={<ArrowBackIosNewIcon />} // Icon at the start
              onClick={handleBack}
              sx={{
                  mb: 2, // Margin bottom to space it from the title area
                  color: theme.palette.secondary.main, // Button text color
                  borderColor: theme.palette.secondary.light, // Button border color
                  position: 'relative', // Absolutely positioned relative to the parent Box
                  top: 0, left: 0, // Positioned at the top-left corner of the parent Box
              }}
          >
              Go Back
          </Button>    
        
          {provider && (
            <>
            <EducationProviderInfo 
                provider={provider}
                statusAnalysis={statusAnalysis}
                statusClassification={statusClassification}
                getContentTypeDescription={getContentTypeDescription}
                refreshProviderDetails={fetchAndUpdateProviderDetails} // Pass this as a prop
                token={token} // Pass the token here
            />
            {statusCrawling === 'processing' ? (
                <>
                <Button
                    variant="contained"
                    color="primary"
                    disabled
                    sx={{
                        mb: 2,
                        position: 'relative',
                        overflow: 'hidden',
                        '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255, 255, 255, 0.3)',
                        transform: 'translateX(-100%)',
                        animation: 'pulse 1.5s infinite',
                        },
                        '@keyframes pulse': {
                        '0%': {
                            transform: 'translateX(-100%)',
                        },
                        '50%': {
                            transform: 'translateX(100%)',
                        },
                        '100%': {
                            transform: 'translateX(100%)',
                        },
                        },
                    }}
                    startIcon={<CircularProgress size={20} />}
                    >
                    Identification in progress...
                    </Button>
                    <Box sx={{ position: 'relative', mb: 2 }}>  
                        <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} />
                    </Box>
                   
                </>
            ) : (
            
                <Button
                    variant="contained"
                    color={statusCrawling === 'complete' ? 'success' : 'primary'}
                    onClick={handleCrawlClick}
                    sx={{
                        mb: 4,
                        backgroundColor: statusCrawling === 'complete' ? 'success.main' : 'primary.main',
                        '&:hover': {
                        backgroundColor: statusCrawling === 'complete' ? 'success.dark' : 'primary.dark',
                        },
                        '&:disabled': {
                        backgroundColor: statusCrawling === 'complete' ? 'success.light' : 'primary.light',
                        color: 'white',
                        },
                    }}
                    disabled={statusCrawling === 'processing' || statusCrawling === 'complete'}
                    startIcon={statusCrawling === 'complete' && <CheckCircleOutlineIcon />}
                    >
                    {statusCrawling === 'processing' ? 'Identification in progress...' : statusCrawling === 'complete' ? 'Identification Process Complete' : 'Identify Courses'}
                    </Button>
            )}
            <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom>Identified data:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            {statusCrawling === 'processing' && <CircularProgress size={20} sx={{ mr: 2 }} />}
                            <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', mr: 1 }}>{identifiedData.identified_urls.length}</Typography>
                            <Typography variant="body1" component="span">Courses</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <Typography variant="h6" component="span" sx={{ mr: 1 }}>| {identifiedData.linkedin_urls.length}</Typography>
                            <Typography variant="body1" component="span">LinkedIn Profiles</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" component="span" sx={{ mr: 1 }}>| {identifiedData.email_links.length}</Typography>
                            <Typography variant="body1" component="span">Email Links</Typography>
                        </Box>
                    </Typography>
                </Box>
            </Paper>
            

            <Paper sx={{ p: 2, mb: 4, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h6">
                    Connect to Mydra
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1">Staging</Typography>
                    <Switch
                        checked={isProduction}
                        onChange={() => setIsProduction(!isProduction)}
                        inputProps={{ 'aria-label': 'Environment toggle' }}
                        sx={{ mx: 1 }}
                    />
                    <Typography variant="body1">Production</Typography>
                    </Box>
                </Box>

                {connectionStatus[isProduction ? 'Production' : 'Staging'] ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                            <Typography variant="body1">Connected</Typography>
                        </Box>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                }
                            }} 
                            onClick={() => {
                                const provider = providers.find(
                                    (provider) => provider._id === connectionStatus[isProduction ? 'Production' : 'Staging']
                                );
                                if (provider) {
                                    window.open(generateMydraHubUrl(provider._id, provider.name), '_blank');
                                }
                            }}
                        >
                        {providers.find(
                            (provider) => provider._id === connectionStatus[isProduction ? 'Production' : 'Staging']
                        )?.logoUrl && (
                            <img
                            src={providers.find(
                                (provider) => provider._id === connectionStatus[isProduction ? 'Production' : 'Staging']
                            ).logoUrl}
                            alt={providers.find(
                                (provider) => provider._id === connectionStatus[isProduction ? 'Production' : 'Staging']
                            ).name}
                            style={{ width: 40, height: 40, marginRight: 8 }}
                            />
                        )}
                        <Typography variant="body1">
                            {providers.find(
                            (provider) => provider._id === connectionStatus[isProduction ? 'Production' : 'Staging']
                            )?.name}
                        </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={matchCourses}
                                sx={{ ml: 2 }}
                                disabled={isMatching} // Disable the button while matching
                                startIcon={isMatching ? <CircularProgress size={20} /> : null} // Show loading spinner
                            >
                                {isMatching ? 'Matching courses...' : 'Match Courses'}
                            </Button>
                            <Chip 
                                label={`${matchedCoursesCount} courses uploaded`} 
                                color="secondary" 
                                sx={{ ml: 2, fontSize: '0.875rem', height: '32px' }} 
                            />
                        </Box>


                        
                    </Box>
                    ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl variant="outlined" sx={{ minWidth: 200, mr: 2 }}>
                            <InputLabel id="provider-label">Education Provider</InputLabel>
                            <Select
                                labelId="provider-label"
                                value={selectedProvider}
                                onChange={(event) => setSelectedProvider(event.target.value)}
                                label="Education Provider"
                            >
                                {providers.map((provider) => (
                                <MenuItem key={provider._id} value={provider._id}>
                                    {provider.name}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConnectClick}
                        disabled={!selectedProvider || isConnecting}
                        startIcon={isConnecting ? <CircularProgress size={20} /> : null}
                        >
                        {isConnecting ? 'Connecting...' : 'Connect'}
                        </Button>
                    </Box>
                    )}

            </Paper>    
            <EducationProviderIdentifiedData 
                data={identifiedData} 
                connectionStatus={connectionStatus} 
                isProduction={isProduction} 
                siteId={site_id} 
                authToken={authToken}
                userEmail={userEmail}               
                />
            </>

            )}
            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Container>
    );
};
    
export default EducationProviderDetails;
