import React from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import MydraCategoryItem from '../MydraCategoryItem/MydraCategoryItem';


const MydraCategoryList = ({ categories, onDeleteSubCategory, onAddSubcategory, authToken, userEmail, isProduction, onSubcategoryAdded }) => {
    
    // console.log('Categories in MydraCategoryList:', categories); // Add this line to log the categories

    if (!categories || !Array.isArray(categories)) {
        // Handle the case where categories is not an array
        // This can be a return statement for a loading spinner, empty state, or a simple message
        return <div>No categories available</div>;
    }


    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}> {/* Use Container to control the maximum width */}
            <TableContainer component={Paper}>
                <Table aria-label="category table">
                    <TableHead sx={{ backgroundColor: 'primary.main' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Category Name</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Subcategories</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map(category => (
                            <MydraCategoryItem 
                                authToken={authToken} // Pass the authToken to the child component
                                userEmail={userEmail} // Pass the userEmail to the child component
                                isProduction={isProduction} // Pass the isProduction to the child component
                                key={category._id} 
                                category={category} 
                                onAddSubcategory={onAddSubcategory} // Pass the function directly
                                onSubcategoryAdded={onSubcategoryAdded} // Pass the function directly
                                onDeleteSubCategory={onDeleteSubCategory} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};


export default MydraCategoryList;




