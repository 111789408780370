
import React, { useState, useEffect } from 'react';
import { Avatar, Box, Card, CardContent, Typography, CardActions, Button, TextField, Link, CardHeader, Grid , Chip, OutlinedInput, FormControlLabel, Checkbox, duration} from '@mui/material';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LanguageIcon from '@mui/icons-material/Language';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SchoolIcon from '@mui/icons-material/School';
import UpdateIcon from '@mui/icons-material/Update';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Language from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { format } from 'date-fns';


const levels = ['beginner', 'intermediate', 'advanced'];

const languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Spanish' },
    { code: 'fr', label: 'French' },
    { code: 'de', label: 'German' },
    { code: 'it', label: 'Italian' },
    { code: 'pt', label: 'Portuguese' },
    { code: 'ru', label: 'Russian' },
    { code: 'zh', label: 'Chinese' },
    { code: 'ja', label: 'Japanese' },
  ];

const currencies = ['EUR', 'USD', 'GBP', 'CHF'];

const classTypes = ['live', 'pre-recorded'];

const durationTypes = ['hour', 'hours', 'day', 'days', 'week', 'weeks', 'month', 'months', 'year', 'years'];



// Assuming "format" is an array of strings. Adjust according to your data structure.
const defaultFormats = ['self-paced', 'full-time', 'part-time'];

const CourseInformationSection = ({ course, selectedLanguage, isSectionEditable, onEditSection, onSaveSection, isSaving}) => {

    // Local state to manage editable fields
    const [editableCourseName, setEditableCourseName] = useState(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.name);
    const [editableCohorts, setEditableCohorts] = useState(course.cohorts || []);
    const [editableHasCertificate, setEditableHasCertificate] = useState(course.hasCertificate || false);
    const [editableCourseURL, setEditableCourseURL] = useState(course.url);
    const [editableCourseType, setEditableCourseType] = useState(course?.type);
    // const [editableCourseDurationQuantity, setEditableDurationQuantity] = useState(course.duration?.quantity);
    // const [editableCourseDurationType, setEditableDurationType] = useState(course.duration?.type);
    const [editableCourseCost, setEditableCourseCost] = useState(course.cost);
    const [editableCourseLanguage, setEditableCourseLanguage] = useState(course.language);
    const [editableCourseLevel, setEditableCourseLevel] = useState(course.level);
    const [editableCourseFrequency, setEditableCourseFrequency] = useState(course.frequency);
    const [editableCourseFormats, setEditableCourseFormats] = useState(course.formats || []);
    const [editableLocations, setEditableLocations] = useState(course.locations || []);
    const [newLocation, setNewLocation] = useState('');


    const today = new Date(); // Get today's date

    const [originalCourse, setOriginalCourse] = useState(course);

    const editable = isSectionEditable('courseInformation');
    
    // Use a local state to store the displayed value in the input field
    const [displayedCost, setDisplayedCost] = useState(editableCourseCost?.amount / 100);

    useEffect(() => {
        setOriginalCourse(course);
        setEditableCohorts(course.cohorts || []);
        setEditableHasCertificate(course.hasCertificate || false);
        setEditableCourseName(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.name);
        setEditableCourseURL(course?.url);
        setEditableCourseType(course?.type);
        // setEditableDurationQuantity(course.duration?.quantity);
        // setEditableDurationType(course.duration?.type);
        // setEditableCourseCost(course?.cost);
        setEditableCourseLanguage(course?.language);
        setEditableCourseLevel(course?.level);
        setEditableCourseFrequency(course?.frequency);
        // setEditableCourseFormats(course?.formats || []);
        // setEditableLocations(course?.locations || []);
        setDisplayedCost(course.cost?.amount / 100);

        // Find the corresponding language object based on the language code
         // Find the corresponding language object based on the language code
         const languageObject = languages.find(lang => lang.code.toLowerCase() === course?.language.toLowerCase());
         setEditableCourseLanguage(languageObject || '');
    }, [course, selectedLanguage]);

    

    const getCourseDetailByLanguage = (courseDetailTranslations, selectedLanguage) => {
        if (!courseDetailTranslations || !Array.isArray(courseDetailTranslations)) {
            return null;
        }
        return courseDetailTranslations.find(detail => detail.language === selectedLanguage);
    };

    const currentCourseDetail = getCourseDetailByLanguage(course?.courseDetailTranslations, selectedLanguage);

    const handleCancel = () => {
        setEditableCourseName(originalCourse?.name);
        setEditableCohorts(originalCourse.cohorts || []);
        setEditableHasCertificate(originalCourse.hasCertificate || false);    
        setEditableCourseURL(originalCourse?.url);
        setEditableCourseType(originalCourse?.type);
        // setEditableDurationQuantity(originalCourse.duration?.quantity);
        // setEditableDurationType(originalCourse.duration?.type);
        // setEditableCourseCost(originalCourse?.cost);
        setEditableCourseLevel(originalCourse?.level);
        setEditableCourseFrequency(originalCourse?.frequency);
        setEditableCourseFormats(originalCourse?.formats || []);
        setEditableLocations(originalCourse?.locations || []);
        setDisplayedCost(originalCourse.cost?.amount / 100);
    
        // Find the corresponding language object based on the original course language code
        const languageObject = languages.find(lang => lang.code.toLowerCase() === originalCourse?.language.toLowerCase());
        setEditableCourseLanguage(languageObject || '');
    
        onEditSection(null);
    };

    const handleAddCohort = () => {
        setEditableCohorts([
            ...editableCohorts,
            {
                location: '',
                format: '',
                startDate: '',
                endDate: '',
                cost: { amount: 0, currency: 'EUR' },
                // timeCommitmentPerWeek: '',
                duration: { quantity: 0, type: 'weeks' },
                classType: '',
            },
        ]);
    };

    const handleRemoveCohort = (index) => {
        const newCohorts = [...editableCohorts];
        newCohorts.splice(index, 1);
        setEditableCohorts(newCohorts);
    };


    // 2. Update handleCohortChange function
    const handleCohortChange = (index, field, value) => {
        const newCohorts = [...editableCohorts];
        if (field === 'cost') {
          newCohorts[index].cost = newCohorts[index].cost || {};
          
          // Handle amount
          if ('amount' in value) {
            if (value.amount === null) {
              delete newCohorts[index].cost.amount;
            } else {
              newCohorts[index].cost.amount = value.amount;
            }
          }
          
          // Handle currency
          if ('currency' in value) {
            if (value.currency === null || value.currency === "None") {
              delete newCohorts[index].cost.currency;
            } else {
              newCohorts[index].cost.currency = value.currency;
            }
          }
      
          // If both amount and currency are removed, delete the cost object
          if (Object.keys(newCohorts[index].cost).length === 0) {
            delete newCohorts[index].cost;
          }
        } else {
          newCohorts[index][field] = value;
        }
        setEditableCohorts(newCohorts);
      };
      

    const handleSave = () => {
        // Fields that belong to the translation
        const translationFields = {
            name: editableCourseName,
            // Add other translatable fields here
        };
    
        // Non-translatable fields
        const nonTranslationFields = {
            type: editableCourseType,
            url: editableCourseURL,
            // language: editableCourseLanguage,
            // for the language get only the code, not the label
            language: editableCourseLanguage?.code || null,
            level: editableCourseLevel,
            frequency: editableCourseFrequency,
            // cohorts: editableCohorts,
            cohorts: editableCohorts.map(cohort => ({
                ...cohort,
                cost: cohort.cost && Object.keys(cohort.cost).length > 0 ? {
                  amount: cohort.cost.amount !== undefined ? cohort.cost.amount : null,
                  currency: cohort.cost.currency || null,
                } : null,
              })),

            hasCertificate: editableHasCertificate,
        };
    
        const updatePayload = {
            language: selectedLanguage,
            translationFields,
            nonTranslationFields
        };
    
        console.log('Saving course with data:', updatePayload);
        onSaveSection('courseInformation', updatePayload);
    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const handleProviderClick = () => {
        if (course.educationProvider) {
            const url = `/mydra/provider/${course.educationProvider._id}/${encodeURIComponent(course.educationProvider.name)}`;
            window.open(url, '_blank');
        }
    };


    

    return (
        <Box sx={{ width: '100%', p: 0, m: 0 }}>
            <Card variant="outlined" sx={{ mb: 2, width: '100%'  }}> {/* Adjusted maxWidth and margin */}
                <CardHeader
                    action={
                        editable ? (        
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    disabled={isSaving} // Disable button when isSaving is true
                                >
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: 'common.white',
                                        borderColor: 'common.white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderColor: 'common.white',
                                        },
                                    }}
                                    onClick={handleCancel}
                                    disabled={isSaving}
                                >
                                    Cancel
                                </Button>
                            </Box>
                         
                        ) : (
                            <IconButton onClick={() => onEditSection('courseInformation')} sx={{ color: 'common.white' }}>
                                <EditIcon />
                            </IconButton>
                        )
                    }
                    titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
                    title="Course Information"
                    sx={{
                        backgroundColor: 'primary.light',
                        color: 'common.white',
                        padding: '16px',
                        '.MuiCardHeader-action': { m: 'auto 0' },
                        '.MuiCardHeader-title': { color: 'common.white' }
                    }}
                />
                <CardContent>

                    <Box 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            mb: 3, 
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8,
                            },
                        }}
                        onClick={handleProviderClick}
                    >
                        <Avatar 
                            src={course.educationProvider?.logoUrl} 
                            alt={`${course.educationProvider?.name} logo`} 
                            sx={{ width: 56, height: 56, marginRight: 2 }} 
                        />
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {course.educationProvider?.name}
                        </Typography>
                    </Box>

                
                    {editable ? (
                        <>
                            <TextField
                                label="Course Name"
                                value={editableCourseName}
                                onChange={(e) => setEditableCourseName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Course URL"
                                value={editableCourseURL}
                                onChange={(e) => setEditableCourseURL(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Type"
                                value={editableCourseType}
                                onChange={(e) => setEditableCourseType(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Language</InputLabel>
                                <Select
                                    value={editableCourseLanguage?.code || ''}
                                    onChange={(e) => {
                                        const selectedLanguage = languages.find(lang => lang.code === e.target.value);
                                        setEditableCourseLanguage(selectedLanguage || '');
                                    }}
                                    label="Language"
                                >
                                    {languages.map((lang) => (
                                        <MenuItem key={lang.code} value={lang.code}>
                                            {lang.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <InputLabel>Level</InputLabel>
                                <Select
                                value={editableCourseLevel}
                                onChange={(e) => setEditableCourseLevel(e.target.value)}
                                label="Level"
                                >
                                {levels.map((level) => (
                                    <MenuItem key={level} value={level}>
                                    {level}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={editableHasCertificate}
                                        onChange={(e) => setEditableHasCertificate(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Has Certificate"
                            />
                                
                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
                                Cohorts
                            </Typography>
                                {editableCohorts.map((cohort, index) => (
                                        <Box key={index} mb={2} p={2} borderRadius={1} boxShadow={1} bgcolor={index % 2 === 0 ? 'grey.100' : 'grey.200'}>
                                            <TextField
                                                label="Location"
                                                value={cohort.location}
                                                onChange={(e) => {
                                                    const newCohorts = [...editableCohorts];
                                                    newCohorts[index].location = e.target.value;
                                                    setEditableCohorts(newCohorts);
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            />
                                            
                                            <FormControl fullWidth margin="normal">
                                            <InputLabel>Format</InputLabel>
                                            <Select
                                                value={cohort.format}
                                                onChange={(e) => {
                                                    const newCohorts = [...editableCohorts];
                                                    newCohorts[index].format = e.target.value;
                                                    setEditableCohorts(newCohorts);
                                                }}
                                                label="Format"
                                            >
                                                {defaultFormats.map((format) => (
                                                    <MenuItem key={format} value={format}>
                                                        {format}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Start Date"
                                                value={cohort.startDate ? new Date(cohort.startDate) : null}
                                                format="dd-MMM-yyyy" // Correct format for display

                                                onChange={(date) => {
                                                    const newCohorts = [...editableCohorts];
                                                    newCohorts[index].startDate = date ? format(date, 'yyyy-MM-dd') : '';
                                                    setEditableCohorts(newCohorts);
                                                }}
                                                minDate={today} // Disallow past dates
                                                renderInput={(params) => <TextField {...params} />}

                                                sx = {{marginRight: 2}}
                                            />
                                            <DatePicker
                                                label="End Date"
                                                value={cohort.endDate ? new Date(cohort.endDate) : null}
                                                format="dd-MMM-yyyy" 

                                                onChange={(date) => {
                                                    const newCohorts = [...editableCohorts];
                                                    // newCohorts[index].endDate = date ? date.toISOString().split('T')[0] : '';
                                                    newCohorts[index].endDate = date ? format(date, 'yyyy-MM-dd') : '';

                                                    setEditableCohorts(newCohorts);
                                                }}
                                                minDate={today} // Disallow past dates

                                                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}

                                            />
                                        </LocalizationProvider>
                                            
                                            {/* <TextField
                                                label="Time Commitment Per Week"
                                                value={cohort.duration}
                                                onChange={(e) => {
                                                    const newCohorts = [...editableCohorts];
                                                    newCohorts[index].duration = e.target.value;
                                                    setEditableCohorts(newCohorts);
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            /> */}
                                            <TextField
                                                label="Duration Quantity"
                                                type="number"
                                                value={cohort.duration?.quantity || ''}
                                                onChange={(e) => {
                                                    const newCohorts = [...editableCohorts];
                                                    newCohorts[index].duration = {
                                                        ...newCohorts[index].duration,
                                                        quantity: parseInt(e.target.value),
                                                    };
                                                    setEditableCohorts(newCohorts);
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            />
                                            {/* <TextField
                                                label="Duration Type"
                                                value={cohort.duration?.type || ''}
                                                onChange={(e) => {
                                                    const newCohorts = [...editableCohorts];
                                                    newCohorts[index].duration = {
                                                        ...newCohorts[index].duration,
                                                        type: e.target.value,
                                                    };
                                                    setEditableCohorts(newCohorts);
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            /> */}
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>Duration Type</InputLabel>
                                                <Select
                                                    value={cohort.duration?.type || ''}
                                                    onChange={(e) => {
                                                        const newCohorts = [...editableCohorts];
                                                        newCohorts[index].duration = {
                                                            ...newCohorts[index].duration,
                                                            type: e.target.value,
                                                        };
                                                        setEditableCohorts(newCohorts);
                                                    }}
                                                    label="Duration Type"
                                                >
                                                    {durationTypes.map((type) => (
                                                        <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>Class Type</InputLabel>
                                                <Select
                                                    value={cohort.classType}
                                                    onChange={(e) => {
                                                        const newCohorts = [...editableCohorts];
                                                        newCohorts[index].classType = e.target.value;
                                                        setEditableCohorts(newCohorts);
                                                    }}
                                                    label="Class Type"
                                                >
                                                    {classTypes.map((type) => (
                                                        <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                label="Cost Amount"
                                                type="number"
                                                value={cohort.cost?.amount !== null && cohort.cost?.amount !== undefined 
                                                ? (cohort.cost.amount / 100).toString() 
                                                : ''}
                                                onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const newAmount = inputValue === '' ? null : Math.round(parseFloat(inputValue) * 100);
                                                handleCohortChange(index, 'cost', { amount: newAmount });
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                inputProps={{ step: '0.01', min: '0' }}
                                            />
                                        

                                            
                                    
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>Currency</InputLabel>
                                                <Select
                                                    value={cohort.cost?.currency || ''}
                                                    onChange={(e) => handleCohortChange(index, 'cost', { currency: e.target.value || null })}
                                                    label="Currency"
                                                >
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency} value={currency}>
                                                            {currency}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value="">None</MenuItem> {/* Allow selecting empty currency */}

                                                </Select>
                                            </FormControl>
                                            <Box display="flex" justifyContent="flex-end">
                                                <IconButton onClick={() => handleRemoveCohort(index)} color="error">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                    </Box>
                                ))}
                                <Box sx={{ mt: 2 }}>
                                    <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddCohort}>
                                        Add Cohort
                                    </Button>
                                </Box>
                                


                        </>
                    ) : (
                        <>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                                {currentCourseDetail?.name}
                            </Typography>     

                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                                        <LanguageIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                        <Link href={course.url} target="_blank" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                                        {course.url.split('?')[0]} {/* This will only display the URL part before the query string */}
                                        </Link>
                                    </Typography>
                                    
                                </Box>

                                <Grid container spacing={2} justifyContent="space-between" sx={{ marginLeft: 0, marginRight: 2 }}>
                                    <Grid item>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <LabelIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                            Type
                                        </Typography>
                                        <Typography variant="body1">{course?.type}</Typography>
                                    </Grid>                                    
                                    <Grid item>
                                        <Typography variant="subtitle1" gutterBottom>
                                        <Language sx={{ verticalAlign: 'middle', mr: 1 }} />
                                            Language
                                        </Typography>
                                        <Typography variant="body1">{editableCourseLanguage?.label || ''}</Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <SchoolIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                            Level
                                        </Typography>
                                        <Typography variant="body1">{course?.level}</Typography>
                                    </Grid>
                                    <Grid item>
                                       
                                        <Typography variant="subtitle1" gutterBottom>
                                            <SchoolIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                            Has Certificate:
                                        </Typography>
                                        <Typography variant="body1">{course.hasCertificate ? 'Yes' : 'No'}</Typography> 
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
                                Cohorts
                            </Typography>
                            {course.cohorts?.map((cohort, index) => (     
                                <Card key={index} sx={{ mb: 2, p: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <LocationOnIcon sx={{ mr: 1 }} /> Location:
                                            </Typography>
                                            <Typography variant="body1">{cohort.location}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FormatListBulletedIcon sx={{ mr: 1 }} /> Format:
                                            </Typography>
                                            <Typography variant="body1">{cohort.format}</Typography>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CalendarTodayIcon sx={{ mr: 1 }} /> Start Date:
                                            </Typography>
                                            {cohort.startDate ? new Date(cohort.startDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) : ''}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <EventAvailableIcon sx={{ mr: 1 }} /> End Date:
                                            </Typography>
                                            {cohort.endDate ? new Date(cohort.endDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) : ''}
                                        </Grid>


                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AccessTimeIcon sx={{ mr: 1 }} /> Duration:
                                            </Typography>
                                            <Typography variant="body1">
                                                {cohort.duration?.quantity} {cohort.duration?.type}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <SchoolIcon sx={{ mr: 1 }} /> Class Type:
                                            </Typography>
                                            <Typography variant="body1">{cohort.classType}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AttachMoneyIcon sx={{ mr: 1 }} /> Cost:
                                        </Typography>
                                        <Typography variant="body1">{(cohort.cost?.amount / 100).toFixed(2)} {cohort.cost?.currency}</Typography>
                                    </Grid>
                                    </Grid>
                                </Card>
                            ))}
                                               
                        </>

                        )}

                </CardContent>

            </Card>

        </Box>
    );
};

export default CourseInformationSection;
