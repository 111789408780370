import React, { useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
    Container,
    Typography,
    Switch,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    Alert,
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
    styled,
    Collapse,
    IconButton,
    Button,
    Link
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StyledTableContainer = styled(TableContainer)({
    maxHeight: '70vh',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
});

const StyledTable = styled(Table)({
    minWidth: 650,
    backgroundColor: '#ffffff',
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: '#f0f0f0',
    '& .MuiTableCell-head': {
        color: '#333',
        fontWeight: 'bold',
        padding: '16px',
    },
});

const StyledHeaderCell = styled(TableCell)({
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: '#f5f5f5',
});

const FilterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiInputLabel-root': {
        backgroundColor: theme.palette.background.paper,
        padding: '0 4px',
    },
}));

const EnquiryRow = ({ enquiry, fetchEnquiryHistory, isOpen, onToggle, updateEnquiryStatus, isProduction }) => {
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedStatus, setEditedStatus] = useState(enquiry.status);


    const handleClick = async () => {
        if (!isOpen) {
            setIsLoading(true);
            const fetchedHistory = await fetchEnquiryHistory(enquiry._id);
            setHistory(fetchedHistory);
            setIsLoading(false);
        }
        onToggle(enquiry._id);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        await updateEnquiryStatus(enquiry._id, editedStatus);
        setIsEditing(false);
    };

    const handleStatusChange = (event) => {
        setEditedStatus(event.target.value);
    };

    const getMydraLink = (courseId) => {
        return isProduction
            ? `https://marketplace.mydra.io/courses/${courseId}`
            : `https://marketplace.staging.mydra.io/courses/${courseId}`;
    };

    const getMydraHubLink = (courseId) => {
        return isProduction
            ? `https://www.mydrahub.com/mydra/course/${courseId}`
            : `https://www.mydrahub.com/mydra/course/${courseId}`; // Assuming there's a staging URL for MydraHub
    };

    return (
        <>
        <TableRow>
            <TableCell>
                <IconButton size="small" onClick={handleClick}>
                    {isLoading ? (
                        <CircularProgress size={24} />
                    ) : isOpen ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )}
                </IconButton>
            </TableCell>
            <TableCell>{enquiry?._id}</TableCell>
            <TableCell>
                {enquiry?.reporter ? (
                    <>
                        {`${enquiry.reporter.firstName} ${enquiry.reporter.lastName}`}
                        <Typography variant="caption" color="textSecondary" display="block">
                            {enquiry.reporter.email}
                        </Typography>
                    </>
                ) : (
                    'N/A'
                )}
            </TableCell>
            <TableCell>{enquiry.featureRequest}</TableCell>
            <TableCell>
                {isEditing ? (
                    <Select
                        value={editedStatus}
                        onChange={handleStatusChange}
                        size="small"
                    >
                        <MenuItem value="CREATED">Created</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="AWAITING_RESPONSE">Awaiting Response</MenuItem>
                        <MenuItem value="RESOLVED">Resolved</MenuItem>
                        <MenuItem value="CANCELLED">Cancelled</MenuItem>
                        <MenuItem value="FAILED">Failed</MenuItem>
                    </Select>
                ) : (
                    enquiry.status
                )}
            </TableCell>
            <TableCell>
                <Typography variant="body2">
                    {enquiry.assignee ? `${enquiry.assignee?.firstName} ${enquiry.assignee?.lastName}` : 'N/A'}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {enquiry.assignee ? enquiry.assignee?.email : 'N/A'}
                </Typography>
            </TableCell>
            <TableCell>
            <Box>
                    <Typography variant="body2" component="div">
                        EP: {enquiry.context ? enquiry.context?.educationProvider : 'N/A'}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="div">
                        Course: {enquiry.context?.course ? (
                            <>
                                <Link 
                                    href={getMydraLink(enquiry.context.course)} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ marginRight: '8px' }}
                                >
                                    Mydra <OpenInNewIcon fontSize="small" />
                                </Link>
                                <Link 
                                    href={getMydraHubLink(enquiry.context.course)} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ marginRight: '8px' }}

                                >
                                    MydraHub <OpenInNewIcon fontSize="small" />
                                </Link>
                            </>
                        ) : 'N/A'}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="div">
                        Cohort: {enquiry.context ? enquiry.context?.cohort : 'N/A'}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>{new Date(enquiry.createdAt).toLocaleString()}</TableCell>
            <TableCell>{new Date(enquiry.updatedAt).toLocaleString()}</TableCell>  
            <TableCell>
                {isEditing ? (
                    <Button
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        color="primary"
                        size="small"
                    >
                        Save
                    </Button>
                ) : (
                    <Button
                        startIcon={<EditIcon />}
                        onClick={handleEdit}
                        color="primary"
                        size="small"
                    >
                        Edit
                    </Button>
                )}
            </TableCell>    
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            History
                        </Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell>User</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {history.map((historyItem, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{historyItem.action}</TableCell>
                                        <TableCell>{historyItem.user}</TableCell>
                                        <TableCell>{historyItem.message}</TableCell>
                                        <TableCell>{new Date(historyItem.createdAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
        </>
    );
};

const MydraEnquiriesPage = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [authToken, setAuthToken] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 20,
        totalPages: 1,
    });
    const [filters, setFilters] = useState({
        status: '',
        featureRequest: '',
    });
    const [openEnquiryId, setOpenEnquiryId] = useState(null);

    const handleToggle = (enquiryId) => {
        setOpenEnquiryId(prevId => prevId === enquiryId ? null : enquiryId);
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    console.log('User authenticated');
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    const fetchEnquiries = useCallback(async () => {
        console.log('Fetching enquiries...');
        try {
            setIsLoading(true);
            const baseParams = new URLSearchParams({
                page: pagination.page.toString(),
                limit: pagination.limit.toString(),
                include: 'assignee', // Changed back to a single string
            });

            if (filters.status) baseParams.append('status', filters.status);
            if (filters.featureRequest) baseParams.append('featureRequest', filters.featureRequest);

            const url = `${API_BASE_URL}/mydra/enquiries?${baseParams}`;
            console.log('Fetching from URL:', url);

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response:', errorData);
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
            }

            const responseData = await response.json();

            console.log('Response data:', responseData);

            if (Array.isArray(responseData)) {
                console.log('Enquiries fetched:', responseData);
                setEnquiries(responseData);
                // Since we don't have metadata, we'll assume there's only one page for now
                setPagination(prevPagination => ({
                    ...prevPagination,
                    totalPages: 1,
                }));
            } else {
                console.error('Unexpected response structure:', responseData);
                setEnquiries([]);
            }
        } catch (error) {
            console.error('Error fetching enquiries:', error);
            setEnquiries([]);
        } finally {
            setIsLoading(false);
        }
    }, [authToken, isProduction, pagination.page, pagination.limit, filters]);

    useEffect(() => {
        if (authToken) {
            fetchEnquiries();
        }
    }, [authToken, isProduction, pagination.page, filters, fetchEnquiries]);

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handlePageChange = (event, newPage) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: newPage,
        }));
    };

    const handleFilterChange = (event) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [event.target.name]: event.target.value,
        }));
    };

    const fetchEnquiryHistory = useCallback(async (enquiryId) => {
        try {
            const url = `${API_BASE_URL}/mydra/enquiries/history?enquiryId=${enquiryId}`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching enquiry history:', error);
            return [];
        }
    }, [authToken, isProduction]);

    const updateEnquiryStatus = useCallback(async (enquiryId, newStatus) => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/enquiries/${enquiryId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();
            // Refetch enquiries to update the list
            fetchEnquiries();
        } catch (error) {
            console.error('Error updating enquiry status:', error);
        }
    }, [authToken, isProduction, API_BASE_URL, fetchEnquiries]);

    return (
        <Box sx={{ mt: 4, overflowX: 'auto' }}>
            <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
                Enquiries
            </Typography>

            <Box display="flex" alignItems="center" mb={2} ml={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            <FilterBox>
                <StyledFormControl>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                        labelId="status-label"
                        value={filters.status}
                        onChange={handleFilterChange}
                        name="status"
                        label="Status"
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="CREATED">Created</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="AWAITING_RESPONSE">Awaiting Response</MenuItem>
                        <MenuItem value="RESOLVED">Resolved</MenuItem>
                        <MenuItem value="CANCELLED">Cancelled</MenuItem>
                        <MenuItem value="FAILED">Failed</MenuItem>
                    </Select>
                </StyledFormControl>

                <StyledFormControl>
                    <InputLabel id="feature-request-label">Feature Request</InputLabel>
                    <Select
                        labelId="feature-request-label"
                        value={filters.featureRequest}
                        onChange={handleFilterChange}
                        name="featureRequest"
                        label="Feature Request"
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="PAYMENT-METHOD">Payment Method</MenuItem>
                        <MenuItem value="PAYMENT-FEATURE">Payment Feature</MenuItem>
                        <MenuItem value="FUTURE-COHORTS">Future Cohorts</MenuItem>
                        <MenuItem value="COHORT-PRICE">Cohort Price</MenuItem>
                        <MenuItem value="COURSE-REQUEST">Course Request</MenuItem>
                    </Select>
                </StyledFormControl>
            </FilterBox>

            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact live data.
                </Typography>
            )}

            {isLoading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : enquiries.length > 0 ? (
                <>
                    <StyledTableContainer component={Paper}>
                        <StyledTable stickyHeader>
                            <StyledTableHead>
                                <TableRow>
                                    <StyledHeaderCell />
                                    <StyledHeaderCell>ID</StyledHeaderCell>
                                    <StyledHeaderCell>Reporter</StyledHeaderCell>
                                    <StyledHeaderCell>Feature Request</StyledHeaderCell>
                                    <StyledHeaderCell>Status</StyledHeaderCell>
                                    <StyledHeaderCell>Assignee</StyledHeaderCell>              
                                    <StyledHeaderCell>EP & Course</StyledHeaderCell>
                                    <StyledHeaderCell>Created At</StyledHeaderCell>
                                    <StyledHeaderCell>Updated At</StyledHeaderCell>
                                    <StyledHeaderCell>Actions</StyledHeaderCell>
                                </TableRow>
                            </StyledTableHead>
                            <TableBody>
                                {enquiries.map((enquiry) => (
                                    <EnquiryRow 
                                        key={enquiry._id} 
                                        enquiry={enquiry} 
                                        fetchEnquiryHistory={fetchEnquiryHistory}
                                        isOpen={openEnquiryId === enquiry._id}
                                        onToggle={handleToggle}
                                        updateEnquiryStatus={updateEnquiryStatus}
                                        isProduction={isProduction}

                                    />
                                ))}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                            count={pagination.totalPages}
                            page={pagination.page}
                            onChange={handlePageChange}
                            color="primary"
                            disabled={enquiries.length === 0}
                        />
                    </Box>
                </>
            ) : (
                <Alert severity="info" sx={{ mt: 3 }}>
                    No enquiries found for the current page. This might be due to a backend pagination issue. 
                    Please try returning to the first page or adjusting your filters.
                </Alert>
            )}
        </Box>

    );
};

export default MydraEnquiriesPage;
