import React, { useState } from 'react';
import { Typography, Container } from '@mui/material';

import CompaniesTable from '../../components_contacts/CompaniesTable';
import ContactsCreationForm from '../../components_contacts/ContactsCreationForm/ContactsCreationForm';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


function ContactPage() {
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(false); // This state will be toggled to trigger updates

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                // Handle user not logged in or redirect to login
                console.log('User not logged in');
            }
        });
    }, []);

    const handleCompanyProcessed = () => {
        setUpdateTrigger(prev => !prev); // Toggle the state to trigger an update
    };
    
    
  return (
    <Container maxWidth="lg">
        <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
            Add and Process Company
        </Typography>
        {authToken && (
            <>
                <ContactsCreationForm authToken={authToken} userEmail={userEmail} onCompanyProcessed={handleCompanyProcessed} />
                <CompaniesTable authToken={authToken} updateTrigger={updateTrigger} onCompanyProcessed={handleCompanyProcessed}  />
            </>
        )}
        
    </Container>
  );
}

export default ContactPage;

