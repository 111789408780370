import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
    Container,
    Typography,
    Switch,
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox,
    Snackbar,
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import CategoryIcon from '@mui/icons-material/Category';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const MydraCategoryPage = () => {
    const { categoryId, categoryName } = useParams();
    const [courses, setCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState(new Set());

    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoadingCourses, setIsLoadingCourses] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const theme = useTheme();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [isLoading, setIsLoading] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [loadingState, setLoadingState] = useState({
        isLoading: false,
        type: '',
    });

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        if (authToken && userEmail) {
            console.log('Fetching courses');
            fetchCourses();
        }
    }, [authToken, userEmail, isProduction]);

    const fetchCourses = async () => {
        try {
            setIsLoadingCourses(true);
            const response = await fetch(`${API_BASE_URL}/mydra/categories/${categoryId}/courses`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            const responseData = await response.json();
            console.log('Courses:', responseData.data);
            setCourses(responseData.data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        } finally {
            setIsLoadingCourses(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleRowClick = (courseId) => {
        const url = `/mydra/course/${courseId}${!isProduction ? '?env=Staging' : ''}`;
        console.log('Navigating to:', url);
        window.open(url, '_blank');
    };

    const tableRowStyle = {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        }
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedCourses = React.useMemo(() => {
        const comparator = (a, b) => {
            if (orderBy === 'providerName') {
                return a.educationProvider.name.localeCompare(b.educationProvider.name);
            } else if (orderBy === 'courseName') {
                return a.name.localeCompare(b.name);
            } else if (orderBy === 'published') {
                return a.published === b.published ? 0 : a.published ? -1 : 1;
            } else if (orderBy === 'createdAt') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            } else {
                return 0;
            }
        };

        return [...courses].sort((a, b) => {
            const orderMultiplier = order === 'asc' ? 1 : -1;
            return comparator(a, b) * orderMultiplier;
        });
    }, [courses, order, orderBy]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd HH:mm');
    };

    const publishedCourses = courses.filter(course => course.published);
    const unpublishedCourses = courses.filter(course => !course.published);

    const providerCounts = courses.reduce((acc, course) => {
        const providerName = course.educationProvider.name;
        if (!acc[providerName]) {
            acc[providerName] = 0;
        }
        acc[providerName]++;
        return acc;
    }, {});
    
    const sortedProviderCounts = Object.entries(providerCounts).sort((a, b) => b[1] - a[1]);

    const handleSelectCourse = (event, courseId) => {
        event.stopPropagation();
        const newSelection = new Set(selectedCourses);
        if (newSelection.has(courseId)) {
            newSelection.delete(courseId);
        } else {
            newSelection.add(courseId);
        }
        setSelectedCourses(newSelection);
    };

    const handleBulkPublish = async (publish) => {
        setLoadingState({ isLoading: true, type: publish ? 'publish' : 'unpublish' });

        const newPublishedStatus = publish;
        const nonTranslationFields = {
            published: newPublishedStatus,
        };

        const payload = {
            nonTranslationFields: nonTranslationFields,
        };

        try {
            await Promise.all(
                Array.from(selectedCourses).map(courseId =>
                    fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                        method: 'PUT',
                        headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json' },
                        body: JSON.stringify(payload),
                    })
                )
            );
            setSnackbar({ open: true, message: `Courses successfully ${publish ? 'published' : 'unpublished'}`, severity: 'success' });
            setSelectedCourses(new Set());
            fetchCourses();
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to update courses', severity: 'error' });
            console.error('Failed to publish/unpublish courses', error);
        } finally {
            setLoadingState({ isLoading: false, type: '' });
        }
    };

    const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

    const handleOpenPublishDialog = () => setOpenPublishDialog(true);
    const handleClosePublishDialog = () => setOpenPublishDialog(false);

    const handleOpenUnpublishDialog = () => setOpenUnpublishDialog(true);
    const handleCloseUnpublishDialog = () => setOpenUnpublishDialog(false);

    const initiatePublish = (publish) => {
        if (publish) {
            handleOpenPublishDialog();
        } else {
            handleOpenUnpublishDialog();
        }
    };

    const formatDateCohorts = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
      };

      const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
      const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);
  
      const handleBulkDelete = async () => {
          setLoadingState({ isLoading: true, type: 'delete' });
          try {
              await Promise.all(
                  Array.from(selectedCourses).map(courseId =>
                      fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                          method: 'DELETE',
                          headers: {
                              'Authorization': `Bearer ${authToken}`,
                              'X-User-Email': userEmail,
                              'X-Environment': isProduction ? 'Production' : 'Staging',
                          },
                      })
                  )
              );
              setSnackbar({ open: true, message: `${selectedCourses.size} course(s) successfully deleted`, severity: 'success' });
              setSelectedCourses(new Set()); // Clear selection after successful operation
              fetchCourses(); // Refresh the course list
          } catch (error) {
              setSnackbar({ open: true, message: 'Failed to delete courses', severity: 'error' });
              console.error('Failed to delete courses', error);
          } finally {
              setLoadingState({ isLoading: false, type: '' });
              handleCloseDeleteDialog();
          }
      };
  

    

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography 
                variant="h4" 
                align="center"
                sx={{
                    my: 4,
                    fontWeight: 'bold',
                    color: 'primary.main',
                }}
            >
                Courses in {categoryName}
            </Typography>

            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact all live courses.
                </Typography>
            )}

            {!isLoadingCourses ? (
                <> 
                    <Box sx={{ mb: 4 }}>
                        <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>                
                            <Typography variant="h6" gutterBottom>Total Courses</Typography>
                            <Box>
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                        <Typography variant="h5" component="span" sx={{ fontWeight: 'bold', mr: 1 }}>{courses.length}</Typography>
                                        <Typography variant="body1" component="span">Courses</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                        <FiberManualRecordIcon sx={{ color: 'green', mr: 1 }} />
                                        <Typography variant="h6" component="span" sx={{ mr: 1 }}>{publishedCourses.length}</Typography>
                                        <Typography variant="body1" component="span">Published</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FiberManualRecordIcon sx={{ color: 'red', mr: 1 }} />
                                        <Typography variant="h6" component="span" sx={{ mr: 1 }}>{unpublishedCourses.length}</Typography>
                                        <Typography variant="body1" component="span">Unpublished</Typography>
                                    </Box>
                                </Typography>
                            </Box>
                        </Paper>  
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Courses by Provider</Typography>
                            <Box sx={{ mt: 4 }}>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart data={sortedProviderCounts.map(([name, value]) => ({ name, value }))}
                                        margin={{ bottom: 110, left: 30 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" angle={-40} textAnchor="end" interval={0} />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="value" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        </Paper>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            startIcon={<FiberManualRecordIcon style={{ color: 'green' }} />}
                            onClick={() => initiatePublish(true)}
                            disabled={selectedCourses.size === 0 || loadingState.isLoading}
                            sx={{ marginRight: 2 }}
                        >
                            Publish Selected
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<FiberManualRecordIcon style={{ color: 'red' }} />}
                            onClick={() => initiatePublish(false)}
                            disabled={selectedCourses.size === 0 || loadingState.isLoading}
                            sx={{ marginRight: 2 }}
                        >
                            Unpublish Selected
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            onClick={handleOpenDeleteDialog}
                            disabled={selectedCourses.size === 0 || loadingState.isLoading}
                        >
                            Delete Selected
                        </Button>
                   
                        {loadingState.isLoading && (
                            <CircularProgress size={24} sx={{ ml: 2 }} />
                        )}
                    </Box>
                </>
            ) : null}

            {isLoadingCourses ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
                sortedCourses.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selectedCourses.size > 0 && selectedCourses.size < courses.length}
                                            checked={courses.length > 0 && selectedCourses.size === courses.length}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    const newSelectedCourses = new Set(courses.map(course => course._id));
                                                    setSelectedCourses(newSelectedCourses);
                                                } else {
                                                    setSelectedCourses(new Set());
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    <TableCell sortDirection={orderBy === 'providerName' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'providerName'}
                                            direction={orderBy === 'providerName' ? order : 'asc'}
                                            onClick={() => handleSortRequest('providerName')}
                                        >
                                            Provider
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={orderBy === 'courseName' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'courseName'}
                                            direction={orderBy === 'courseName' ? order : 'asc'}
                                            onClick={() => handleSortRequest('courseName')}
                                        >
                                            Course Name
                                        </TableSortLabel>
                                    </TableCell>
                                    {/* <TableCell>Course Id</TableCell> */}
                                    <TableCell>Cost</TableCell>
                                    <TableCell>Next Cohort</TableCell>
                                    
                                    <TableCell sortDirection={orderBy === 'createdAt' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'createdAt'}
                                            direction={orderBy === 'createdAt' ? order : 'asc'}
                                            onClick={() => handleSortRequest('createdAt')}
                                        >
                                            Created At
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        Agreement
                                    </TableCell>
                                    <TableCell sortDirection={orderBy === 'published' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'published'}
                                            direction={orderBy === 'published' ? order : 'asc'}
                                            onClick={() => handleSortRequest('published')}
                                        >
                                            Published
                                        </TableSortLabel>
                                    </TableCell>       
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedCourses.map((course, index) => (
                                    <TableRow key={course._id} onClick={() => handleRowClick(course._id)} sx={tableRowStyle}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedCourses.has(course._id)}
                                                onChange={(event) => handleSelectCourse(event, course._id)}
                                                onClick={(event) => event.stopPropagation()}
                                            />
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell>
                                        {/* <TableCell>
                                            {course.educationProvider.logoUrl ? (
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={course.educationProvider.logoUrl}
                                                        alt="Provider Logo"
                                                        style={{ width: 50, height: 50, marginRight: 8 }}
                                                    />
                                                </Box>
                                            ) : (
                                                <Typography variant="body2">No Logo Available</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>{course.educationProvider.name}</TableCell> */}
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                {course.educationProvider.logoUrl ? (
                                                <img
                                                    src={course.educationProvider.logoUrl}
                                                    alt="Provider Logo"
                                                    style={{ width: 40, height: 40, marginRight: 8 }}
                                                />
                                                ) : (
                                                <Box width={40} height={40} bgcolor="grey.300" marginRight={1} display="flex" alignItems="center" justifyContent="center">
                                                    <Typography variant="caption">No Logo</Typography>
                                                </Box>
                                                )}
                                                <Typography variant="body2">{course.educationProvider.name}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{course.name}</TableCell>
                                        <TableCell>
                                        {course.cohorts && course.cohorts[0]?.cost
                                            ? `${(course.cohorts[0].cost.amount / 100).toFixed(2)} ${course.cohorts[0].cost.currency}`
                                            : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                        {course.cohorts && course.cohorts[0]?.startDate && course.cohorts[0]?.endDate
                                            ? `${formatDateCohorts(course.cohorts[0].startDate)} - ${formatDateCohorts(course.cohorts[0].endDate)}`
                                            : 'Self-paced'}
                                        </TableCell>
                                        {/* <TableCell>{course._id}</TableCell> */}
                                        <TableCell>{formatDate(course.createdAt)}</TableCell>
                                        <TableCell>
                                        {course.agreements && course.agreements.length > 0 ? (
                                            course.agreements.map((agreement, index) => (
                                                <Box key={agreement._id} sx={{ display: 'flex', alignItems: 'center', mb: index < course.agreements.length - 1 ? 1 : 0 }}>
                                                    <FiberManualRecordIcon sx={{ color: 'green', mr: 1, fontSize: 12 }} />
                                                    <Typography variant="body2">{agreement.name}</Typography>
                                                </Box>
                                            ))
                                        ) : (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FiberManualRecordIcon sx={{ color: 'red', mr: 1, fontSize: 12 }} />
                                                <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                                                    No agreement
                                                </Typography>
                                            </Box>
                                        )}
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2" component="span">
                                                    {course.published ? "Yes" : "No"}
                                                </Typography>
                                                <FiberManualRecordIcon
                                                    sx={{ color: course.published ? 'green' : 'red', ml: 1 }}
                                                />
                                            </Box>
                                        </TableCell>                                    
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Paper elevation={3} sx={{ p: 3, mt: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: theme.palette.background.paper }}>
                        <InfoIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            No Courses Available
                        </Typography>
                        <Typography variant="subtitle1">
                            This category doesn't have any courses yet.
                        </Typography>
                    </Paper>
                )
            )}

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={openPublishDialog}
                onClose={handleClosePublishDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Publish Selected Courses?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to publish the selected courses? This action will make them live.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePublishDialog}>Cancel</Button>
                    <Button onClick={() => {handleBulkPublish(true); handleClosePublishDialog();}} autoFocus>
                        Publish
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openUnpublishDialog}
                onClose={handleCloseUnpublishDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unpublish Selected Courses?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to unpublish the selected courses? This action will remove them from being live.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUnpublishDialog}>Cancel</Button>
                    <Button onClick={() => {handleBulkPublish(false); handleCloseUnpublishDialog();}} autoFocus>
                        Unpublish
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Bulk Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {selectedCourses.size} selected course(s)? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} disabled={loadingState.isLoading && loadingState.type === 'delete'}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleBulkDelete} 
                        color="error" 
                        autoFocus
                        disabled={loadingState.isLoading && loadingState.type === 'delete'}
                        startIcon={loadingState.isLoading && loadingState.type === 'delete' ? <CircularProgress size={20} color="inherit" /> : <DeleteIcon />}
                    >
                        {loadingState.isLoading && loadingState.type === 'delete' ? 'Deleting...' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
};

export default MydraCategoryPage;

