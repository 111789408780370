import React, { useState, useEffect } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    TextField, 
    DialogActions, 
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    InputAdornment,
    FormHelperText,
    Paper,
    Box,
    Typography,
} from '@mui/material';
import './MydraAddEditAgreementModal.css';

const MydraAddEditAgreementModal = ({ open, onClose, onAdd, onUpdate, agreement, authToken, userEmail, isProduction }) => {
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        contractType: '',
        fees: [{ origin: '', type: '', discount: 0, maxStudents: 0, maxRevenue: 0 }], 
        startAt: '',
        endAt: '',
        status: '',
        contractUrl: '',
        dropoutPolicy: '',
        emailForNotifications: '',
        educationProviderId: '',
    });

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [providers, setProviders] = useState([]);

    const validateForm = (data) => {
        let errors = {};
        let isValid = true;

        // Add validation for name field
        if (!data.name) {
            isValid = false;
            errors["name"] = "Name is required";
        }
      
        // Use 'data' instead of 'formData' for validation
        if (!data.contractType) {
            isValid = false;
            errors["contractType"] = "Contract type is required";
        }
      
        if (!data.status) {
          isValid = false;
          errors["status"] = "Status is required";
        }
      
        if (!data.startAt) {
            isValid = false;
            errors["startAt"] = "Start date is required";
        }

        if (!data.contractUrl) {
            isValid = false;
            errors["contractUrl"] = "Contract URL is required";
        }

        if (!data.educationProviderId) {
            isValid = false;
            errors["educationProviderId"] = "Education provider is required";
        }

        if(!data.emailForNotifications) {
            isValid = false;
            errors["emailForNotifications"] = "Email for notifications is required";
        }

        // Initialize fee errors
        const feeErrors = data.fees.map(fee => {
            const feeError = {};
            if (!fee.origin) {
                isValid = false;
                feeError.origin = "Origin is required";
            }
            if (!fee.type) {
                isValid = false;
                feeError.type = "Type is required";
            }
            return feeError;
        });

        // Update the main errors object with fee errors
        if (feeErrors.some(error => Object.keys(error).length > 0)) {
            errors.fees = feeErrors;
        }

        if (feeErrors.some(error => Object.keys(error).length > 0)) {
            errors.fees = feeErrors;
        }
        setFormErrors(errors);
        return isValid;
      };

      const initializeFormData = (agreement) => ({
        name: agreement?.name || '', // Add the name field initialization
        contractType: agreement?.contractType || '',
        fees: agreement?.fees.map(fee => ({
          origin: fee.origin || '',
          type: fee.type || '',
          discount: fee.discount * 100 || '0', // Ensure discount is always a string for consistency
          ...(fee.type === 'escalation' && {
            ...(fee.maxStudents !== undefined && { maxStudents: fee.maxStudents.toString() }), // Include maxStudents only if present
            ...(fee.maxRevenue !== undefined && { maxRevenue: fee.maxRevenue.toString() }), // Include maxRevenue only if present
          }),
        })) || [{ origin: '', type: '', discount: '0', maxStudents: '', maxRevenue: '' }],        
        startAt: agreement?.startAt?.slice(0, 10) || '',
        endAt: agreement?.endAt?.slice(0, 10) || '',
        status: agreement?.status || '',
        contractUrl: agreement?.contractUrl || '',
        dropoutPolicy: agreement?.dropoutPolicy || '',
        emailForNotifications: agreement?.emailForNotifications || '',
        educationProviderId: agreement?.educationProvider?._id || '',
        assignToAllCourses: agreement?.assignToAllCourses || false, // Initialize the assignToAllCourses field
      });
  
    useEffect(() => {
        // Use the initializeFormData function for both new form and editing existing agreement
        setFormData(initializeFormData(agreement));
    }, [agreement]);

    useEffect(() => {
      const fetchProviders = async () => {
          const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`, // Include the auth token
              'X-Environment': isProduction ? 'Production' : 'Staging', // Include the environment
              'X-User-Email': userEmail || '',
          };
          try {
              const response = await fetch(`${API_BASE_URL}/mydra/education-providers`, { headers });
                if (response.ok) {
                    const data = await response.json();
                    // console.log('Fetched education providers:', data);
                    setProviders(data.data || []);
                } else {
                    console.error('Failed to fetch education providers');
                }
            } catch (error) {
                console.error('Error fetching education providers:', error);
            }
      };

      if (authToken && userEmail) {
          fetchProviders();
      }
  }, [authToken, userEmail, isProduction]);


  useEffect(() => {
      if (agreement) {
      setFormData({ ...agreement });
      }
  }, [agreement]);

  useEffect(() => {
      const initializedData = initializeFormData(agreement);
      setFormData(initializedData);
      // Validate to set initial form errors based on the loaded data
      validateForm(initializedData);
  }, [agreement]);

  const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = { ...formData, [name]: value };
      setFormData(updatedFormData);
  
      validateForm(updatedFormData);

  };

  const handleFeeChange = (index, field, value) => {
      console.log('Updating fee:', index, field, value);
      const updatedFees = formData.fees.map((fee, feeIndex) => {
          if (index === feeIndex) {
              let updatedValue;

              // Special handling for the discount field to ensure it stays within 0-100 range
                if (field === 'discount') {
                  const numericValue = Math.min(100, Math.max(0, Number(value))); // Clamp value between 0 and 100
                  updatedValue = numericValue.toString(); // Convert back to string to maintain consistency
              } else {
                  updatedValue = (field === 'maxStudents' || field === 'maxRevenue') ? value.toString() : value;
              }

              const updatedFee = { ...fee, [field]: updatedValue };
          
              // Clear the other fields if needed (for maxStudents and maxRevenue in case of escalation)
              if (fee.type === 'escalation') {
                  if (field === 'maxStudents') updatedFee.maxRevenue = '';
                  if (field === 'maxRevenue') updatedFee.maxStudents = '';
              }
              console.log('Updated fee:', updatedFee);
              return updatedFee;
          }
          return fee;
      });
  
      setFormData({ ...formData, fees: updatedFees });
  };
  


    // Function to add a new fee group
  const addFeeGroup = () => {
      // Check if the last fee group in the array is of type 'flat'
      const lastFeeGroup = formData.fees[formData.fees.length - 1];
      if (lastFeeGroup && lastFeeGroup.type === 'flat' || lastFeeGroup && lastFeeGroup.type === '' ) {
          // Optionally, show an alert or message to the user
          alert("Cannot add a new fee group after a 'flat' or empty fee type.");
          return; // Exit the function without adding a new fee group
      }

      // If the last fee group is not of type 'flat', or there are no fee groups, add a new fee group
      setFormData({
          ...formData,
          fees: [...formData.fees, { origin: '', type: '', discount: '0', maxStudents: '', maxRevenue: '' }] // Ensure discount is a string
      });
  };
    
  const handleSubmit = async () => {
        console.log('Submitting form data:', formData);
    
        const selectedProvider = providers.find(provider => provider._id === formData.educationProviderId);
        if (!selectedProvider) {
          console.error('Selected provider not found');
          return;
        }

        // Transform 'fees' structure for the backend
        const transformedFees = formData.fees.flatMap(fee => {
          const transformedFee = {
            origin: fee.origin,
            type: fee.type,
            // discount: fee.discount,
            // divide fee by 100
            discount: fee.discount / 100,
          };

          // Conditionally include maxStudents and maxRevenue if they have meaningful values
          if (fee.maxStudents && fee.maxStudents !== '0') {
            transformedFee.maxStudents = fee.maxStudents;
          }
          if (fee.maxRevenue && fee.maxRevenue !== '0') {
            transformedFee.maxRevenue = fee.maxRevenue;
          }

          return transformedFee;
        });

  
    const transformedData = {
          agreementId: agreement?._id, // Use agreement._id as the identifier
          name: formData.name, // Include the name field
          contractType: formData.contractType,
          fees: transformedFees, // Use the transformed fees
          startAt: formData.startAt,
          status: formData.status,
          contractUrl: formData.contractUrl,
          emailForNotifications: formData.emailForNotifications,
          educationProviderId: formData.educationProviderId,
          educationProvider: selectedProvider, // Include the full provider details
          ...(formData.dropoutPolicy && { dropoutPolicy: formData.dropoutPolicy }), // Include dropoutPolicy only if it's provided
          ...(formData.endAt && Date.parse(formData.endAt) && { endAt: formData.endAt }), // Include endAt only if it's provided and valid
    };
  
    if (!validateForm(transformedData)) return; // Stop the submission if the form is invalid

    if (agreement) {
        onUpdate(transformedData, authToken); // If editing an existing agreement
    } else {
        onAdd(transformedData, authToken); // If adding a new agreement
    }
    onClose(); // Close the modal after submission
  };

  

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{agreement ? 'Edit Agreement' : 'Add New Agreement'}</DialogTitle>
      <DialogContent>
      <TextField
            margin="dense"
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            value={formData.name || ''}
            onChange={handleChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
            />
        <FormControl fullWidth margin="dense" variant="outlined" error={!!formErrors.contractType}>          
          <InputLabel>Contract Type</InputLabel>
          <Select
            id="contractType"
            name="contractType"
            value={formData?.contractType || ''}
            onChange={handleChange}
            label="Contract Type"
          >
            <MenuItem value="ep-contract">EP-Contract</MenuItem>
            <MenuItem value="ep-financing">EP-Financing</MenuItem>
          </Select>
          {formErrors.contractType && <FormHelperText>{formErrors.contractType}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth margin="dense" variant="outlined" error={!!formErrors.status}>
          <InputLabel>Status</InputLabel>
          <Select
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            label="Status"
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
          </Select>
            {formErrors.status && <FormHelperText>{formErrors.status}</FormHelperText>}
        </FormControl>

          {formData.fees.map((fee, index) => (
            <Paper key={index} elevation={2} className="fee-group"> {/* Wrap each fee group inside a Paper component */}
              <FormGroup key={index}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel>Origin</InputLabel>
                      <Select
                          id={`fee-origin-${index}`}
                          name="origin"
                          value={fee.origin}
                          onChange={(e) => handleFeeChange(index, 'origin', e.target.value)}
                          label="Origin"
                      >
                          <MenuItem value="lead">Lead</MenuItem>
                          <MenuItem value="financing">Financing</MenuItem>
                      </Select>
                  </FormControl>

                  <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel>Type</InputLabel>
                      <Select
                          id={`fee-type-${index}`}
                          name="type"
                          value={fee.type}
                          onChange={(e) => handleFeeChange(index, 'type', e.target.value)}
                          label="Type"
                      >
                          <MenuItem value="flat">Flat</MenuItem>
                          <MenuItem value="escalation">Escalation</MenuItem>
                      </Select>
                  </FormControl>

                  <TextField
                          margin="dense"
                          id={`fee-discount-${index}`}
                          name="discount"
                          label="Discount (%)"
                          type="number"
                          value={fee.discount}
                          onChange={(e) => handleFeeChange(index, 'discount', e.target.value)}
                          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                          fullWidth
                      />

                  {fee.type === 'escalation' && (
                      <>
                          <TextField
                              margin="dense"
                              id={`fee-maxStudents-${index}`}
                              name="maxStudents"
                              label="Max Students"
                              type="number"
                              value={fee.maxStudents}
                              onChange={(e) => handleFeeChange(index, 'maxStudents', e.target.value)}
                              fullWidth
                          />
                          <TextField
                              margin="dense"
                              id={`fee-maxRevenue-${index}`}
                              name="maxRevenue"
                              label="Max Revenue"
                              type="number"
                              value={fee.maxRevenue}
                              onChange={(e) => handleFeeChange(index, 'maxRevenue', e.target.value)}
                              fullWidth
                          />
                      </>
                  )}
              </FormGroup>
            
            </Paper>

          ))}

        <Button onClick={addFeeGroup} color="primary">
            Add Fee Group
        </Button>
 
        <TextField
            margin="dense"
            id="contractUrl"
            name="contractUrl"
            label="Contract URL"
            type="url"
            fullWidth
            variant="outlined"
            value={formData.contractUrl || ''}
            onChange={handleChange}
            error={!!formErrors.contractUrl}
            helperText={formErrors.contractUrl}
            />

        <TextField
            margin="dense"
            id="dropoutPolicy"
            name="dropoutPolicy"
            label="Dropout Policy"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={formData.dropoutPolicy || ''}
            onChange={handleChange}
            helperText="Describe the policy for student dropouts"
            />

        <TextField
            margin="dense"
            id="emailForNotifications"
            name="emailForNotifications"
            label="Email for Notifications"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.emailForNotifications || ''}
            onChange={handleChange}
            error={!!formErrors.emailForNotifications}
            helperText={formErrors.emailForNotifications}
            />

          <FormControl fullWidth margin="dense" variant="outlined" error={!!formErrors.educationProviderId}>
                <InputLabel>Education Provider</InputLabel>
                <Select
                    id="educationProviderId"
                    name="educationProviderId"
                    value={formData.educationProviderId || ''}
                    onChange={handleChange}
                    label="Education Provider"
                >
                    {providers.map((provider) => (
                        <MenuItem key={provider._id} value={provider._id}>
                            {provider.name}
                        </MenuItem>
                    ))}
                </Select>
                {formErrors.educationProviderId && <FormHelperText>{formErrors.educationProviderId}</FormHelperText>}
          </FormControl>
          <Box display="flex" gap={2}> {/* Add a gap for some space between the fields */}
              <TextField
                  margin="dense"
                  id="startAt"
                  name="startAt"
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  value={formData?.startAt || ''} // Ensures value is never undefined
                  onChange={handleChange}
                  error={!!formErrors.startAt}
                  helperText={formErrors.startAt}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  sx={{ flex: 1 }} // Make TextField take up equal space
              />

              <TextField
                  margin="dense"
                  id="endAt"
                  name="endAt"
                  label="End Date (Optional)"
                  type="date"
                  variant="outlined"
                  value={formData.endAt || ''} // Ensures value is never undefined
                  onChange={handleChange}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  sx={{ flex: 1 }} // Make TextField take up equal space
              />
          </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{agreement ? 'Update' : 'Add'}</Button>
      </DialogActions>
      {/* <NotesSection /> */}
    </Dialog>
  );
};

export default MydraAddEditAgreementModal;
