import React, { useState, useEffect } from 'react';
import { 
    TextField, 
    Button, 
    Select, 
    MenuItem, 
    FormControl, 
    InputLabel,
    CircularProgress,
    Typography,
    Backdrop,
    Box,
    Input,
    Radio,
    RadioGroup,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Papa from 'papaparse';


const MydraAddCourseComponent = ({ authToken, userEmail, isProduction, onCourseCreated, onUploadTypeChange }) => {
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission status
    const [submissionMessage, setSubmissionMessage] = useState(''); // New state for submission message
    const [pdfFile, setPdfFile] = useState(null);
    const [uploadType, setUploadType] = useState('single');
    const [csvFile, setCsvFile] = useState(null);
    const [parsedCourses, setParsedCourses] = useState([]);
    const [providerError, setProviderError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');


    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        // resolver: yupResolver(schema),
    });

      
    useEffect(() => {
        console.log('Fetching education providers');
       
        const fetchProviders = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`, // Include the auth token
                'X-Environment': isProduction ? 'Production' : 'Staging',
                'X-User-Email': userEmail || '', // Include the user email
            };
            try {
                const response = await fetch(`${API_BASE_URL}/mydra/education-providers`, { headers });
                if (response.ok) {
                    const data = await response.json();
                    console.log('Education Providers:', data.data);
                    setProviders(data.data || []);
                } else {
                    console.error('Failed to fetch education providers');
                }
            } catch (error) {
                console.error('Error fetching education providers:', error);
            }
        };

        // Only fetch providers if both authToken and userEmail are available
        if (authToken && userEmail) {
            console.log('Fetching education providers');
            fetchProviders();
        }
    }, [authToken, userEmail, isProduction]); // Include userEmail in the dependencies array

    const handleCsvUpload = (event) => {
        console.log("Added CSV file")
        const file = event.target.files[0];
        setCsvFile(file);

        // Parse the CSV file
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const courses = results.data.map((row, index) => {
                    const instructors = [];
                    let i = 1;
                    while (row[`instructor_name_${i}`]) {
                        instructors.push({
                            name: row[`instructor_name_${i}`],
                            linkedin: row[`instructor_linkedin_${i}`],
                            bio: row[`instructor_bio_${i}`],
                            avatarUrl: row[`instructor_avatar_url_${i}`],
                        });
                        i++;
                    }

                    return {
                        id: index + 1,  // Adding a counter as the first column
                        url: row.url,
                        name: row.name,
                        brochureUrl: row.brochure_url,
                        videoUrl: row.video_url,
                        additionalInfo: row.additional_info,
                        instructors,
                    };
                });

                // Set the parsed courses in the state for preview
                setParsedCourses(courses);
                // Consle log parsed courses

                
            },
        });
    };




    // onsbumti that either calls on submit single of onSubmitBulk
    const onSubmit = (data) => {
        if (!selectedProvider) {
            setProviderError(true);
            return;
        }
        if (uploadType === 'single') {
            onSubmitSingle(data);
        } else {
            onSubmitBulk(data);
        }
    };

    const onSubmitBulk = async (data) => {
        console.log("Submitting in bulk")  
        if (!csvFile) {
            setSnackbarMessage("Please provide a CSV file.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);            
            return; // Exit the function early
        }
        setIsSubmitting(true);

        console.log('Form data:', data);
        console.log("Parsed courses:", parsedCourses)

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
            'X-Environment': isProduction ? 'Production' : 'Staging',
            'X-User-Email': userEmail || '',
        };


        try {
            const response = await fetch(`${API_BASE_URL}/mydra/bulk-upload-courses`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    courses: parsedCourses,
                    educationProviderId: selectedProvider,
                }),
            });
    
            if (response.ok) {
                const data = await response.text(); // Parse the response as text
                console.log(data); // Log the success message
                // Handle the success message or perform any necessary actions
                setSnackbarMessage("Course Upload Started Successfully");
                setSnackbarSeverity("success");
                setParsedCourses([]); // Clear the table
            } else {
                console.error('Bulk upload failed');
                // Handle the error case
                setSnackbarMessage("Bulk upload failed");
                setSnackbarSeverity("error");
            }
        } catch (error) {
            console.error('Error during bulk upload:', error);
            // Handle the error case
            setSnackbarMessage("Failed to upload courses");
            setSnackbarSeverity("error");
        } finally {
            setIsSubmitting(false);
            setOpenSnackbar(true);
        }
    };

        

    const onSubmitSingle = async (data) => {
        console.log("Submitting form data")

        if (!data.courseUrl && !pdfFile) {
            alert("Please provide either a Course URL or a PDF file."); // Use a more user-friendly notification in production
            return; // Exit the function early
        }

        console.log('Form data:', data);
        setIsSubmitting(true);
        setSubmissionMessage('Course is being created, this may take up to 2 minutes.');

        // Submit logic here
        console.log({
            courseUrl: data.courseUrl,
            additionalInfo,
            educationProviderId: selectedProvider,
            pdfFile: pdfFile,
        });

        const formData = new FormData();
            if (data.courseUrl) {
                formData.append('courseUrl', data.courseUrl);
            }
            formData.append('additionalInfo', additionalInfo);
            formData.append('educationProviderId', selectedProvider);
            if (pdfFile) {
                formData.append('pdfFile', pdfFile);
        }

        const headers = {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
            'X-Environment': isProduction ? 'Production' : 'Staging',
            'X-User-Email': userEmail || '',
        };

        try {
            const response = await fetch(`${API_BASE_URL}/mydra/course`, {
                method: 'POST',
                headers: headers,
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to create course');
            }
            const data = await response.json();
            console.log('Course created successfully:', data);
            console.log('Course created:', data.courseId);
            console.log('Environment:', data.environment);
            setSnackbarMessage("Course Creation Started Successfully");
            setSnackbarSeverity("success");
            // onCourseCreated(data.courseId, data.environment);

        } catch (error) {
            console.error('Error creating course:', error);
            setSnackbarMessage("Failed to create course");
            setSnackbarSeverity("error");
            // setSubmissionMessage('Failed to create course.');
        } finally {
            setIsSubmitting(false);
            setOpenSnackbar(true);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl component="fieldset" sx={{ mt: 2 }}>
                <RadioGroup
                    row
                    value={uploadType}
                    onChange={(e) => {
                        setUploadType(e.target.value);
                        // reset teh csv 
                        setCsvFile(null)
                        setProviderError(null)
                        setParsedCourses([])
                        onUploadTypeChange(e.target.value);
                    }}
                >
                    <FormControlLabel value="single" control={<Radio />} label="Single Course" />
                    <FormControlLabel value="bulk" control={<Radio />} label="Bulk Upload" />
                </RadioGroup>
            </FormControl>

            {uploadType === 'single' ? (
                <>            
                    <TextField
                        {...register('courseUrl')}
                        label="Course URL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!(errors.courseUrl || errors.courseUrlOrPdfFile)}
                        helperText={errors.courseUrl?.message || (errors.courseUrlOrPdfFile && !pdfFile && 'Either Course URL or PDF file is required')}
                        />
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Button variant="outlined" component="label" fullWidth>
                            Upload PDF
                            <input
                                {...register('pdfFile')}
                                type="file"
                                accept=".pdf"
                                hidden
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    setPdfFile(file); // Update local state
                                    setValue('pdfFile', e.target.files[0]);
                                }}
                            />
                        </Button>
                        {pdfFile && (
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body2" noWrap>
                                    {pdfFile.name}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setPdfFile(null);
                                        setValue('pdfFile', null);
                                    }}
                                >
                                    Clear
                                </Button>
                            </Box>
                        )}
                        {errors.pdfFile && (
                            <Typography color="error" sx={{ mt: 1 }}>
                                {errors.pdfFile.message}
                            </Typography>
                        )}
                    </Box>
                    
        
                    <TextField
                        label="Additional Info"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                </>
            ) : (
                <>

                    <Typography sx={{ mt: 2 }}>
                        Upload a CSV file with the following columns:
                    </Typography>
                    <ul>
                        <li>url</li>
                        <li>name</li>
                        <li>brochure_url</li>
                        <li>video_url</li>
                        <li>additional_info</li>
                        <li>instructor_name_1</li>
                        <li>instructor_linkedin_1</li>
                        <li>instructor_bio_1</li>
                        <li>instructor_avatar_url_1</li>
                        <li>instructor_name_2</li>
                        <li>instructor_linkedin_2</li>
                        <li>...</li>
                    </ul>
                    <Typography sx={{ mt: 2 }}>
                        Need a template? <a href="https://docs.google.com/spreadsheets/d/1pfSneLmPN2y7CpkSjGJ8ZW8vxEBRNot0xV0RRnr6YBc/edit#gid=0" target="_blank" rel="noopener noreferrer">You can copy this spreadsheet</a>.
                    </Typography>
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Button variant="outlined" component="label" fullWidth>
                            Upload CSV
                            <input
                                type="file"
                                accept=".csv"
                                hidden
                                onChange={handleCsvUpload}
                            />
                        </Button>
                        {csvFile && (
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body2" noWrap>
                                    {csvFile.name}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setCsvFile(null);
                                    }}
                                >
                                    Clear
                                </Button>
                            </Box>
                        )}
                    </Box>
                </>
            )}
            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>




            <FormControl fullWidth margin="normal">
                <InputLabel id="provider-select-label">Education Provider</InputLabel>
                <Select
                    labelId="provider-select-label"
                    id="provider-select"
                    value={selectedProvider}
                    label="Education Provider"
                    onChange={(e) => {
                        setSelectedProvider(e.target.value);
                        setProviderError(false);
                    }}
                >
                    {providers.map((provider) => (
                        <MenuItem key={provider._id} value={provider._id}>
                            {provider.name}
                        </MenuItem>
                    ))}
                </Select>
                {providerError && (
                    <Typography color="error" sx={{ mt: 1 }}>
                        Please select an education provider.
                    </Typography>
                )}
            </FormControl>

            {parsedCourses.length > 0 && (
                <>
                    <Typography variant="h6" sx={{ mt: 4 }}>
                        Preview Courses
                    </Typography>
                    <Box sx={{ width: '100%', overflowX: 'auto' }}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell> {/* Header for the counter */}
                                        <TableCell>URL</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Brochure URL</TableCell>
                                        <TableCell>Video URL</TableCell>
                                        <TableCell>Additional Info</TableCell>
                                        <TableCell>Instructors</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {parsedCourses.map((course, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{course.id}</TableCell> {/* Display the counter */}

                                            <TableCell>
                                                <a href={course.url} target="_blank" rel="noopener noreferrer">
                                                    {course.url.length > 20 ? course.url.slice(0, 20) + '...' : course.url}
                                                </a>
                                            </TableCell>
                                            <TableCell>{course.name}</TableCell>
                                            <TableCell>
                                                {course.brochureUrl && (
                                                    <a href={course.brochureUrl} target="_blank" rel="noopener noreferrer">
                                                        {course.brochureUrl.length > 20 ? course.brochureUrl.slice(0, 20) + '...' : course.brochureUrl}
                                                    </a>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {course.videoUrl && (
                                                    <a href={course.videoUrl} target="_blank" rel="noopener noreferrer">
                                                        {course.videoUrl.length > 20 ? course.videoUrl.slice(0, 20) + '...' : course.videoUrl}
                                                    </a>
                                                )}
                                            </TableCell>
                                            <TableCell>{course.additionalInfo}</TableCell>
                                            <TableCell>
                                                {course.instructors.map((instructor, idx) => (
                                                    <div key={idx} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                        {instructor.avatarUrl && (
                                                            <img
                                                                src={instructor.avatarUrl}
                                                                alt={`${instructor.name}'s avatar`}
                                                                style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '8px' }}
                                                            />
                                                        )}
                                                        <div>
                                                            {instructor.name} -{' '}
                                                            {instructor.linkedin && (
                                                                <a href={instructor.linkedin} target="_blank" rel="noopener noreferrer">
                                                                    {instructor.linkedin.replace('https://www.linkedin.com/', '')}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={onSubmit}
                    >
                        Start Course Upload
                    </Button>
                </>
            )}
            <Backdrop 
                open={isSubmitting} 
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    flexDirection: 'column',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)' // Adjust the opacity as needed

                }}                
            >
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <CircularProgress color="inherit" />
                    <Typography sx={{ mt: 2 }}>{submissionMessage}</Typography>
                </Box>
            </Backdrop>

            {!isSubmitting && uploadType === 'single' && (
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Create Course
                </Button>
            )}
        </form>  
    );
    
};

export default MydraAddCourseComponent;

