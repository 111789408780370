import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
    Container,
    Typography,
    Switch,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Pagination,
    Chip,
    IconButton,
    Collapse,
    Link,
    Grid,
    Alert,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const OrderDetailsRow = ({ order, allPayments, isProduction }) => {
    const [open, setOpen] = useState(false);

    const relatedPayments = order.paymentDetails.map(pd => 
        allPayments.find(p => p._id === pd.document)
    ).filter(Boolean);

    console.log('Related payments:', relatedPayments);

    const user = relatedPayments[0]?.user; // Assuming all payments in an order are from the same user

    const getCourseUrl = (courseId) => {
        const baseUrl = isProduction
            ? 'https://marketplace.mydra.io'
            : 'https://marketplace.staging.mydra.io';
        return `${baseUrl}/courses/${courseId}`;
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'PAID':
                return 'success';
            case 'PENDING':
                return 'warning';
            case 'FAILED':
                return 'error';
            default:
                return 'default';
        }
    };


    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{order._id}</TableCell>
                <TableCell>{`${user?.firstName} ${user?.lastName}`}</TableCell>
                <TableCell>
                    <Link 
                        href={getCourseUrl(order.item.course)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {order.item.course}
                    </Link>
                </TableCell>
                <TableCell>{order.paymentTerm}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>{`${order.totalAmount / 100} ${order.currency}`}</TableCell>
                <TableCell>{new Date(order.createdAt).toLocaleDateString()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Related Payments
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Payment ID</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Payment Type</TableCell>
                                        <TableCell>Created At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {relatedPayments.map((payment) => (
                                        <TableRow key={payment._id}>
                                            <TableCell component="th" scope="row">
                                                {payment._id}
                                            </TableCell>
                                            <TableCell>{`${payment.totalAmount / 100} ${payment.currency}`}</TableCell>
                                            <TableCell>
                                                <Chip 
                                                    label={payment.status} 
                                                    color={getStatusColor(payment.status)}
                                                />
                                            </TableCell>
                                            <TableCell>{payment.paymentType}</TableCell>
                                            <TableCell>{new Date(payment.createdAt).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};



const UserPaymentsPage = () => {
    const [payments, setPayments] = useState([]);
    const [orders, setOrders] = useState([]);

    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
        status: '',
        paymentType: '',
        userId: '',
    });
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 20,
        totalPages: 1,
    });

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    

    
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        if (authToken && userEmail) {
            fetchPaymentsAndOrders();
        }
    }, [authToken, userEmail, isProduction, filters, pagination.page, pagination.limit]);

    
    const fetchPaymentsAndOrders = async () => {
        try {
            setIsLoading(true);
            const baseParams = {
                ...filters,
                page: pagination.page,
                limit: pagination.limit,
                include: 'order,user',
            };

            const response = await fetch(`${API_BASE_URL}/mydra/payments?${new URLSearchParams(baseParams)}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            const responseData = await response.json();

            console.log('Payments response:', responseData);

            if (responseData && Array.isArray(responseData.data)) {
                setPayments(responseData.data);
                const uniqueOrders = [...new Set(responseData.data.map(payment => payment.order))];
                setOrders(uniqueOrders);
                setPagination(prevPagination => ({
                    ...prevPagination,
                    totalPages: Math.ceil(responseData.metadata.total / pagination.limit),
                }));
            } else {
                console.error('Unexpected response structure:', responseData);
                setPayments([]);
                setOrders([]);
            }
        } catch (error) {
            console.error('Error fetching payments:', error);
            setPayments([]);
            setOrders([]);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value,
        }));
        setPagination(prevPagination => ({
            ...prevPagination,
            page: 1,
        }));
    };

    const handlePageChange = (event, newPage) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: newPage,
        }));
    };


    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
                User Payments and Orders
            </Typography>

            <Box display="flex" alignItems="center" mb={2} ml={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact live data.
                </Typography>
            )}

            <Box mb={2}>
                <FormControl sx={{ mr: 2, minWidth: 120 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        name="status"
                        value={filters?.status}
                        onChange={handleFilterChange}
                        label="Status"
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="AUTHORIZED">Authorized</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="PAID">Paid</MenuItem>
                        <MenuItem value="FAILED">Failed</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ mr: 2, minWidth: 120 }}>
                    <InputLabel>Payment Type</InputLabel>
                    <Select
                        name="paymentType"
                        value={filters.paymentType}
                        onChange={handleFilterChange}
                        label="Payment Type"
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="REPAYMENT_TO_EP">Repayment to EP</MenuItem>
                        <MenuItem value="FIXPAY_INSTALMENT">Fixpay Instalment</MenuItem>
                        <MenuItem value="UPFRONT">Upfront</MenuItem>
                        <MenuItem value="LEARNING_BUDGET">Learning Budget</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    name="userId"
                    label="User ID"
                    value={filters.userId}
                    onChange={handleFilterChange}
                    sx={{ mr: 2 }}
                />
                <Button variant="contained" onClick={fetchPaymentsAndOrders}>Apply Filters</Button>
            </Box>

            {isLoading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : orders.length > 0 ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Order ID</TableCell>
                                    <TableCell>User</TableCell>
                                    <TableCell>Course</TableCell>
                                    <TableCell>Payment Term</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Total Amount</TableCell>
                                    <TableCell>Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order) => (
                                    <OrderDetailsRow key={order._id} order={order} allPayments={payments} isProduction={isProduction} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                            count={pagination.totalPages}
                            page={pagination.page}
                            onChange={handlePageChange}
                            color="primary"
                            disabled={payments.length === 0}

                        />
                    </Box>
                </>
            ) : (
                <Alert severity="info" sx={{ mt: 3 }}>
                    No payments found for the current page. This might be due to a backend pagination issue. 
                    Please try returning to the first page or adjusting your filters.
                </Alert>
            )}
        </Container>
    );
};

export default UserPaymentsPage;
