
import React, { useEffect, useState, useMemo} from 'react';
import { 
    Typography, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Paper, Box, Avatar, Link, Tooltip, Alert,
    IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Snackbar,
    CircularProgress, Checkbox, FormControlLabel, Toolbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email'; // Importing EmailIcon
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReplayIcon from '@mui/icons-material/Replay';


import { getFirestore, doc, updateDoc, writeBatch } from 'firebase/firestore';
import app from '../../firebaseConfig';


const EducationProviderIdentifiedData = ({ data, connectionStatus, isProduction, siteId, authToken, userEmail }) => {
    const theme = useTheme();
    const linkedinProfiles = data.linkedin_urls || [];
    const emailLinks = data.email_links || [];
    const [identifiedUrls, setIdentifiedUrls] = useState(data.identified_urls || []);
    const [openDialog, setOpenDialog] = useState(false);
    const [courseToDiscard, setCourseToDiscard] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [uploadingCourseId, setUploadingCourseId] = useState(null); // New state for uploading course ID
    const [uploadConfirmDialogOpen, setUploadConfirmDialogOpen] = useState(false); // New state for upload confirmation dialog
    const [selectedCourses, setSelectedCourses] = useState([]);
    // const for selectAllselected
    const [selectAllSelected, setSelectAllSelected] = useState(false);
    const [uniqueCourses, setUniqueCourses] = useState([]);


    // New state variables for sorting
    const [sortField, setSortField] = useState('course_name');
    const [sortDirection, setSortDirection] = useState('asc');
    

    const db = getFirestore(app);

    // Add this new function to modify the URL
    const modifyCegosUrl = (url) => {
        if (url.startsWith('https://cegos.es/formacion')) {
            return url.replace('https://cegos.es', 'https://www.cegos.es');
        }
        return url;
    };

    useEffect(() => {
        console.log("Original courses count:", data.identified_urls?.length);

        // Create a Map to store unique courses
        const courseMap = new Map();

        (data.identified_urls || []).forEach(course => {
            const courseName = course.result.course_name || '';
            const modifiedUrl = modifyCegosUrl(course.url); // Modify the URL
            if (!courseMap.has(courseName) || modifiedUrl.length < courseMap.get(courseName).url.length) {
                courseMap.set(courseName, {...course, url: modifiedUrl}); // Store the modified URL
                if (courseMap.has(courseName)) {
                    console.log(`Replaced URL for "${courseName}": ${modifiedUrl}`);
                }
            }
        });

        // Convert the Map values to an array and sort by name
        const sortedUniqueCourses = Array.from(courseMap.values()).sort((a, b) => {
            const nameA = (a.result.course_name || '').toLowerCase();
            const nameB = (b.result.course_name || '').toLowerCase();
            return nameA.localeCompare(nameB);
        });

        console.log("Unique courses count:", sortedUniqueCourses.length);
        console.log("Unique courses:", sortedUniqueCourses.map(course => ({
            name: course.result.course_name,
            url: course.url
        })));

        setUniqueCourses(sortedUniqueCourses);
    }, [data]);

    const formatCourseCost = (cost) => {
        if (!cost) return 'N/A';
        if (typeof cost === 'object') {
            return `${cost.cost_amount || 'N/A'} ${cost.cost_currency || ''} ${cost.cost_interval ? `per ${cost.cost_interval}` : ''}`;
        }
        return cost;
    };

    useEffect(() => {
        setIdentifiedUrls(data.identified_urls || []);
    }, [data]);

    // console.log('identifiedUrls:', identifiedUrls); 

    const tableRowStyle = (status) => ({
        cursor: 'pointer',
        backgroundColor: 
            status === 'discarded' ? theme.palette.grey[200] : 
            status === 'uploading' ? 'rgba(209, 227, 255, 0.3)' : // Light blue color for uploading
            status === 'uploaded' ? 'rgba(209, 227, 255, 0.5)' :  // Light green color for uploaded
            'inherit',
        color: status === 'discarded' ? theme.palette.text.disabled : 'inherit',
        fontWeight: status === 'uploading' ? 'bold' : 'inherit',
        opacity: status === 'discarded' ? 0.5 : 1,
        '&:hover': {
            backgroundColor: 
                status === 'discarded' ? theme.palette.grey[200] :
                status === 'uploading' ? 'rgba(173, 216, 230, 0.7)' :  // Slightly darker blue on hover
                status === 'uploaded' ? 'rgba(209, 227, 255, 0.5)' : theme.palette.action.hover,
        },
    });

    const tableHeaderStyle = {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    };

    const tableCellStyle = {
        padding: theme.spacing(1, 2), // Adjust padding to align columns
        maxWidth: '280px',
    };

    const truncatedUrlStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
        display: 'inline-block',
        verticalAlign: 'middle'
    };

    const extractPathFromUrl = (url) => {
        try {
            const urlObj = new URL(url);
            return urlObj.pathname + urlObj.search;
        } catch (e) {
            return url; // return the original URL if parsing fails
        }
    };

    const handleDiscard = async (courseIds) => {
        const idsToDiscard = Array.isArray(courseIds) ? courseIds : [courseIds];
        console.log(`Discarding courses with IDs: ${idsToDiscard.join(', ')}`);
        
        // Find and log the courses being discarded
        const coursesToDiscard = uniqueCourses.filter(course => idsToDiscard.includes(course.id));
        console.log("Courses being discarded:", coursesToDiscard.map(course => ({
            id: course.id,
            name: course.result.course_name,
            url: course.url
        })));

        try {
            const batch = writeBatch(db);
    
            idsToDiscard.forEach((courseId) => {
                const courseDocRef = doc(db, 'sites', siteId, 'identified_urls', courseId);
                batch.update(courseDocRef, {
                    [`mydra_status.${isProduction ? 'Production' : 'Staging'}`]: 'discarded',
                });
            });
    
            await batch.commit();
    
            setUniqueCourses((prevCourses) =>
                prevCourses.map((course) =>
                    idsToDiscard.includes(course.id)
                        ? { ...course, mydra_status: { ...course.mydra_status, [isProduction ? 'Production' : 'Staging']: 'discarded' } }
                        : course
                )
            );
    
            setSelectedCourses([]);
            setSelectAllSelected(false);
    
            console.log(`Courses with IDs: ${idsToDiscard.join(', ')} marked as discarded.`);
            setSnackbarMessage(`Courses with IDs: ${idsToDiscard.join(', ')} marked as discarded.`);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setCourseToDiscard(null);
            setOpenDialog(false);
        } catch (error) {
            console.error('Error updating course status:', error);
            setSnackbarMessage('Failed to discard course(s). Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };


    const handleOpenUploadConfirmDialog = (courseIds) => {
        setUploadingCourseId(courseIds);
        setUploadConfirmDialogOpen(true);
    };

    const handleCloseUploadConfirmDialog = () => {
        setUploadingCourseId(null);
        setUploadConfirmDialogOpen(false);
    };

    const handleUploadConfirm = () => {
        handleConfirmUpload(uploadingCourseId);
        handleCloseUploadConfirmDialog();
    };


    const handleConfirmUpload = async (courseIds) => {
        const idsToUpload = Array.isArray(courseIds) ? courseIds : [courseIds];
        
        if (idsToUpload.length === 0) return;
    
        console.log(`Uploading courses with IDs: ${idsToUpload.join(', ')} to Mydra`);
    
        const coursesToUpload = uniqueCourses.filter(course => idsToUpload.includes(course.id));
        if (coursesToUpload.length === 0) {
            console.error('Courses not found:', idsToUpload);
            setSnackbarMessage('Courses not found. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
    
        for (const course of coursesToUpload) {
            try {
                const courseDocRef = doc(db, 'sites', siteId, 'identified_urls', course.id);
                await updateDoc(courseDocRef, {
                    [`mydra_status.${isProduction ? 'Production' : 'Staging'}`]: 'uploading',
                });

                const formData = new FormData();
                formData.append('courseUrl', modifyCegosUrl(course.url)); // Use the modified URL
                formData.append('educationProviderId', isProduction ? connectionStatus.Production : connectionStatus.Staging);
                formData.append('additionalInfo', course.additionalInfo || '');
    
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mydra/course`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'X-Environment': isProduction ? 'Production' : 'Staging',
                        'X-User-Email': userEmail || '',
                    },
                    body: formData,
                });
    
                if (!response.ok) {
                    throw new Error(`Failed to upload course ${course.id}: ${response.statusText}`);
                }
    
                const responseData = await response.json();
                console.log(`API Response for course ${course.id}:`, responseData);
                
                // Update local state to reflect 'uploading' status
                setUniqueCourses((prevCourses) =>
                    prevCourses.map((c) =>
                        c.id === course.id
                            ? { ...c, mydra_status: { ...c.mydra_status, [isProduction ? 'Production' : 'Staging']: 'uploading' } }
                            : c
                    )
                );

                console.log(`Course ${course.id} set to uploading status.`);
            } catch (error) {
                console.error(`Error uploading course ${course.id}:`, error);

                const courseDocRef = doc(db, 'sites', siteId, 'identified_urls', course.id);
                await updateDoc(courseDocRef, {
                    [`mydra_status.${isProduction ? 'Production' : 'Staging'}`]: 'error',
                });

                setUniqueCourses((prevCourses) =>
                    prevCourses.map((c) =>
                        c.id === course.id
                            ? { ...c, mydra_status: { ...c.mydra_status, [isProduction ? 'Production' : 'Staging']: 'error' } }
                            : c
                    )
                );

                setSnackbarMessage(`Failed to upload course ${course.id}: ${error.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        }
    
        setSelectedCourses([]);
        setSelectAllSelected(false);
        setUploadingCourseId(null);
        setUploadConfirmDialogOpen(false);
    
        console.log(`Upload process completed for courses with IDs: ${idsToUpload.join(', ')}`);
        setSnackbarMessage(`Courses with IDs: ${idsToUpload.join(', ')} set to uploading status. Use 'Match Courses' to complete the process.`);
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
    };




    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            console.log('Select all courses');
            const newSelectedCourses = uniqueCourses
                .filter((course) => !(
                    course.mydra_status && 
                    (
                        course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'discarded' ||
                        course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'uploaded' ||
                        course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'uploading'
                    )
                ))
                .map((course) => course.id);
            setSelectedCourses(newSelectedCourses);
            setSelectAllSelected(true);
        } else {
            console.log('Deselect all courses');
            setSelectedCourses([]);
            setSelectAllSelected(false);
        }
    };
    


    const handleClick = (event, id) => {
        const course = uniqueCourses.find((course) => course.id === id);
        if (course.mydra_status && (
            course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'discarded' ||
            course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'uploaded' ||
            course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'uploading'
        )) {
            return;
        }
    
        const selectedIndex = selectedCourses.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCourses, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCourses.slice(1));
        } else if (selectedIndex === selectedCourses.length - 1) {
            newSelected = newSelected.concat(selectedCourses.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCourses.slice(0, selectedIndex),
                selectedCourses.slice(selectedIndex + 1),
            );
        }
    
        console.log(`Course ${id} selection toggled: ${newSelected}`);
        setSelectedCourses(newSelected);
        setSelectAllSelected(false);
    };

    const isSelected = (id) => selectedCourses.indexOf(id) !== -1;



    const handleOpenDialog = (courseId) => {
        setCourseToDiscard(Array.isArray(courseId) ? courseId : [courseId]);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCourseToDiscard(null);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const isUploadButtonEnabled = connectionStatus[isProduction ? 'Production' : 'Staging'];

    const handleViewOnMydra = (course) => {
        const courseId = course.mydra_id[isProduction ? 'Production' : 'Staging'];
        const url = isProduction
            ? `https://marketplace.mydra.io/en/courses/${courseId}`
            : `https://marketplace.staging.mydra.io/en/courses/${courseId}`;
        window.open(url, '_blank');
    };
    
    const handleViewOnMydraHub = (course) => {
        const courseId = course.mydra_id[isProduction ? 'Production' : 'Staging'];
        const url = isProduction
            ? `https://www.mydrahub.com/mydra/course/${courseId}`
            : `https://www.mydrahub.com/mydra/course/${courseId}?env=Staging`;
        window.open(url, '_blank');
    };
    
    // New function to handle sorting
    const handleSort = (field) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortField(field);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    // Function to sort the courses
    const sortedCourses = useMemo(() => {
        const comparator = (a, b) => {
            if (!a.result[sortField]) return 1;
            if (!b.result[sortField]) return -1;
            const valueA = (a.result[sortField] || '').toLowerCase();
            const valueB = (b.result[sortField] || '').toLowerCase();
            if (valueA < valueB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        };
        return [...identifiedUrls].sort(comparator);
    }, [identifiedUrls, sortField, sortDirection]);

    const handleRetry = (courseId) => {
        console.log(`Retrying upload for course: ${courseId}`);
        handleConfirmUpload([courseId]);
    };


    return (
        <div>
            <Typography variant="h5" gutterBottom>
                {/* Courses ({identifiedUrls.length}) */}
                Unique Courses: {uniqueCourses.length}. Total courses: {identifiedUrls.length}
            </Typography>
            <Toolbar>
                <FormControlLabel
                    control={<Checkbox
                        indeterminate={selectedCourses.length > 0 && selectedCourses.length < identifiedUrls.length}
                        checked={selectAllSelected}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all courses' }}
                    />}
                    label="Select All"
                />
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleOpenDialog(selectedCourses)}
                    disabled={selectedCourses.length === 0}
                >
                    Discard Selected
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => handleOpenUploadConfirmDialog(selectedCourses)}
                    disabled={selectedCourses.length === 0 || !isUploadButtonEnabled}
                    sx={{ ml: 2 }}
                >
                    Upload Selected
                </Button>
            </Toolbar>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableHeaderStyle} padding="checkbox">
                            <Checkbox
                                indeterminate={selectedCourses.length > 0 && selectedCourses.length < identifiedUrls.filter(course => !(course.mydra_status && course.mydra_status[isProduction ? 'Production' : 'Staging'] === 'discarded')).length}
                                checked={selectAllSelected}
                                onChange={handleSelectAllClick}
                                inputProps={{ 'aria-label': 'select all courses' }}
                            />
                            </TableCell>
                            <TableCell sx={tableHeaderStyle}>#</TableCell>
                            <TableCell sx={tableHeaderStyle}>Course Name</TableCell>
                            <TableCell sx={tableHeaderStyle}>URL</TableCell>
                            <TableCell sx={tableHeaderStyle}>Category</TableCell>
                            <TableCell sx={tableHeaderStyle}>Cost</TableCell>
                            <TableCell sx={tableHeaderStyle}>Location</TableCell>
                            <TableCell sx={tableHeaderStyle}>Actions</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {uniqueCourses.map((course, index) => {
                            const isItemSelected = isSelected(course.id);
                            const status = course.mydra_status && course.mydra_status[isProduction ? 'Production' : 'Staging'];
                            const isDisabled = status === 'discarded' || status === 'uploaded';
                            const isUploading = status === 'uploading';
                            const isError = status === 'error';
               
                            return (
                                <TableRow
                                    key={index}
                                    sx={tableRowStyle(course.mydra_status && course.mydra_status[isProduction ? 'Production' : 'Staging'])}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            onChange={(event) => handleClick(event, course.id)}
                                            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                                            disabled={isDisabled} // Disable checkbox for discarded, uploaded, or uploading courses
                                            />
                                    </TableCell>
                                    {/* <TableCell sx={tableCellStyle}>{index + 1}</TableCell> */}
                                    <TableCell sx={tableCellStyle}>
                                        <Tooltip title={`Course ID: ${course.id}`} arrow>
                                            <span>{index + 1}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>{course.result.course_name || 'N/A'}</TableCell>
                                    <TableCell sx={tableCellStyle}>
                                        <Tooltip title={course.url}>
                                            <Link href={course.url} target="_blank" rel="noopener noreferrer" sx={truncatedUrlStyle}>
                                                {extractPathFromUrl(course.url)}
                                            </Link>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>{course.result.course_category || 'N/A'}</TableCell>
                                    <TableCell sx={tableCellStyle}>{formatCourseCost(course.result.course_cost)}</TableCell>
                                    <TableCell sx={tableCellStyle}>{course.result.course_location || 'N/A'}</TableCell>
                                   
                                    <TableCell sx={tableCellStyle}>
                                        {course.mydra_id && course.mydra_id[isProduction ? 'Production' : 'Staging'] ? (
                                            <>
                                                <Button 
                                                    variant="text" 
                                                    color="primary" 
                                                    onClick={() => handleViewOnMydra(course)}
                                                    sx={{ mr: 1 }}
                                                    startIcon={<OpenInNewIcon />}
                                                >
                                                    Mydra
                                                </Button>
                                                <Button 
                                                    variant="text" 
                                                    color="secondary" 
                                                    onClick={() => handleViewOnMydraHub(course)}
                                                    startIcon={<OpenInNewIcon />}
                                                >
                                                    MydraHub
                                                </Button>
                                            </>
                                        ) : isUploading || isError ? (
                                            <Box display="flex" alignItems="center">
                                                {isUploading && <CircularProgress size={22} sx={{ mr: 1 }} />}
                                                <Typography 
                                                    variant="body2" 
                                                    color={isError ? "error" : "inherit"} 
                                                    sx={{ mr: 1 }}
                                                >
                                                    {isUploading ? "Uploading..." : "Error"}
                                                </Typography>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleRetry(course.id)}
                                                    title="Retry upload"
                                                >
                                                    <ReplayIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <>
                                                <IconButton
                                                    onClick={() => handleOpenDialog(course.id)}
                                                    disabled={isDisabled}
                                                >
                                                    <DeleteIcon sx={{ color: isDisabled ? 'gray' : 'red' }} />
                                                </IconButton>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleOpenUploadConfirmDialog(course.id)}
                                                    disabled={!isUploadButtonEnabled || isDisabled}
                                                >
                                                    <CloudUploadIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h5" gutterBottom>
                LinkedIn Profiles ({linkedinProfiles.length})
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableHeaderStyle}>#</TableCell>
                            <TableCell sx={tableHeaderStyle}>Profile</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {linkedinProfiles.map((profile, index) => (
                            <TableRow key={index} sx={tableRowStyle()}>
                                <TableCell sx={tableCellStyle}>{index + 1}</TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <Box display="flex" alignItems="center">
                                        <Avatar alt={`LinkedIn Profile ${index + 1}`} src={`https://www.linkedin.com/favicon.ico`} sx={{ mr: 2 }} />
                                        <Link href={profile} target="_blank" rel="noopener noreferrer">
                                            {profile}
                                        </Link>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Divider /> */}

            <Typography variant="h5" gutterBottom>
                Email Links ({emailLinks.length})
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableHeaderStyle}>#</TableCell>
                            <TableCell sx={tableHeaderStyle}>Email Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emailLinks.map((email, index) => (
                            <TableRow key={index} sx={tableRowStyle()}>
                                <TableCell sx={tableCellStyle}>{index + 1}</TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <Box display="flex" alignItems="center">
                                        <EmailIcon sx={{ mr: 2, color: theme.palette.primary.main }} />
                                        <Link href={email} target="_blank" rel="noopener noreferrer">
                                            {email.replace('mailto://', '')}
                                        </Link>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="confirm-discard-dialog-title"
                aria-describedby="confirm-discard-dialog-description"
            >
                <DialogTitle id="confirm-discard-dialog-title">Confirm Discard</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-discard-dialog-description">
                        Are you sure you want to discard the selected course(s)?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDiscard(courseToDiscard || selectedCourses)} color="primary" autoFocus>

                        Discard
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={uploadConfirmDialogOpen}
                onClose={handleCloseUploadConfirmDialog}
                aria-labelledby="confirm-upload-dialog-title"
                aria-describedby="confirm-upload-dialog-description"
            >
                <DialogTitle id="confirm-upload-dialog-title">Confirm Upload</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-upload-dialog-description">
                        Are you sure you want to upload the selected course(s)?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUploadConfirmDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUploadConfirm} color="primary" autoFocus>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default EducationProviderIdentifiedData;

