import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, CardHeader, IconButton, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat'; // Icon for Motivation
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'; // Icon for Video
import PersonIcon from '@mui/icons-material/Person'; // Icon for Advisor

const CourseMotivationSection = ({ course, selectedLanguage,  isSectionEditable, onEditSection, onSaveSection, isSaving }) => {
    const [editableMotivation, setEditableMotivation] = useState('');
    const [originalMotivation, setOriginalMotivation] = useState('');
    const [editableVideoUrl, setEditableVideoUrl] = useState('');
    const [originalVideoUrl, setOriginalVideoUrl] = useState('');
    const [editableAdvisor, setEditableAdvisor] = useState({});
    const [originalAdvisor, setOriginalAdvisor] = useState({});

    const editable = isSectionEditable('courseMotivation');

    useEffect(() => {
        const courseDetail = getCourseDetailByLanguage(course.courseDetailTranslations, selectedLanguage);
        // console.log('courseDetail:', courseDetail);

        setOriginalMotivation(courseDetail?.courseMotivation?.motivation || '');
        setEditableMotivation(courseDetail?.courseMotivation?.motivation || '');
        setOriginalVideoUrl(courseDetail?.courseMotivation?.videoUrl || '');
        setEditableVideoUrl(courseDetail?.courseMotivation?.videoUrl || '');
        setOriginalAdvisor(courseDetail?.courseMotivation?.advisor || {});
        setEditableAdvisor(courseDetail?.courseMotivation?.advisor || {});
    }, [course, selectedLanguage]);
    
    // Function to get course details by selected language
    const getCourseDetailByLanguage = (courseDetailTranslations, selectedLanguage) => {
        if (!courseDetailTranslations || !Array.isArray(courseDetailTranslations)) {
            return null;
        }
        const courseDetail = courseDetailTranslations.find(detail => detail.language === selectedLanguage);
        return courseDetail ? { ...courseDetail, courseMotivation: courseDetail.courseMotivation || {} } : {};
    };

    const currentCourseDetail = getCourseDetailByLanguage(course.courseDetailTranslations, selectedLanguage);
    // console.log('currentCourseDetail:', currentCourseDetail);



    const handleCancel = () => {
        setEditableMotivation(originalMotivation);
        setEditableVideoUrl(originalVideoUrl);
        setEditableAdvisor(originalAdvisor);
        onEditSection(null);
    };

    const handleSave = () => {
        const translationFields = {
            motivation: editableMotivation,
        };
    
        const nonTranslationFields = {
            videoUrl: editableVideoUrl,
            advisor: editableAdvisor,
        };
    
        const updatePayload = {
            language: selectedLanguage,
            translationFields,
            nonTranslationFields,
        };
    
        console.log('Saving course with data:', updatePayload);
        onSaveSection('courseMotivation', updatePayload);
    };

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    editable ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: 'common.white',
                                    borderColor: 'common.white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'common.white',
                                    },
                                }}
                                onClick={handleCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <IconButton onClick={() => onEditSection('courseMotivation')} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
                title="Course Motivation"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{ backgroundColor: 'primary.light', color: 'common.white' }}
            />
            <CardContent>
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <ChatIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Motivation
                </Typography>
                {editable ? (
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={editableMotivation}
                        onChange={(e) => setEditableMotivation(e.target.value)}
                    />
                ) : (
                    <Typography variant="body1">
                        {/* {editableMotivation} */}
                        {currentCourseDetail?.courseMotivation?.motivation}
                    </Typography>
                )}
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <VideoLibraryIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Video URL
                </Typography>
                {editable ? (
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={editableVideoUrl}
                        onChange={(e) => setEditableVideoUrl(e.target.value)}
                    />
                ) : (
                    <Typography variant="body1">{editableVideoUrl}</Typography>
                )}
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <PersonIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Advisor
                </Typography>
                {editable ? (
                    <Box>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            value={editableAdvisor.name}
                            onChange={(e) => setEditableAdvisor({ ...editableAdvisor, name: e.target.value })}
                            sx={{ mb: 1 }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Role"
                            value={editableAdvisor.role}
                            onChange={(e) => setEditableAdvisor({ ...editableAdvisor, role: e.target.value })}
                            sx={{ mb: 1 }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Avatar URL"
                            value={editableAdvisor.avatarUrl}
                            onChange={(e) => setEditableAdvisor({ ...editableAdvisor, avatarUrl: e.target.value })}
                        />
                    </Box>
                ) : (
                    <>
                        <Typography variant="body1">{editableAdvisor.name}</Typography>
                        <Typography variant="body1">{editableAdvisor.role}</Typography>
                        <Typography variant="body1">{editableAdvisor.avatarUrl}</Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default CourseMotivationSection;