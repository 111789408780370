import React, { useState } from 'react';
import { Box, Typography, Switch, IconButton, CircularProgress, Button, Dialog, 
    DialogTitle, DialogContent, DialogActions, Tooltip, Snackbar, Alert,
    FormControl, InputLabel, Select, MenuItem, Paper 

} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';


import QualityScoreBreakdown from '../QualityScoreBreakdwown/QualityScoreBreakdown';
const MAX_QUALITY_SCORE = 32;

const calculateQualityScorePercentage = (score) => {
    return Math.round((score / MAX_QUALITY_SCORE) * 100);
};


const getQualityScoreColor = (score) => {
    const percentage = (score / MAX_QUALITY_SCORE) * 100;
    if (percentage > 75) return 'darkgreen';
    if (percentage > 50) return 'green';
    if (percentage > 25) return 'orange';
    return 'darkred';
};

const languages = [
    { value: 'en', label: 'English', flag: 'GB' },
    { value: 'es', label: 'Spanish', flag: 'ES' },
    { value: 'fr', label: 'French', flag: 'FR' },
    { value: 'de', label: 'German', flag: 'DE' },
    { value: 'it', label: 'Italian', flag: 'IT' },
    {value: 'pt', label: 'Portuguese', flag: 'PT'},
];


const MydraCoursePublishedStatus = ({ course, authToken, userEmail, isProduction, fetchCourseDetails, selectedLanguage, handleLanguageChange }) => {
    const [isPublished, setIsPublished] = useState(course.published);
    const [isLoading, setIsLoading] = useState(false); // Add a loading state
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility

    const navigate = useNavigate();
    
    console.log("I'm in MydraCoursePublishedStatus");
    console.log("course", course);
    console.log("qualityScoreCalculatedAt", course.qualityScoreCalculatedAt);

    const mydraBaseUrl = isProduction ? 'https://marketplace.mydra.io/en' : 'https://marketplace.staging.mydra.io/en';

    const handleTogglePublished = async () => {
        setIsLoading(true); // Set loading to true when the toggle is clicked
        const newPublishedStatus = !isPublished;
        // Prepare the payload to update the published status
        setIsPublished(newPublishedStatus);

        const nonTranslationFields = {
            published: newPublishedStatus,
        };

        const payload = {
            nonTranslationFields : nonTranslationFields,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mydra/courses/${course._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                // If the update is successful, update the local state and refetch course details
                setIsPublished(newPublishedStatus);
                fetchCourseDetails(userEmail);
            } else {
                // Handle errors
                setIsPublished(!newPublishedStatus);

                console.error('Failed to update course published status');
            }
        } catch (error) {
            setIsPublished(!newPublishedStatus);
            console.error('Error updating course published status:', error);
        } finally {
            setIsLoading(false); // Set loading to false after the request is complete
        }
    };

    const handleDeleteCourse = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mydra/courses/${course._id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'X-User-Email': userEmail,
              'X-Environment': isProduction ? 'Production' : 'Staging',
            },
          });
          if (response.ok) {
            console.log('Course deleted successfully');
            setSnackbarOpen(true);

            setTimeout(() => {
                // After showing the snackbar, reload the page
                navigate('/mydra/course');
                
                window.location.reload();
                
                
            }, 2000); // Delay to show snackbar message before reloading

          } else {
            console.error('Failed to delete course');
            // print response status
            console.log(response.status);
        
            console.error('Failed to delete course');
          }
        } catch (error) {
          console.error('Error deleting course:', error);
        } finally {
          setIsLoading(false);
          setDeleteConfirmationOpen(false);
        }
      };

      // Close the snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    
    const qualityScorePercentage = calculateQualityScorePercentage(course.qualityScore);

      

    return (
        <Box display="flex" alignItems="center" mb={2} ml={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>

                <FiberManualRecordIcon sx={{ color: isPublished ? 'green' : 'red', mr: 1, fontSize: '2rem' }} />
                <Typography variant="h6" sx={{ mr: 2 }}>
                    {isPublished ? 'Published' : 'Unpublished'}
                </Typography>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}> */}
                <Box sx={{ width: '100px' }}> {/* Ensures the switch area has a fixed width */}

                    {isLoading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <Switch
                            checked={isPublished}
                            onChange={handleTogglePublished}
                            inputProps={{ 'aria-label': 'Publish course toggle' }}
                        />
                    )}
                </Box>
                {/* Button to delete course */}
                <Tooltip title="Delete Course" placement="top">
                <IconButton sx={{ mr: 5}} onClick={() => setDeleteConfirmationOpen(true)}>
                    <DeleteIcon  />
                </IconButton>
                </Tooltip>
                <Button
                    variant="outlined"
                    color="primary"
                    href={`${mydraBaseUrl}/courses/${course._id}`}
                    target="_blank"
                    startIcon={<OpenInNewIcon />}
                    sx={{ height: '40px' }}

                >
                    View on Mydra
                </Button>

                {/* Button to delete course */}
                
                <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
                    <DialogTitle>Confirm Course Deletion</DialogTitle>
                    <DialogContent>
                    <Typography>Are you sure you want to delete this course?</Typography>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteCourse} color="error" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button>
                    </DialogActions>
                </Dialog>
                {/* Snackbar for showing deletion success message */}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Course deleted successfully!
                    </Alert>
                </Snackbar>

                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ml: 5 }}>
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel>Language</InputLabel>
                        <Select value={selectedLanguage} onChange={handleLanguageChange} label="Language">
                            {course?.courseDetailTranslations?.map((detail) => (
                                <MenuItem key={detail.language} value={detail.language}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FlagIcon code={languages.find(lang => lang.value === detail.language)?.flag} size={16} style={{ marginRight: 8 }} />
                                        {languages.find(lang => lang.value === detail.language)?.label || detail.language}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip
                        title={
                            <QualityScoreBreakdown 
                                breakdown={course.qualityScoreBreakdown} 
                                qualityScoreCalculatedAt={course.qualityScoreCalculatedAt}
                            />
                        }
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: 'background.paper',
                                    '& .MuiTooltip-arrow': {
                                        color: 'background.paper',
                                    },
                                    boxShadow: (theme) => theme.shadows[1],
                                    maxWidth: 'none',
                                    width: '300px',
                                    alignContent: 'center',
                                },
                            },
                        }}
                    >
                        <Paper elevation={3} sx={{ 
                            p: 1, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            cursor: 'help',
                            minWidth: '120px',
                            ml: 4,
                        }}>
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                Quality Score
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ 
                                    color: getQualityScoreColor(course.qualityScore),
                                    fontWeight: 'bold',
                                }}
                            >
                                {`${qualityScorePercentage}%`}
                            </Typography>
                        </Paper>
                    </Tooltip>

                </Box>

            </Box>
        </Box>
    );
};

export default MydraCoursePublishedStatus;
