import React, { useState } from 'react';
import './InputModule.css'; // Assuming you have an accompanying CSS file for styling
import { 
    Container,
    CircularProgress,
    FormControlLabel,
    Switch,
    Typography,
    Box,
    InputLabel,
    TextField,
    Button,
    Snackbar,
    Alert
    } from '@mui/material';
    

const InputModule = ({ onSubmit }) => {
    const [isLoading, setIsLoading] = useState(false); // Add a loading state
    const [showError, setShowError] = useState(false); // State to manage visibility of the error alert
 
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        jobRole: '',
        company: '', // Added company field
        objectives: [''],
        responsibilities: [''] // Added responsibilities field

    });

    const isFormValid = () => {
        // Check if basic fields are filled
        const areBasicFieldsFilled = formData.name && formData.email && formData.jobRole && formData.company;

        // Check if there's at least one non-empty objective and one non-empty responsibility
        const isAtLeastOneObjectiveFilled = formData.objectives.some(objective => objective.trim() !== '');
        const isAtLeastOneResponsibilityFilled = formData.responsibilities.some(responsibility => responsibility.trim() !== '');

        return areBasicFieldsFilled && isAtLeastOneObjectiveFilled && isAtLeastOneResponsibilityFilled;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle change for objectives and responsibilities similarly
    const handleListChange = (listName, index, value) => {
        const updatedList = [...formData[listName]];
        updatedList[index] = value;
        setFormData({
            ...formData,
            [listName]: updatedList
        });
    };

    const addToList = (listName) => {
        setFormData({
            ...formData,
            [listName]: [...formData[listName], '']
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            setShowError(true); // Show error if the form is not valid
            return;
        }

        setIsLoading(true);
        await onSubmit(formData);
        setIsLoading(false);
    };


    return (
        <div className="input-module">
            <form onSubmit={handleSubmit} className="input-form">
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    margin="normal"
                />

                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    margin="normal"
                />  

                <TextField
                    id="jobRole"
                    name="jobRole"
                    label="Job Role"
                    type="text"
                    value={formData.jobRole}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    margin="normal"
                />

                <TextField
                    id="company"
                    name="company"
                    label="Company"
                    type="text"
                    value={formData.company}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    margin="normal"
                />
        
                {/* Responsibilities Section */}
                <Typography variant="h6" margin="normal">Key Responsibilities</Typography>
                {formData.responsibilities.map((responsibility, index) => (
                    <TextField
                        key={index}
                        value={responsibility}
                        onChange={(e) => handleListChange('responsibilities', index, e.target.value)}
                        placeholder="E.g., Managing a team, Project delivery"
                        fullWidth
                        margin="normal"
                        required={index === 0}
                    />
                ))}
                <Button onClick={() => addToList('responsibilities')} variant="contained" sx={{ mb: 2 }}>+ Add Responsibility</Button>

                {/* Objectives Section */}
                <Typography variant="h6" margin="normal">Objectives</Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    E.g., Getting a promotion, Changing jobs, Achieving a project milestone, Automating a task
                </Typography>
                {formData.objectives.map((objective, index) => (
                    <TextField
                        key={index}
                        value={objective}
                        onChange={(e) => handleListChange('objectives', index, e.target.value)}
                        placeholder="Enter your objective"
                        fullWidth
                        margin="normal"
                        required={index === 0}
                    />
                ))}
                <Button onClick={() => addToList('objectives')} variant="contained" sx={{ mb: 2 }}>+ Add Objective</Button>
                    

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <Button type="submit" variant="contained" disabled={!isFormValid()}>Submit</Button>
                    )}
                </Box>
            </form>
            <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
                <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
                    Please fill in all required fields, including at least one responsibility and one objective.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default InputModule;

