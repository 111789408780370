import React from 'react';
import { Typography, List, ListItem, ListItemText, Paper, Box, Divider, Container } from '@mui/material';

// Since you're using MUI, you might not need a separate CSS file for basic styling. But you can still use it for specific animations or custom styles.
import './OutputModule.css';

const OutputModule = ({ upskillingPath }) => {
    return (
        <Container maxWidth="md"> {/* Use Container for responsive width */}
            <Paper elevation={3} sx={{ p: 3, mt: 5, animation: 'slideFadeInAnimation 0.5s ease-in-out' }} className="slideFadeInAnimation">
                <Typography variant="h4" gutterBottom textAlign="center">
                    {upskillingPath.user_name}, here is your Personalized AI Upskilling Path
                </Typography>
                <List>
                    {upskillingPath.steps.map((step, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <Divider variant="fullWidth" component="li" />}
                            <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2, pb: 2 }}>
                                <Typography variant="h5" component="div" color="primary" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    {`${step.level_name}: ${step.title}`}
                                </Typography>
                                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>
                                    {step.description}
                                </Typography>
                                <Box component="div" sx={{ mt: 1 }}>
                                    <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>
                                        Examples:
                                    </Typography>
                                    {step.examples.map((example, exampleIndex) => (
                                        <Typography key={exampleIndex} variant="body2" color="text.primary" sx={{ ml: 2, mb: 1 }}>
                                            {`- ${example}`}
                                        </Typography>
                                    ))}
                                </Box>
                            </ListItem>
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        </Container>
    );
};

export default OutputModule;

