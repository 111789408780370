import React, { useState, useEffect } from 'react';
import { 
    Card, 
    CardContent, 
    Typography, 
    CardHeader, 
    Box, 
    Chip, 
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormLabel,
    CircularProgress,  
    Button,  
} from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const CourseCategorySection = ({ course, onEditSection, apiBaseUrl, authToken, userEmail, onSaveSection, isSaving, isProduction }) => {
    
    console.log("Inside CourseCategorySection")
    console.log("course: ", course) 
    const [editMode, setEditMode] = useState(false);
    const [categories, setCategories] = useState([]);
    // const [selectedCategory, setSelectedCategory] = useState(course.category?._id);
    const [selectedCategory, setSelectedCategory] = useState(course.categories[0]?._id);
    const [selectedCategories, setSelectedCategories] = useState(course.categories?.map(cat => cat._id) || []);

    // const [selectedSubCategories, setSelectedSubCategories] = useState(course?.subCategories?.map(sub => sub.slug));
    const [selectedSubCategories, setSelectedSubCategories] = useState(course?.subCategories?.map(sub => sub));

    const [categoryName, setCategoryName] = useState(course.categories[0]?.name);
    const [loading, setLoading] = useState(false);
    const fullSubCategories = course?.category?.subCategories;
    const [saving, setSaving] = useState(false);

    const [originalCategory, setOriginalCategory] = useState(course?.category);
    const [originalSubCategories, setOriginalSubCategories] = useState(course?.subCategories);

    // Map the subCategories from the course to their full details
    const courseSubCategoryDetails = course?.subCategories?.map(subCatSlug =>
        fullSubCategories?.find(subCat => subCat.slug === subCatSlug)
    );

    useEffect(() => {
        setOriginalCategory(course.categories[0]);
        setOriginalSubCategories(course.subCategories);
        setCategoryName(course.categories[0]?.name);
        setSelectedCategory(course.categories[0]?._id);
        setSelectedCategories(course.categories?.map(cat => cat._id) || []);
        // setSelectedSubCategories(course?.subCategories || []);
        setSelectedSubCategories(course?.subCategories?.map(sub => sub) || []);

        // console.log("course.category: ", course.category);
        // console.log("course.subCategories: ", course.subCategories);    
    }, [course]);

    const handleCancel = () => {
        setCategoryName(originalCategory?.name);
        setSelectedCategory(originalCategory?._id);
        setSelectedSubCategories(originalSubCategories);
        setEditMode(false);
    };

    const displayedSubCategories = course?.subCategories?.map(subCatSlug =>
        // course.category?.subCategories?.find(subCat => subCat.slug === subCatSlug)
        course.categories[0]?.subCategories?.find(subCat => subCat.slug === subCatSlug)

    );

    useEffect(() => {
        console.log("Fetching categories")
        // console.log("apiBaseUrl: ", apiBaseUrl)
        // console.log("authToken: ", authToken.substring(0, 10))
        // console.log("userEmail: ", userEmail)
        // console.log("isProduction: ", isProduction)

        const fetchCategories = async () => {        
            setLoading(true);
            try {
                const response = await fetch(`${apiBaseUrl}/mydra/categories`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        'X-User-Email': userEmail, // Use the email parameter
                        'X-Environment': isProduction ? 'Production' : 'Staging',
                    },
                });
                if (!response.ok) {
                    console.error('Failed to fetch categories');
                    return;
                }
                const data = await response.json();
                // console.log("Categories data: ", data)
                setCategories(data['data']);
            } catch (error) {
                console.error('Failed to fetch categories', error);
            } finally {
                setLoading(false);
            };
        };

        if (editMode) {
            fetchCategories();
        }
    }, [editMode, apiBaseUrl, authToken, userEmail, isProduction, course]);


    useEffect(() => {
        // When entering edit mode and categories are loaded
        if (editMode && categories.length > 0) {
            // Assuming `course.subCategories` is an array of subcategory slugs
            // const currentSubCategories = course.subCategories.map(sub => sub.slug);
            const currentSubCategories = course?.subCategories;
            // console.log("currentSubCategories: ", currentSubCategories)
            setSelectedSubCategories(currentSubCategories);
        }
    }, [editMode, categories, course?.subCategories]);

    const handleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleSave = async () => {
        // Construct the payload based on your backend requirements
        // const payload = {
        //     categoryIds: selectedCategories,
        //     subCategories: selectedSubCategories, // Assuming this is an array of slugs
        // };

        const nonTranslationFields = {
            categoryIds: selectedCategories,
            subCategories: selectedSubCategories,
        };
    
        const updatePayload = {
            nonTranslationFields,
        };

        console.log('Saving course with data:', updatePayload);

        try {
            setSaving(true); // Optionally manage saving state to disable buttons, show loaders, etc.
            await onSaveSection('categorySelection', updatePayload);
        } catch (error) {
            // Handle any errors here, such as showing error messages
            console.error("Failed to save category selection:", error);
        } finally {  
            setEditMode(false); // Exit edit mode
            setSaving(false); // Optionally manage saving state to disable buttons, show loaders, etc.
        }
    };

    const handleCategoryChange = (event) => {
        const newSelectedCategoryId = event.target.value;
        setSelectedCategory(newSelectedCategoryId);
        setSelectedCategories([newSelectedCategoryId]);

        setSelectedSubCategories([]);
    };

    const handleSubCategoryChange = (slug, isChecked) => {
        if (isChecked) {
            // If checked, add the subcategory slug to the array
            setSelectedSubCategories(prevSubCategories => [...prevSubCategories, slug]);
        } else {
            // If unchecked, remove the subcategory slug from the array
            setSelectedSubCategories(prevSubCategories => prevSubCategories.filter(sub => sub !== slug));
        }
    };

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    editMode ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: 'common.white',
                                borderColor: 'common.white',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    borderColor: 'common.white',
                                },
                            }}
                            onClick={handleCancel}
                            disabled={isSaving}
                        >
                            Cancel
                        </Button>
                    </Box>
                    ) : (
                        <IconButton onClick={handleEditMode} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
                title="Categories"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{ backgroundColor: 'primary.light', color: 'common.white' }}
            />
            
            <CardContent>

                {loading || isSaving ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={100}>
                        <CircularProgress />
                    </Box>
                ) : (
            
                    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                        {editMode ? (
                            <>
                                <FormControl fullWidth sx={{ mt: 1, width: '100%' }}>
                                    <InputLabel id="category-select-label">Primary Category</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        value={selectedCategory}
                                        label="Primary Category"
                                        onChange={handleCategoryChange}
                                    >
                                        {categories.map((category) => (
                                            <MenuItem key={category?._id} value={category?._id}>
                                                {category?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl component="fieldset" sx={{ mt: 2, width: '100%' }}>
                                    <FormLabel component="legend">Sub-Categories</FormLabel>
                                    <FormGroup>
                                        {categories?.find(category => category?._id === selectedCategory)?.subCategories?.map((subCategory) => (
                                            <FormControlLabel
                                                key={subCategory?._id}
                                                control={
                                                    <Checkbox
                                                        checked={selectedSubCategories?.includes(subCategory.slug)}
                                                        onChange={(event) => handleSubCategoryChange(subCategory.slug, event.target.checked)}
                                                    />
                                                }
                                                label={subCategory ? subCategory?.name : 'Loading...'}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            
                            </>
                        ) : (
                            <>
                                    <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CategoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        Primary Category: <Chip label={categoryName} variant="outlined" sx={{ ml: 1 }} />

                                        
                                    </Typography>
                                    {displayedSubCategories?.map(subCategory => (
                                        <Typography key={subCategory?._id} variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <SubdirectoryArrowRightIcon sx={{ mr: 1, color: 'secondary.main' }} />
                                            Sub-Category: <Chip label={subCategory?.name} variant="outlined" color="secondary" sx={{ ml: 1 }} />
                                        </Typography>
                                    ))}
                                
                                
                                
                            </>
                        )}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};



export default CourseCategorySection;

