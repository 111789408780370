import React, { useState, useEffect, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Switch,
    CircularProgress,
    Typography,
    Box,
    Chip,
    Tooltip,
    Snackbar,
    Alert,
} from '@mui/material';
import { format } from 'date-fns';

const CompanyUsers = ({ companyId, authToken, userEmail, isProduction }) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const [updatingUserId, setUpdatingUserId] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchUsers = useCallback(async () => {
        console.log("Fetching users for company:", companyId);
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/companies/${companyId}/users`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }
            const data = await response.json();
            setUsers(data);
            console.log("Users:", data);
        } catch (err) {
            console.error("Error fetching users:", err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    }, [API_BASE_URL, authToken, companyId, isProduction, userEmail]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleAdminToggle = async (userId, isAdmin) => {
        console.log(`Toggling admin status for user ${userId} to ${isAdmin}`);
        setUpdatingUserId(userId);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/companies/${companyId}/users/${userId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ isAdmin }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update user');
            }
            const updatedUser = await response.json();
            console.log("Updated user:", updatedUser);
            
            if (updatedUser.isAdmin !== isAdmin) {
                console.warn('The returned user data does not reflect the requested change');
                setSnackbar({
                    open: true,
                    message: `User update may not have been applied correctly. Please refresh and try again.`,
                    severity: 'warning',
                });
            } else {
                setUsers(users.map(user => user._id === updatedUser._id ? updatedUser : user));
                setSnackbar({
                    open: true,
                    message: `User ${updatedUser.firstName} ${updatedUser.lastName} ${isAdmin ? 'promoted to' : 'demoted from'} admin successfully`,
                    severity: 'success',
                });
            }
        } catch (err) {
            console.error('Error updating user:', err);
            setSnackbar({
                open: true,
                message: `Failed to update user: ${err.message}`,
                severity: 'error',
            });
            // Revert the toggle in the UI
            setUsers(users.map(user => user._id === userId ? {...user, isAdmin: !isAdmin} : user));
        } finally {
            setUpdatingUserId(null);
        }
    };

    
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="150px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Job Role</TableCell>
                            <TableCell>Last Login</TableCell>
                            <TableCell>Roles</TableCell>
                            <TableCell>Admin</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.jobRole || 'N/A'}</TableCell>
                                <TableCell>
                                    <Tooltip title={`Login Type: ${user.lastLoginType}`} placement="top">
                                        <span>{user.lastLogin ? format(new Date(user.lastLogin), 'yyyy-MM-dd HH:mm') : 'Never'}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    {user.roles.map((role, index) => (
                                        <Chip key={index} label={role} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={user.isAdmin}
                                        onChange={() => handleAdminToggle(user._id, !user.isAdmin)}
                                        disabled={updatingUserId === user._id}
                                        color="primary"
                                    />
                                    {updatingUserId === user._id && <CircularProgress size={24} />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default CompanyUsers;
