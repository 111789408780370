import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, CardHeader, IconButton, CardActions, Button, Link, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'; // Icon for Course Video
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Icon for Course Brochure
import CancelIcon from '@mui/icons-material/Cancel'; // Import Cancel icon
import ImageIcon from '@mui/icons-material/Image'; // Icon for Course Image


const CourseAdditionalResourcesSection = ({ course, onSaveSection, isSaving }) => {
    const [viewBrochureUrl, setViewBrochureUrl] = useState(course.brochureUrl || '');
    const [viewImageUrl, setViewImageUrl] = useState(course.imageUrl || '');

    const [editBrochureUrl, setEditBrochureUrl] = useState(course.brochureUrl || '');
    const [editImageUrl, setEditImageUrl] = useState(course.imageUrl || '');

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setViewBrochureUrl(course.brochureUrl || '');
        setViewImageUrl(course.imageUrl || '');

        setEditBrochureUrl(course.brochureUrl || '');
        setEditImageUrl(course.imageUrl || '');
    }, [course.videoUrl, course.brochureUrl, course.imageUrl]);

    const handleEdit = () => {
        // Enable edit mode and use course's current URLs for editing
        setEditBrochureUrl(viewBrochureUrl);
        setEditImageUrl(viewImageUrl);

        setIsEditing(true);
    };


    const handleCancel = () => {
        // Exit edit mode without saving changes
        setEditBrochureUrl(viewBrochureUrl);
        setEditImageUrl(viewImageUrl);

        setIsEditing(false);
    };

    const handleSave = () => {
        const nonTranslationFields = {
            brochureUrl: editBrochureUrl,
            imageUrl: editImageUrl
        };
        const updatePayload = {
            nonTranslationFields,
        };
        // Save the changes and exit edit mode
        onSaveSection('additionalResources', updatePayload);

        // Update the view states to reflect the changes
        setViewBrochureUrl(editBrochureUrl);
        setViewImageUrl(editImageUrl);
        setIsEditing(false);
    };

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    isEditing ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                onClick={handleSave}
                                startIcon={<SaveIcon />}
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={handleCancel}
                                startIcon={<CancelIcon />}
                                variant="outlined"
                                color="inherit" // Adjust color to match your design
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>

                        </Box>

                    ) : (
                        <IconButton onClick={handleEdit} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
      
                title="Additional Resources"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                    '.MuiCardHeader-title': { color: 'primary.contrastText' }
                }}
            />
            <CardContent>
                {isEditing ? (
                    <>
                        
                        <TextField
                            label="Course Brochure URL"
                            value={editBrochureUrl}
                            onChange={(e) => setEditBrochureUrl(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Course Image URL"
                            value={editImageUrl}
                            onChange={(e) => setEditImageUrl(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </>
                ) : (
                    <>                       
                        <Typography paragraph sx={{ display: 'flex', alignItems: 'center' }}>
                            <PictureAsPdfIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Course Brochure: <Link href={viewBrochureUrl || '#'} target="_blank" color="secondary" sx={{ ml: 1 }}>{viewBrochureUrl || 'Not provided'}</Link>
                        </Typography>
                        <Typography paragraph sx={{ display: 'flex', alignItems: 'center' }}>
                            <ImageIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Course Image: <Link href={viewImageUrl || '#'} target="_blank" color="secondary" sx={{ ml: 1 }}>{viewImageUrl || 'Not provided'}</Link>
                        </Typography>
                    </>
                )}
            </CardContent>

        </Card>
    );
};

export default CourseAdditionalResourcesSection;

