

// src/components/EducationProviderForm/index.js


import EducationProviderForm from './EducationProviderForm';

export default EducationProviderForm;




