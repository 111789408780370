


import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation

import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import app from '../firebaseConfig';
import GoogleIcon from '@mui/icons-material/Google';
import { Button, Container, Typography, Box, Paper, Alert } from '@mui/material';


const LoginPage = () => {
    let navigate = useNavigate(); // Hook for navigation
    let location = useLocation(); // Use location to access the state

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    const from = location.state?.from || '/';

    const handleSignIn = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log('Sign-in successful', result);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.email;
                console.log('Sign-in error', errorCode, errorMessage, email);
            });
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // navigate('/'); // Navigate to home or dashboard page upon successful login
            // navigate to the page where the user was trying to access before login
            navigate(from, { replace: true });
        }
        });

        return () => unsubscribe();
    }, [navigate, auth]);


    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={6} sx={{ marginTop: 8, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" sx={{ lineHeight: 'normal', textAlign: 'center' }}>
                    Please Login to Access This Application
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                    Sign in with your <Box component="span" sx={{ fontWeight: 'bold' }}>@studentfinance.com</Box> email address.
                </Typography>
                <Button
                    variant="outlined"
                    startIcon={<GoogleIcon sx={{ color: '#4285F4' }} />}
                    onClick={handleSignIn}
                    sx={{
                        mt: 3,
                        mb: 2,
                        borderColor: '#4285F4',
                        color: '#4285F4',
                        ':hover': {
                            borderColor: '#2a62c0',
                            background: '#e8f0fe',
                        },
                    }}
                >
                    Sign in with Google
                </Button>
                <Alert severity="info" sx={{ mt: 2 }}>
                    Access is restricted to StudentFinance employees.
                </Alert>
            </Paper>
        </Container>
    );
};

export default LoginPage;


