
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Button,
  CardActions,
  CardHeader,
  Box,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

const CourseTestimonialsSection = ({ course, selectedLanguage, onSaveSection, isSaving, authToken, userEmail, isProduction, onCourseUpdate }) => {
    const [editMode, setEditMode] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [originalTestimonials, setOriginalTestimonials] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const courseDetail = getCourseDetailByLanguage(course.courseDetailTranslations, selectedLanguage);
        setTestimonials(courseDetail?.testimonials || []);
        setOriginalTestimonials(JSON.parse(JSON.stringify(courseDetail?.testimonials || [])));
    }, [course.courseDetailTranslations, selectedLanguage]);

    const getCourseDetailByLanguage = (courseDetailTranslations, selectedLanguage) => {
        if (!courseDetailTranslations || !Array.isArray(courseDetailTranslations)) {
            return null;
        }
        const courseDetail = courseDetailTranslations.find(detail => detail.language === selectedLanguage);
        return courseDetail ? { ...courseDetail, testimonials: courseDetail.testimonials || [] } : {};
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleCancel = () => {
        console.log("Current testimonials:", testimonials);
        console.log("Original testimonials:", originalTestimonials);
        setTestimonials(JSON.parse(JSON.stringify(originalTestimonials)));
        setEditMode(false);
    };

    const handleAddTestimonial = () => {
        setTestimonials([...testimonials, { comment: '', author: '', authorRole: '', avatarUrl: '' }]);
    };

    const handleRemoveTestimonial = (index) => {
        const updatedTestimonials = [...testimonials];
        updatedTestimonials.splice(index, 1);
        setTestimonials(updatedTestimonials);
    };

    const handleChange = (index, field, value) => {
        const updatedTestimonials = [...testimonials];
        updatedTestimonials[index][field] = value;
        setTestimonials(updatedTestimonials);
    };

    const handleSave = () => {
        const translationFields = {
            testimonials: testimonials,
        };

        const updatePayload = {
            language: selectedLanguage,
            translationFields,
        };

        console.log('Saving course with data:', updatePayload);
        onSaveSection('testimonials', updatePayload);
    };

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    editMode ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                Save
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: 'common.white',
                                    borderColor: 'common.white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'common.white',
                                    },
                                }}
                                startIcon={<CancelIcon />}
                                onClick={handleCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <IconButton onClick={handleEdit} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
                title="Testimonials"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                    '.MuiCardHeader-action': { m: 'auto 0' },
                    '.MuiCardHeader-title': { color: 'primary.contrastText' }
                }}
            />

            <CardContent>
                {editMode ? (
                    <div>
                        {testimonials.map((testimonial, index) => (
                            <Box key={index} sx={{ marginBottom: 2 }}>
                                <TextField
                                    label="Comment"
                                    value={testimonial.comment}
                                    onChange={(e) => handleChange(index, 'comment', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Author"
                                    value={testimonial.author}
                                    onChange={(e) => handleChange(index, 'author', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Author Role"
                                    value={testimonial.authorRole}
                                    onChange={(e) => handleChange(index, 'authorRole', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <Tooltip title="Remove Testimonial">
                                    <IconButton onClick={() => handleRemoveTestimonial(index)} sx={{ marginTop: 1 }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        ))}
                        <Button
                            variant="outlined"
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={handleAddTestimonial}
                            sx={{ mt: 2 }}
                        >
                            Add Testimonial
                        </Button>
                    </div>
                ) : (
                    testimonials.map((testimonial, index) => (
                        <Typography key={index} paragraph>
                            "{testimonial.comment}" - {testimonial.author || 'Anonymous'} - {testimonial.authorRole || 'Role Unknown'}
                        </Typography>
                    ))
                )}
            </CardContent>
        </Card>
    );
};

export default CourseTestimonialsSection;



