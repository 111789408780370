
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Container, Switch, Typography, CircularProgress, Box, Paper, Button, TextField } from '@mui/material';
import MydraCourse from '../../components_mydra/MydraCourse/MydraCourse';
import MydraCoursePublishedStatus from '../../components_mydra/MydraCoursePublishedStatus/MydraCoursePublishedStatus';

const MydraCoursePage = () => {
    const { courseId } = useParams();
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [inputCourseId, setInputCourseId] = useState(courseId);
    const location = useLocation();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [courseDetails, setCourseDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const env = queryParams.get('env');
        setIsProduction(env !== 'Staging');
    }, [location]);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            }
        });
    }, []);

    useEffect(() => {
        if (courseId && authToken && userEmail) {
            fetchCourseDetails(userEmail);
        } else {
            setLoading(false);
        }
    }, [authToken, userEmail, courseId, isProduction]);


    const handleCourseUpdate = (updatedCourse, isInstructorUpdate) => {
        console.log('Updated course:', updatedCourse);

        fetchCourseDetails(userEmail);

        if (isInstructorUpdate) {
            fetchCourseDetails(userEmail);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const fetchCourseDetails = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (response.status === 404) {
                throw new Error('Course not available in the selected environment. Please try a different course or switch to Production.');
            } else if (!response.ok) {
                throw new Error('Failed to fetch course details due to an unexpected error.');
            }

            const data = await response.json();
            handleFetchCourseDetails(data);

        } catch (err) {
            setError(err.message);
            setCourseDetails(null);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleFetchCourseDetails = (data) => {
        if (data && data['data']) {
            const courseData = data['data'];
            const categoryId = courseData.category?._id;
            setCourseDetails({
                ...courseData,
                categoryId,
            });
        } else {
            setCourseDetails(null);
            setError('No course details available');
        }
    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" sx={{ my: 4, fontWeight: 'bold', color: 'primary.main' }}>
                Course Details
            </Typography>
    
            <Box display="flex" alignItems="center" mb={2} ml={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>
            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact all live courses.
                </Typography>
            )}
    
            <Box display="flex" alignItems="center" mb={2} ml={2}>
                <TextField
                    label="Course ID"
                    variant="outlined"
                    value={inputCourseId}
                    onChange={(e) => setInputCourseId(e.target.value)}
                    sx={{ mr: 2, width: '350px' }}
                />
                <Button variant="contained" color="primary" onClick={() => navigate(`/mydra/course/${inputCourseId.trim()}`)}>
                    View Course
                </Button>
            </Box>

            {courseDetails && (
                <MydraCoursePublishedStatus
                    course={courseDetails}
                    authToken={authToken}
                    userEmail={userEmail}
                    isProduction={isProduction}
                    fetchCourseDetails={fetchCourseDetails}
                    selectedLanguage={selectedLanguage}
                    handleLanguageChange={handleLanguageChange}
                />
            )}

            <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                {loading && (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
                {error && <Typography variant="h6" color="error">{error}</Typography>}
                {!loading && !error && courseDetails && (
                    <MydraCourse
                        course={courseDetails}
                        authToken={authToken}
                        userEmail={userEmail}
                        isProduction={isProduction}
                        onCourseUpdate={handleCourseUpdate}
                        selectedLanguage={selectedLanguage}
                    />
                )}
                {!loading && !error && !courseDetails && <Typography variant="h6">No course details available</Typography>}
            </Paper>
        </Container>
    );
};

export default MydraCoursePage;
