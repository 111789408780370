import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar, Box, LinearProgress } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';

// Helper function to determine the color based on the score
const getScoreColor = (score, highestScore) => {
  const percentage = (score / highestScore) * 100;
  if (percentage < 40) return red[500];
  if (percentage < 70) return yellow[700];
  return green[500];
};

const ScoreList = ({ scores, handleProviderDetails, highestScore }) => {
  return (
    <Box sx={{ mt: 4 }}>
      {scores.map((ep, index) => (
        <Card key={ep.site_id} sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1}>
                <Typography variant="h5">{index + 1}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Avatar src={ep.logo_url} alt={ep.name} sx={{ width: 48, height: 48 }} />
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h6" component="a" onClick={() => handleProviderDetails(ep.site_id)} sx={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                  {ep.name}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <LinearProgress
                    variant="determinate"
                    value={(ep.score / highestScore) * 100}
                    sx={{
                      width: '100%',
                      mr: 1,
                      [`& .MuiLinearProgress-bar`]: {
                        backgroundColor: getScoreColor(ep.score, highestScore),
                      },
                    }}
                  />
                  <Typography variant="h5" component="span" sx={{ fontWeight: 'bold', ml: 2, color: getScoreColor(ep.score, highestScore) }}>
                    {ep.score.toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ScoreList;

