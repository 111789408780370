
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CardActions, Button, TextField, Box, CardHeader, IconButton } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import StructureIcon from '@mui/icons-material/AccountTree'; // Assuming you have an icon for 'Programme Structure'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SummarizeIcon from '@mui/icons-material/Summarize';

const CourseContentDescriptionSection = ({ course, selectedLanguage, isSectionEditable, onEditSection, onSaveSection, isSaving }) => {


    // State to manage editable fields
    // const [editableCourseName, setEditableCourseName] = useState(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.name);

    const [editableLearningDescription, setEditableLearningDescription] = useState(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.learningDescription || '' );
    const [editableProgrammeStructure, setEditableProgrammeStructure] = useState([]);
    const [editableSummary, setEditableSummary] = useState('');

    // State to manage original values
    const [originalLearningDescription, setOriginalLearningDescription] = useState(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.learningDescription || '');
    const [originalProgrammeStructure, setOriginalProgrammeStructure] = useState([]);
    const [originalSummary, setOriginalSummary] = useState('');

    const editable = isSectionEditable('contentDescription');

    console.log("I'm in CourseContentDescriptionSection.js");
    console.log('course:', course);

    useEffect(() => {

        setOriginalLearningDescription(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.learningDescription || '');
        setOriginalProgrammeStructure(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.programmeStructure || []);
        setOriginalSummary(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.summary || '');

        setEditableLearningDescription(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.learningDescription || '');
        setEditableProgrammeStructure(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.programmeStructure || []);
        setEditableSummary(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.summary || '');
    }, [course, selectedLanguage]);

    const getCourseDetailByLanguage = (courseDetailTranslations, selectedLanguage) => {
        // console.log('courseDetailTranslations:', courseDetailTranslations);
        // console.log('selectedLanguage:', selectedLanguage);
        if (!courseDetailTranslations || !Array.isArray(courseDetailTranslations)) {
            return null;
        }
        return courseDetailTranslations.find(detail => detail.language === selectedLanguage) || {};
    };

    const currentCourseDetail = getCourseDetailByLanguage(course.courseDetailTranslations, selectedLanguage);

    // console.log('currentCourseDetail:', currentCourseDetail);



    

    const handleCancel = () => {
        setEditableLearningDescription(originalLearningDescription);
        setEditableProgrammeStructure(originalProgrammeStructure);
        setEditableSummary(originalSummary);
        onEditSection(null);
    };

    const handleProgrammeStructureChange = (index, field, value) => {
        const updatedProgrammeStructure = [...editableProgrammeStructure];
        updatedProgrammeStructure[index][field] = value;
        setEditableProgrammeStructure(updatedProgrammeStructure);
    };

    const handleAddModule = () => {
        const newModule = { title: '', description: '' };
        setEditableProgrammeStructure([...editableProgrammeStructure, newModule]);
    };

    const handleRemoveModule = (index) => {
        const updatedModules = editableProgrammeStructure.filter((_, i) => i !== index);
        setEditableProgrammeStructure(updatedModules);
    };

    const handleSave = () => {
        const translationFields = {
            learningDescription: editableLearningDescription,
            programmeStructure: editableProgrammeStructure,
            summary: editableSummary,
        };

        const updatePayload = {
            language: selectedLanguage,
            translationFields,
            nonTranslationFields: {},
        };

        console.log('Saving course with data:', updatePayload);
        onSaveSection('contentDescription', updatePayload);
    };

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    editable ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: 'common.white',
                                    borderColor: 'common.white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'common.white',
                                    },
                                }}
                                onClick={handleCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <IconButton onClick={() => onEditSection('contentDescription')} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                title="Content Description"
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    '.MuiCardHeader-action': { m: 'auto 0' },
                    '.MuiCardHeader-title': { color: 'common.white' }
                }}
            />
            <CardContent>
                {/* Summary section */}
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                        <SummarizeIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Summary
                    </Typography>
                    {editable ? (
                        <TextField
                            label="Summary"
                            multiline
                            rows={4}
                            value={editableSummary}
                            onChange={(e) => setEditableSummary(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    ) : (
                        <Typography paragraph>
                            {/* {originalSummary || 'No summary provided.'} */}
                            {currentCourseDetail?.summary || 'No summary provided.'}
                        </Typography>
                    )}
                </Box>

                {/* Learning Description section */}
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                        <DescriptionIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Learning Description
                    </Typography>
                    {editable ? (
                        <TextField
                            label="Learning Description"
                            multiline
                            rows={4}
                            value={editableLearningDescription}
                            onChange={(e) => setEditableLearningDescription(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    ) : (
                        <Typography paragraph>
                            {originalLearningDescription || 'No learning description provided.'}
                        </Typography>
                    )}
                </Box>

                {/* Programme Structure section */}
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <StructureIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Programme Structure
                </Typography>
                {editable ? (
                    <>
                        {editableProgrammeStructure.map((module, index) => (
                            <Card key={index} variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <TextField
                                        label="Title"
                                        value={module.title}
                                        onChange={(e) => handleProgrammeStructureChange(index, 'title', e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Description"
                                        value={module.description || ''}
                                        onChange={(e) => handleProgrammeStructureChange(index, 'description', e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        multiline
                                        rows={2}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button 
                                        variant="outlined" 
                                        color="error" 
                                        startIcon={<DeleteIcon />} 
                                        onClick={() => handleRemoveModule(index)}
                                    >
                                        Remove
                                    </Button>
                                </CardActions>
                            </Card>
                        ))}
                        <Button 
                            variant="contained" 
                            color="primary" 
                            startIcon={<AddCircleOutlineIcon />} 
                            onClick={handleAddModule}
                            sx={{ mt: 2 }}
                        >
                            Add Module
                        </Button>
                    </>
                ) : (
                    <>
                        {originalProgrammeStructure.map((module, index) => (
                            <Card key={index} variant="outlined" sx={{ mb: 1, p: 2 }}>
                                <CardContent sx={{ padding: '8px', '&:last-child': { paddingBottom: '8px' } }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <Typography variant="h6" component="span">
                                            {module.title}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">
                                        {module.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default CourseContentDescriptionSection;



