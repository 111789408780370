import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, TextField, Button, IconButton, Link, CardHeader, Avatar, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const CourseInstructorsSection = ({ course, selectedLanguage, onSaveSection, isSaving, authToken, userEmail, isProduction, onCourseUpdate }) => {
    
    const [editMode, setEditMode] = useState(false);
    const [instructors, setInstructors] = useState([]);
    const [originalInstructors, setOriginalInstructors] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const courseDetail = getCourseDetailByLanguage(course.courseDetailTranslations, selectedLanguage);
        setInstructors(courseDetail?.instructors || []);
        setOriginalInstructors(JSON.parse(JSON.stringify(courseDetail?.instructors || [])));
    }, [course.courseDetailTranslations, selectedLanguage]);

    const getCourseDetailByLanguage = (courseDetailTranslations, selectedLanguage) => {
        if (!courseDetailTranslations || !Array.isArray(courseDetailTranslations)) {
            return null;
        }
        const courseDetail = courseDetailTranslations.find(detail => detail.language === selectedLanguage);
        return courseDetail ? { ...courseDetail, instructors: courseDetail.instructors || [] } : {};
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleCancel = () => {
        console.log("Current instructors:", instructors);
        console.log("Original instructors:", originalInstructors);
        setInstructors(JSON.parse(JSON.stringify(originalInstructors)));
        setEditMode(false);
    };

    const handleAddInstructor = () => {
        setInstructors([...instructors, { name: '', linkedIn: '', bio: '', role: '', company: '', website: '', avatarUrl: '' }]);
    };

    const handleRemoveInstructor = (index) => {
        const updatedInstructors = [...instructors];
        updatedInstructors.splice(index, 1);
        setInstructors(updatedInstructors);
    };

    const handleChange = (index, field, value) => {
        const updatedInstructors = [...instructors];
        updatedInstructors[index][field] = value;
        setInstructors(updatedInstructors);
    };

  
    const handleSave = () => {
        const translationFields = {
            instructors: instructors,
        };

     
        const updatePayload = {
            language: selectedLanguage,
            translationFields,
            // nonTranslationFields,
        };

        console.log('Saving course with data:', updatePayload);
        onSaveSection('instructors', updatePayload);
    };

    


    // Add the following function
    const updateInstructorProfiles = async () => {
        setIsUpdating(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/course_instructors/${course._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
                body: JSON.stringify(instructors),
            });
            const responseData = await response.json();
            console.log('Instructors updated:', responseData);
            onCourseUpdate(true); // Call the onCourseUpdate callback to refetch the course data

        } catch (error) {
            console.error('Error updating instructors:', error);
        }
        setIsUpdating(false);
    };




    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    editMode ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: 'common.white',
                                    borderColor: 'common.white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'common.white',
                                    },
                                }}
                                onClick={handleCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>
                        </Box>
                        
                        
                    ) : (
                        <>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'white', color: 'blue' }}
                            onClick={updateInstructorProfiles}
                            disabled={isUpdating}
                        >
                            {isUpdating ? (
                                <>
                                    <CircularProgress size={20} style={{ marginRight: '8px' }} />
                                    Updating...
                                </>
                            ) : (
                                'Update Instructor Profiles'
                            )}
                        </Button>
                        <IconButton onClick={handleEdit} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                        </>
                    )

                }
                title="Instructors"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                    '.MuiCardHeader-title': { color: 'primary.contrastText' }
                }}
            />
            <CardContent>
                {editMode ? (
                    instructors.map((instructor, index) => (
                        <Box
                            key={index}
                            mb={2}
                            p={2}
                            borderRadius={1}
                            boxShadow={1}
                            bgcolor="grey.100"
                        >
                            <Box display="flex" alignItems="center" mb={1}>
                                <TextField
                                    label="Name"
                                    value={instructor.name}
                                    onChange={(e) => handleChange(index, 'name', e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    sx={{ marginRight: 2 }}
                                />
                                <IconButton onClick={() => handleRemoveInstructor(index)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                            <TextField
                                label="LinkedIn URL"
                                value={instructor.linkedIn}
                                onChange={(e) => handleChange(index, 'linkedIn', e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                sx={{ marginBottom: 2 }}
                            />
                            <TextField
                                label="Bio"
                                value={instructor.bio}
                                onChange={(e) => handleChange(index, 'bio', e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={3}
                                sx={{ marginBottom: 2 }}
                            />
                            <TextField
                                label="Role"
                                value={instructor.role}
                                onChange={(e) => handleChange(index, 'role', e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                sx={{ marginBottom: 2 }}
                            />
                            <TextField
                                label="Company"
                                value={instructor.company}
                                onChange={(e) => handleChange(index, 'company', e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                sx={{ marginBottom: 2 }}
                            />
                            <TextField
                                label="Website"                                value={instructor.website}
                                onChange={(e) => handleChange(index, 'website', e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                sx={{ marginBottom: 2 }}
                            />
                            <TextField
                                label="Avatar URL"
                                value={instructor.avatarUrl}
                                onChange={(e) => handleChange(index, 'avatarUrl', e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Box>
                    ))
       
                ) : (
                    instructors.map((instructor, index) => (
                       
    
                        <Box key={index} mb={2} display="flex" alignItems="flex-start">
                            <Avatar src={instructor.avatarUrl} alt={instructor.name} sx={{ width: 56, height: 56, marginRight: 2 }} />
                            <div>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>{instructor.name}</Typography>
                                {instructor.role && (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ marginBottom: 1, fontWeight: 'bold', fontStyle: 'italic' }}
                                    >
                                        {instructor.role}
                                    </Typography>
                                )}
                                {instructor.company && (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ marginBottom: 1, fontWeight: 'bold', fontStyle: 'italic' }}
                                    >
                                        {instructor.company}
                                    </Typography>
                                )}
                                {instructor.linkedIn && (
                                    <Link href={instructor.linkedIn} target="_blank" color="secondary" sx={{ marginBottom: 1, display: 'block' }}>
                                        {instructor.linkedIn}
                                    </Link>
                                )}
                                {instructor.website && (
                                    <Link href={instructor.website} target="_blank" color="secondary" sx={{ marginBottom: 1, display: 'block' }}>
                                        {instructor.website}
                                    </Link>
                                )}
                                {instructor.bio && (
                                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                        {instructor.bio}
                                    </Typography>
                                )}
                            </div>
                        </Box>
                    ))
                )}
                {editMode && (
                    <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddInstructor}>
                        Add Instructor
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default CourseInstructorsSection;

