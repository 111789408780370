import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import { Typography, Box } from '@mui/material';

const ChartComponent = ({ data, dataKey, xAxisKey, barColors, limit = null }) => {
  const displayData = limit ? data.slice(0, limit) : data;

  return (
    <Box>
      {limit && (
        <Typography variant="subtitle2" align="center" sx={{ mb: 2 }}>
          Showing top {limit} out of {data.length} providers
        </Typography>
      )}
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={displayData} margin={{ bottom: 0, left: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey={xAxisKey} 
            angle={-45} 
            textAnchor="end" 
            interval={0} 
            height={180}
            fontSize={12}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          {Object.entries(barColors).map(([key, color]) => (
            <Bar key={key} dataKey={key} stackId="a" fill={color} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ChartComponent;
