import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom'; // Import useLocation
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress from Material-UI
import Box from '@mui/material/Box'; // Import Box from Material-UI for layout



const ProtectedRoute = ({ element: Component }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation(); // Capture the current location

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh' // Take up full viewport height
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return user ? <Component /> : <Navigate to="/login" replace state={{ from: location }} />;
};

export default ProtectedRoute;
