// AddProviderModal.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    OutlinedInput,
    Box,  
    CircularProgress,  

} from '@mui/material';

const AddProviderModal = ({ open, onClose, onAdd, onUpdate, onError, showSnackbar, authToken, userEmail, environment, provider }) => {

    const [providerName, setProviderName] = useState('');
    const [providerCountry, setProviderCountry] = useState('');
    const [providerIsAffiliate, setProviderIsAffiliate] = useState(false);
    const [providerLogoUrl, setProviderLogoUrl] = useState('');
    const [logoPreview, setLogoPreview] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (provider) {
          setProviderName(provider.name);
          setProviderCountry(provider.country);
          setProviderIsAffiliate(provider.isAffiliate);
          setProviderLogoUrl(provider.logoUrl);
        }
      }, [provider]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const providerData = {
                name: providerName,
                country: providerCountry,
                isAffiliate: providerIsAffiliate,
                logoUrl: providerLogoUrl,
            };
            let response;

            if (provider) {
                response = await fetch(`${API_BASE_URL}/mydra/education-providers/${provider._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                        'X-User-Email': userEmail,
                        'X-Environment': environment,
                    },
                    body: JSON.stringify(providerData),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Provider updated:', data);
                    onUpdate(data.provider);
                    onClose();
                    showSnackbar('Provider updated successfully', 'success');
                } else {
                    console.error('Error updating provider:', response.statusText);
                    onError('Error updating provider');
                }
            } else {
                response = await fetch(`${API_BASE_URL}/mydra/education-providers`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                        'X-User-Email': userEmail,
                        'X-Environment': environment,
                    },
                    body: JSON.stringify(providerData),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Provider added:', data);
                    onAdd(data.provider);
                    onClose();
                } else {
                    console.error('Error adding provider:', response.statusText);
                    onError('Error adding provider');
                }
            }
        } catch (error) {
            console.error('Error adding provider:', error);
            onError('Error adding provider');
        } finally {
            setIsLoading(false);

            // Reset form fields
            setProviderName('');
            setProviderCountry('');
            setProviderIsAffiliate(false);
            setProviderLogoUrl('');
            setLogoPreview('');
        }
    };
    

    const handleClose = () => {
        // Reset form fields
        setProviderName('');
        setProviderCountry('');
        setProviderIsAffiliate(false);
        setProviderLogoUrl('');
        setLogoPreview('');

        // Close the modal
        onClose();
    };

    useEffect(() => {
        handleLogoPreview();
      }, [providerLogoUrl]);

    const handleLogoPreview = () => {
        if (providerLogoUrl) {
          setLogoPreview(providerLogoUrl);
        } else {
          setLogoPreview('');
        }
      };

    

    return (
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{provider ? 'Edit Provider' : 'Add New Provider'}</DialogTitle>
        <DialogContent>
            <TextField
            label="Provider Name"
            value={providerName}
            onChange={(e) => setProviderName(e.target.value)}
            fullWidth
            margin="normal"
            />
            <FormControl fullWidth margin="normal">
            <InputLabel>Provider Country</InputLabel>
            <Select
                value={providerCountry}
                onChange={(e) => setProviderCountry(e.target.value)}
                input={<OutlinedInput label="Provider Country" />}

            >
                <MenuItem value="">Select Country</MenuItem>
                <MenuItem value="es">Spain</MenuItem>
                <MenuItem value="uk">United Kingdom</MenuItem>
                <MenuItem value="pt">Portugal</MenuItem>
                <MenuItem value="de">Germany</MenuItem>
                <MenuItem value="it">Italy</MenuItem>
                <MenuItem value="nl">Netherlands</MenuItem>
                <MenuItem value="usa">United States of America</MenuItem>
                <MenuItem value="online">Online</MenuItem>
            </Select>
            </FormControl>
            <FormControlLabel
            control={
                <Switch
                checked={providerIsAffiliate}
                onChange={(e) => setProviderIsAffiliate(e.target.checked)}
                />
            }
            label="Is Affiliate"
            />

            <TextField
            label="Logo URL"
            value={providerLogoUrl}
            onChange={(e) => setProviderLogoUrl(e.target.value)}
            fullWidth
            margin="normal"
            />
            {logoPreview && (
            <Box mt={1}>
                <img src={logoPreview} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '80px' }} />
            </Box>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : provider ? 'Update' : 'Add'}
            </Button>     
        </DialogActions>
        </Dialog>
    );
};

export default AddProviderModal;