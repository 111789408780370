import React from 'react';
import { Box, Typography, Card, CardContent, Tooltip, CardActionArea } from '@mui/material';
import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

const RatingsReviews = ({ providerData }) => {
    const googleReviews = providerData?.google_reviews;

    return (
        <Card sx={{ mt:2, mb: 2, border: 1, borderColor: 'divider', borderRadius: '8px' }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Reviews & Ratings
                </Typography>
                {googleReviews ? (     
                    <Box display="inline-block" sx={{ mx: 0, my: 0, border: 1, borderColor: 'divider', borderRadius: '8px', p: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Google Reviews
                        </Typography>
                        <Tooltip title={`${googleReviews.rating} out of 5`} placement="top" arrow>
                            <div> {/* Use div to wrap Rating to support Tooltip */}
                                <Rating
                                    name="read-only"
                                    value={googleReviews.rating}
                                    precision={0.1}
                                    readOnly
                                    icon={<StarIcon fontSize="inherit" />}
                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                />
                            </div>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary">
                            {`${googleReviews.reviews} reviews`}
                        </Typography>
                    </Box>       
                    
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        No reviews available
                    </Typography>
                )}
                {/* Future platforms can be added here */}
            </CardContent>
        </Card>
    );
};

export default RatingsReviews;

