import React from 'react';
import { Box, Typography, Card, CardContent, Divider, List, ListItem, ListItemText, ListItemIcon, Chip } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import VideocamIcon from '@mui/icons-material/Videocam';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BusinessIcon from '@mui/icons-material/Business';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import FunctionsIcon from '@mui/icons-material/Functions';
import StarBorderIcon from '@mui/icons-material/StarBorder'; // Icon for reviews


const ScoreExplanation = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        How Scores Are Calculated
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Scoring Criteria (Scores range from 0 to 100)
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <SchoolIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Year Founded (15% weight)" secondary="Scores are normalized based on the year founded, with 2000 as the benchmark year. Education providers founded before 2000 are given the maximum score for this criterion. The formula adjusts the score to ensure it's always positive and caps it at 100, reflecting the provider's relative age." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <GroupIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Followers/Alumni (15% weight)" secondary="The count is scaled logarithmically (base 10) to balance its influence, using the formula (log10(Followers/Alumni) / log10(1,000,000)) * 100." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <VideocamIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Course Type (15% weight)" secondary="Instructor-led courses score 70, recorded courses 50, and those offering both modes score 100." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <EmojiEventsIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Official Certifications (15% weight)" secondary="Normalized based on the number of certifications up to a maximum of 10, using the formula (Certifications / 10) * 100." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <BusinessIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Employees (15% weight)" secondary="Scaled logarithmically (base 10) with a maximum of 500 employees, using the formula (log10(Employees) / log10(500)) * 100." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <SentimentVerySatisfiedIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Sentiment Risk (10% weight)" secondary="Normalized sentiment values between -1 and 1, with positive sentiments increasing the score and negative sentiments decreasing it." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <StarBorderIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Reviews (15% weight)" secondary="The reviews score is calculated based on the number of reviews and average rating from Google Reviews. The number of reviews is scaled logarithmically to moderate its impact, and the average rating contributes directly to the score within the allocated weight for reviews." />
            </ListItem>
          </List>

          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" gutterBottom>
            Overall Score Calculation
          </Typography>
          <Typography variant="body1" gutterBottom>
            The overall score is calculated by summing the weighted normalized scores for each criterion, including reviews. The mathematical formula used is:
          </Typography>
          <Chip icon={<FunctionsIcon />} label="Total Score = Σ (Normalized Score * Weight)" color="primary" variant="outlined" sx={{ mb: 2 }} />
        </CardContent>
      </Card>
    </Box>
  );
};


export default ScoreExplanation;
