import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Box, Modal, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FiberNewIcon from '@mui/icons-material/FiberNew';
// import ContactsList from '../ContactsList';
import ContactsList from '../ContactsList/ContactsList';

function CompaniesTable({ authToken, updateTrigger, ...props }) {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processingCompanies, setProcessingCompanies] = useState(new Set()); // Track companies being processed
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(''); // Add this line
  const [modalOpen, setModalOpen] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Ensure this is defined in your environment variables


  useEffect(() => {
    const fetchCompanies = async () => {
        setLoading(true);
        console.log("API_BASE_URL:", API_BASE_URL);
        try {
          const response = await fetch(`${API_BASE_URL}/get-all-companies`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
            },
          });

          if (!response.ok) {
              throw new Error('Failed to fetch companies');
          }
          let companiesData = await response.json();
          console.log('Companies:', companiesData);

          // Sort companies by createdAt in descending order
          // Sort companies by createdAt date, most recent first if there are any
          const sortedCompanies = companiesData.sort((a, b) => {
            const dateA = new Date(a.created_at); // Assuming 'createdAt' is your date field
            const dateB = new Date(b.created_at);
            return dateB - dateA; // Sort in descending order
          });          
          setCompanies(sortedCompanies);

            // Identify companies with 'processing' status and add them to the processingCompanies set
          const processingIds = new Set(
            sortedCompanies.filter(company => company.status === 'processing').map(company => company.id)
          );
          setProcessingCompanies(processingIds);

        } catch (error) {
            console.error("Error fetching companies:", error);
            // Handle error (e.g., show error message)
        } finally {
            setLoading(false);
        }
    };
    fetchCompanies();
    }, [authToken, updateTrigger]);


  // Poll for company status
  useEffect(() => {
    console.log("Processing Companies IDs:", Array.from(processingCompanies));

    const interval = setInterval(() => {
      processingCompanies.forEach(async (companyId) => {
        console.log('Fetching status for company:', companyId);
        const companyData = await fetchCompanyData(companyId);

        if (companyData) {
          // console.log('Company data:', companyData);
          setCompanies((prevCompanies) =>
            prevCompanies.map((company) =>
              company.id === companyId ? { ...company, ...companyData } : company // Update the entire company object with the fetched data
            )
          );
    
          if (companyData.status !== 'processing') {
            console.log('Company status:', companyData.status);
            // console.log('Company data:', companyData);
            setProcessingCompanies((prev) => {
              const updated = new Set(prev);
              updated.delete(companyId);
              return updated;
            });
            setCompanies((prevCompanies) =>
              prevCompanies.map((company) =>
                company.id === companyId ? { ...company, ...companyData } : company // This line updates the entire company object
              )
            );
          }
        }
      });
    }, 5000); // Poll every 5 seconds
  
      return () => clearInterval(interval);
    }, [processingCompanies]); // Dependency on processingCompanies to re-trigger the effect when it changes


  // Function to fetch a single company's data by ID
  const fetchCompanyData = async (companyId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/get-company/${companyId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch company data');
      }

      const companyData = await response.json();
      return companyData; // Return the fetched company data
    } catch (error) {
      console.error("Error fetching company data:", error);
      return null; // In case of an error, return null
    }
  };


  const handleViewContacts = (companyId) => {
    setSelectedCompanyId(companyId);
    setSelectedCompanyName(companies.find(company => company.id === companyId).name); // Add this line
    setModalOpen(true);
  };

  // Process company
  const handleProcessCompany = async (companyId) => {
    // setLoading(true); // Show loading indicator
    console.log('Processing company:', companyId);
    // Mark the company as being processed
    setProcessingCompanies(prev => new Set([...prev, companyId]));

    // Update the company's status in the local state to 'processing' immediately
    setCompanies(companies.map(company => {
      if (company.id === companyId) {
        return { ...company, status: 'processing' };
      }
      return company;
    }));

    try {
      console.log('Starting to process company:', companyId);
      const response = await fetch(`${API_BASE_URL}/start-processing-company/${companyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to start processing company');
      }
      console.log('Company processing started successfully');  
      // print the actual data received from the server
      const data = await response.json();
      console.log('Data:', data);

    } catch (error) {
      console.error("Error processing company:", error);
    } finally {
      // setLoading(false); // Hide loading indicator
    } // Unmark the company as being processed
  };

  // Mapping function to convert contact types to their abbreviations
const abbreviateContactType = (contactTypes) => {
  const abbreviations = {
    decisionMakers: 'DM',
    hrLeaders: 'HR',
    // Add more mappings here if needed
  };

  return contactTypes.map(type => abbreviations[type] || type);
};


  const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%', // Adjust the width to 80% of the viewport width
      maxWidth: 1200, // Set a maximum width to ensure it doesn't get too wide on large screens
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      maxHeight: '80%', // Adjust the height as needed
    };

  return (
      <>
      <TableContainer component={Paper} sx={{ mt: 4, overflowX: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: 'primary.light' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
              {/* CreatedAt */}
              {/* <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>CreatedAt</TableCell> */}
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Location</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Type of Contacts</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>AssingTo</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Contacts Identified</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Contacts Saved</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Contacts Discarded</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Contacts Validated</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Contacts Sent to Pipedrive</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}> {/* Ensure the box takes up full cell height */}
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : companies.length > 0 ? (
              <>
              {companies.map((company) => (
                <TableRow key={company.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {company.name ? (
                      <Link
                        href={company.url?.startsWith('http://') || company.url?.startsWith('https://') ? company.url : `https://${company.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company.name}
                      </Link>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>        
                  {/* <TableCell>{company.created_at || 'N/A'}</TableCell> */}
                              
                  <TableCell>{company.location || 'N/A'}</TableCell>
                    <TableCell>
                        {company.contactType ? abbreviateContactType(company.contactType).join(', ') : 'N/A'}

                    </TableCell>
                    <TableCell>{company.assignTo ? company.assignTo.split('@')[0] : 'N/A'}</TableCell>
                    <TableCell>{company?.counters?.contacts_identified || 0}</TableCell>
                    <TableCell>{company?.counters?.contacts_saved_to_database || 0}</TableCell>
                    <TableCell>
                      {(company?.counters?.contacts_discarded_non_relevance || 0) + (company?.counters?.contacts_discarded_incomplete_data || 0)}
                    </TableCell>
                    <TableCell>{company?.counters?.contacts_validated || 0}</TableCell>
                    <TableCell>{company?.counters?.contacts_sent_to_pipedrive || 0}</TableCell>

                    <TableCell>
                      {company.status === 'processing' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CircularProgress size={20} sx={{ mr: 1 }} />
                          Processing
                        </Box>
                      ) : company.status === 'error' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'error.main' }}>
                          <ErrorIcon sx={{ mr: 1 }} />
                          Error
                        </Box>
                      ) : company.status === 'completed' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'success.main' }}>
                          <CheckCircleIcon sx={{ mr: 1 }} />
                          Completed
                        </Box>
                        ) : company.status === 'new' ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', color: 'info.main' }}>
                            <FiberNewIcon sx={{ mr: 1 }} />
                            New
                          </Box>
                        ) : (
                        company.status
                      )}
                    </TableCell>

                    <TableCell>
                        {company.status === 'new' && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => handleProcessCompany(company.id)}
                              disabled={processingCompanies.has(company.id)}
                            >
                            {processingCompanies.has(company.id) ? 'Processing' : 'Process'}
                            </Button>
                        )}
                        {company.status === 'processing' && (
                            <Button size="small" variant="outlined" color="primary" disabled>Processing</Button>
                        )}
                        {company.status === 'completed' && (
                            <Button size="small" variant="outlined" color="primary" onClick={() => handleViewContacts(company.id)}>View</Button>
                        )}
                        
                        {/* Add more actions as needed */}
                    </TableCell>
                </TableRow>
            ))}
            </>
            ) : (
              <TableRow>
                <TableCell colSpan={9} sx={{ textAlign: 'center' }}>No companies found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
         
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="contacts-modal-title" variant="h6" component="h2">
            Contacts List for {selectedCompanyName}
          </Typography>
          <Typography id="contacts-modal-description" sx={{ mt: 2 }} component="div"> {/* Changed this line */}
            {selectedCompanyId && <ContactsList companyId={selectedCompanyId} authToken={authToken} />}
          </Typography>
          {/* Close button at the bottom of the modal */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt:2 }}>
            <Button onClick={() => setModalOpen(false)} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      </>
          
    );

  }


export default CompaniesTable;

