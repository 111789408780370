

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Container,
    CircularProgress,
    Switch,
    Typography,
    Box,

    } from '@mui/material';
import { useForm } from 'react-hook-form'; // Consider using for form handling
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import MydraAddCourseComponent from '../../components_mydra/MydraAddCourseComponent';


const MydraAddCoursePage = () => {
    const navigate = useNavigate();
    const [isProduction, setIsProduction] = useState(true);
    const [educationProviders, setEducationProviders] = useState([]);
    const [agreements, setAgreements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [authToken, setAuthToken] = useState(null); // Assuming you manage auth tokens globally
    const [userEmail, setUserEmail] = useState(null); // Assuming you manage user email globally    
    // React Hook Form for handling form inputs and validation
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [uploadType, setUploadType] = useState('single');


    const handleUploadTypeChange = (type) => {
        setUploadType(type);
    };
    

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                // Handle user not logged in or redirect to login
                console.log('User not logged in');
            }       
        });
    }, []);

    
    const handleCourseCreated = (courseId, environment) => {
        navigate(`/mydra/course/${courseId}?env=${environment}`);
    };

    // Toggle environment function
    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };


     return (
        <Container maxWidth={uploadType === 'single' ? 'sm' : 'lg'}>
            <Typography 
                variant="h4" 
                align="center"
                sx={{
                    my: 4,
                    fontWeight: 'bold',
                    color: 'primary.main',
                  }}
            >
                    Add New Course
            </Typography>
            {/* Environment toggle switch */}
            <Box display="flex" alignItems="center" mb={2} ml={0}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>
            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact live courses.
                </Typography>
            )}
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Box
                    sx={{
                        mt: 4, // Margin top
                        mb: 4, // Margin bottom
                        p: 4, // Padding
                        border: '1px solid', // Border width and style
                        borderColor: 'divider', // Border color from the theme
                        borderRadius: 2, // Border radius
                        backgroundColor: 'background.paper', // Background color from the theme
                        boxShadow: 3, // Shadow depth
                    }}
                >
                    <MydraAddCourseComponent
                        authToken={authToken}
                        userEmail={userEmail}
                        isProduction={isProduction}
                        onCourseCreated={handleCourseCreated}
                        onUploadTypeChange={handleUploadTypeChange}
                    />
                </Box>
            )}
            {error && <Typography color="error">{error}</Typography>}
        </Container>
    );
};



export default MydraAddCoursePage;
