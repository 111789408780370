import React, { useState, useEffect, useCallback } from 'react';
import { 
    Container, 
    Typography, 
    Box, 
    CircularProgress, 
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    TextField,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// import { debounce } from 'lodash';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';


const getFormattedDate = (date) => date.toISOString().split('T')[0];

const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Unknown';
  
    // Parse the ISO string as UTC
    const utcDate = parseISO(timestamp + 'Z');  // Append 'Z' to explicitly mark as UTC
  
    // Convert UTC to local time (this happens automatically)
    const localDate = new Date(utcDate);
  
    // Format the date and time in local time
    const formattedLocalDate = format(localDate, 'yyyy-MM-dd HH:mm:ss');
  
    // Calculate relative time from now (this will use the local time)
    const relativeTime = formatDistanceToNow(utcDate, { addSuffix: true });
    
    // return `${formattedLocalDate} (${relativeTime})`;
    return  (relativeTime);
  };



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CopilotAnalyticsDashboard = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [authToken, setAuthToken] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);


    const [analyticsData, setAnalyticsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dateRange, setDateRange] = useState({
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
        end: new Date()
    });

    const { overallStats, dailyConversationCounts, dailyStats } = analyticsData || {};
    const [dailyStatsForSelectedDate, setDailyStatsForSelectedDate] = useState([]);


    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        console.log('Daily conversation counts:', dailyConversationCounts);
    }, [dailyConversationCounts]);
    
    useEffect(() => {
        console.log('Daily stats:', dailyStats);
    }, [dailyStats]);

    const fetchAnalyticsData = useCallback(async () => {
        setIsLoading(true);
        try {
          const response = await fetch(`${API_BASE_URL}/mydra/copilot/analytics/all`, {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'X-Environment': 'Production',
            },
          });
          const data = await response.json();
          
          console.log("Raw data from API:", data);
      
          if (!data || typeof data !== 'object' || !data.dailyConversationCounts || !data.overallStats) {
            console.error("Data is not in the expected format:", data);
            throw new Error("Received data is not in the expected format");
          }
          
          // Transform the data for the chart
          const dailyConversationCounts = data.dailyConversationCounts.map(day => ({
            date: day.date,
            count: day.aggregate.count
          }));
      
          console.log("Processed dailyConversationCounts:", dailyConversationCounts);
      
          // Overall stats are already calculated in the API response
          const overallStats = data.overallStats;
      
          console.log("Overall stats:", overallStats);
      
          setAnalyticsData({
            dailyStats: data.dailyConversationCounts,
            dailyConversationCounts,
            overallStats
          });
        } catch (error) {
          console.error('Error fetching analytics data:', error);
          setAnalyticsData(null); // Set to null in case of error
        } finally {
          setIsLoading(false);
        }
      }, [authToken]);

    useEffect(() => {
        if (authToken) {
            fetchAnalyticsData();
        }
    }, [authToken, fetchAnalyticsData]);

    useEffect(() => {
        if (analyticsData) {
          console.log('Daily conversation counts:', analyticsData.dailyConversationCounts);
          console.log('Daily stats:', analyticsData.dailyStats);
        }
      }, [analyticsData]);

      useEffect(() => {
        if (analyticsData && analyticsData.dailyStats) {
          const today = new Date();
          const formattedToday = getFormattedDate(today);
          
          const todayStats = analyticsData.dailyStats.find(day => day.date === formattedToday);
          
          if (todayStats && todayStats.conversations) {
            setDailyStatsForSelectedDate(todayStats.conversations);
          } else {
            // If no data for today, find the most recent date with data
            const mostRecentDate = analyticsData.dailyStats
              .filter(day => day.conversations && day.conversations.length > 0)
              .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      
            if (mostRecentDate) {
              setSelectedDate(new Date(mostRecentDate.date));
              setDailyStatsForSelectedDate(mostRecentDate.conversations);
            } else {
              setDailyStatsForSelectedDate([]);
            }
          }
        }
      }, [analyticsData]);

    const fetchSpecificDateRange = async (start, end) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/copilot/analytics/daily-counts?start_date=${start.toISOString().split('T')[0]}&end_date=${end.toISOString().split('T')[0]}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': 'Production',
                },
            });
            const data = await response.json();
            setAnalyticsData(prevData => ({
                ...prevData,
                dailyConversationCounts: data
            }));
        } catch (error) {
            console.error('Error fetching specific date range:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateRangeChange = (start, end) => {
        setDateRange({ start, end });
        fetchSpecificDateRange(start, end);
    };



    const fetchDailyStats = async (date) => {
        setIsLoading(true);
        try {
            const formattedDate = date.toISOString().split('T')[0];
            const response = await fetch(`${API_BASE_URL}/mydra/copilot/analytics/daily?date=${formattedDate}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': 'Production',
                },
            });
            const data = await response.json();
            setAnalyticsData(prevData => ({
                ...prevData,
                dailyStats: data.conversations.filter(conversation => conversation.message_counter > 1)
            }));
        } catch (error) {
            console.error('Error fetching daily stats:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading || !analyticsData) {
        return (
            <Box display="flex" justifyContent="center" m={4}>
                <CircularProgress />
            </Box>
        );
    }


    
    const handleDateChange = (date) => {
        console.log("Date selected:", date);
        setSelectedDate(date);
        // Find the stats for the selected date
        const formattedDate = date.toISOString().split('T')[0];
        console.log("Formatted date:", formattedDate);
        const selectedDayStats = analyticsData.dailyStats.find(day => day.date === formattedDate);
        console.log("Selected day stats:", selectedDayStats);
        
        if (selectedDayStats && selectedDayStats.conversations) {
            console.log("Setting daily stats:", selectedDayStats.conversations);
            setDailyStatsForSelectedDate(selectedDayStats.conversations);
        } else {
            console.log("No data found for selected date");
            setDailyStatsForSelectedDate([]);
        }
    };
    
    
    return (
        <Container maxWidth="lg">
            <Typography variant="h4" align="center" gutterBottom>
                Copilot Analytics Dashboard
            </Typography>

            {isLoading ? (
                <Box display="flex" justifyContent="center" m={4}>
                    <CircularProgress />
                </Box>
            ) : analyticsData ? (
                <>
                    <Grid container spacing={3} mb={4}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="h6">Total Conversations</Typography>
                                <Typography variant="h4">{overallStats.total_conversations || 0}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="h6">LinkedIn Profiles Analyzed</Typography>
                                <Typography variant="h4">{overallStats.linkedin_profiles_analyzed || 0}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="h6">Learning Paths Generated</Typography>
                                <Typography variant="h4">{overallStats.learning_paths_generated || 0}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="h6">Total Messages Generated</Typography>
                                <Typography variant="h4">{overallStats.total_messages || 0}</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center' }}>
                            <Typography variant="body2">
                                  Last Updated: {formatLastUpdated(overallStats.updated_at)}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Typography variant="h5" gutterBottom>Daily Conversation Counts</Typography>
                    <Box mb={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                value={dateRange.start}
                                onChange={(newValue) => handleDateRangeChange(newValue, dateRange.end)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                label="End Date"
                                value={dateRange.end}
                                onChange={(newValue) => handleDateRangeChange(dateRange.start, newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box mb={4} height={400}>
                        <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={dailyConversationCounts}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="count" fill="#8884d8" name="Conversations" />
                        </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Typography variant="h5" gutterBottom>Daily Conversation Details</Typography>
                    <Box mb={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        </LocalizationProvider>
                    </Box>

                    {dailyStatsForSelectedDate && dailyStatsForSelectedDate.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Count</TableCell>
                                <TableCell>Session ID</TableCell>
                                <TableCell>LinkedIn URL</TableCell>
                                <TableCell>Message Count</TableCell>
                                <TableCell>LinkedIn Processed</TableCell>
                                <TableCell>Learning Path Generated</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dailyStatsForSelectedDate
                                    .filter(stat => stat.message_counter > 1)
                                    .map((stat, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{stat.session_id}</TableCell>
                                        <TableCell>{stat.linkedin_url}</TableCell>
                                        <TableCell>{stat.message_counter}</TableCell>
                                        <TableCell>{stat.linkedin_processed ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{stat.learning_path_generated ? 'Yes' : 'No'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        ) : (
                        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                            No data available for the selected date.
                        </Typography>
                        )}
                </>
            ) : (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    No data available.
                </Typography>
            )}
        </Container>
    );
};
         










export default CopilotAnalyticsDashboard;
