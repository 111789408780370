
import React, { useState } from 'react';
import { Card, CardContent, Typography, CardHeader, IconButton, CardActions, Button, TextField } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const CourseAdministrativeDetailsSection = ({ course  }) => {


    // Manage state for editing admin details if needed
    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                title="Administrative Details"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                    '.MuiCardHeader-title': { color: 'primary.contrastText' }
                }}
            />
            <CardContent>
                <Typography paragraph>
                    
                    Published: {course.published ? 'Yes' : 'No'}
                    <FiberManualRecordIcon
                        sx={{ color: course.published ? 'green' : 'red', verticalAlign: 'middle', ml: 1 }}
                    />
                </Typography>
                <Typography paragraph>Created At: {new Date(course.createdAt).toLocaleString()}</Typography>
                <Typography paragraph>Updated At: {new Date(course.updatedAt).toLocaleString()}</Typography>
                <Typography paragraph>Created By: {course.createdBy || 'Not specified'}</Typography>
                <Typography paragraph>Updated By: {course.updatedBy || 'Not specified'}</Typography>
                <Typography paragraph>Course ID: {course._id}</Typography>
            </CardContent>
        </Card>
    );
};

export default CourseAdministrativeDetailsSection;
