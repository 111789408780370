import React, { useEffect, useState } from 'react';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { 
    Container, 
    Typography, 
    Switch, 
    Box, 
    CircularProgress, 
    Button,
    Snackbar,
    Alert


} from '@mui/material';
import MydraProvidersList from '../../components_mydra/MydraProvidersList/MydraProvidersList';
import AddEditProviderModal from '../../components_mydra/AddEditProviderModal/AddEditProviderModal';

const MydraProvidersPage = () => {
    const [providers, setProviders] = useState([]);
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoadingProviders, setIsLoadingProviders] = useState(false);
    const [openAddProviderModal, setOpenAddProviderModal] = useState(false);
    const [editProvider, setEditProvider] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };

    const handleEditProvider = (provider) => {
        setEditProvider(provider);
        setOpenAddProviderModal(true);
      };

    const handleUpdateProvider = (updatedProvider) => {
        setProviders((prevProviders) =>
          prevProviders.map((provider) =>
            provider._id === updatedProvider._id ? updatedProvider : provider
          )
        );
        setEditProvider(null);
      };
    

    const handleAddProviderWithSnackbar = (newProvider) => {
        handleAddProvider(newProvider);
        showSnackbar('Provider added successfully', 'success');
    };

    const handleAddProviderError = (errorMessage) => {
        showSnackbar(errorMessage, 'error');
      };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
        if (user) {
            user.getIdToken().then((token) => {
            setAuthToken(token);
            setUserEmail(user.email);
            });
        } else {
            // Handle user not logged in or redirect to login
            console.log('User not logged in');
        }
        });
    }, []);

    useEffect(() => {
        // Fetch education providers data from the backend API
        if (authToken && userEmail) {
        console.log('Fetching education providers');
        fetchProviders();
        }
    }, [authToken, userEmail, isProduction]);

    const fetchProviders = async () => {
        try {
        setIsLoadingProviders(true);
        const response = await fetch(`${API_BASE_URL}/mydra/education-providers`, {
            headers: {
            'Authorization': `Bearer ${authToken}`,
            'X-User-Email': userEmail,
            'X-Environment': isProduction ? 'Production' : 'Staging',
            },
        });
        const responseData = await response.json();
        console.log('Providers:', responseData);
        setProviders(responseData.data);
        } catch (error) {
        console.error('Error fetching providers:', error);
        } finally {
        setIsLoadingProviders(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleAddProviderClick = () => {
        setOpenAddProviderModal(true);
    };

    const handleCloseModal = () => {
        setOpenAddProviderModal(false);
        setEditProvider(null);

    };

    const handleAddProvider = (newProvider) => {
        setProviders([...providers, newProvider]);
      };


  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
        Education Providers
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAddProviderClick} sx={{ mb: 2 }}>
        Add Provider
      </Button>

      <Box display="flex" alignItems="center" mb={2} ml={2}>
        <Typography variant="body1">Staging</Typography>
        <Switch
          checked={isProduction}
          onChange={toggleEnvironment}
          inputProps={{ 'aria-label': 'Environment toggle' }}
        />
        <Typography variant="body1">Production</Typography>
      </Box>
      {isProduction && (
        <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
          Warning: You are in Production mode. Changes may impact all live courses.
        </Typography>
      )}
      {authToken && userEmail ? (
        isLoadingProviders ? (
          <Box display="flex" justifyContent="center" mt={5}>
            <CircularProgress />
          </Box>
        ) : (
            <MydraProvidersList
                providers={providers}
                setProviders={setProviders} // Passing setProviders as a prop
                onEditProvider={handleEditProvider}
                isLoading={isLoadingProviders}
                authToken={authToken}
                userEmail={userEmail}
                environment={isProduction}
                onDeleteSuccess={showSnackbar}
                onDeleteError={(msg) => showSnackbar(msg, 'error')}
                />
        )
      ) : (
        <Typography variant="body1">Please log in to view the providers.</Typography>
      )}

      <AddEditProviderModal
        open={openAddProviderModal}
        onClose={handleCloseModal}
        onAdd={handleAddProviderWithSnackbar}
        onUpdate={handleUpdateProvider}
        onError={handleAddProviderError}
        showSnackbar={showSnackbar} // Pass this function as a prop

        authToken={authToken}
        userEmail={userEmail}
        environment={isProduction ? 'Production' : 'Staging'}
        provider={editProvider}
      />
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Container>
  );
};

export default MydraProvidersPage;