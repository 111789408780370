import React, { useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
    Container,
    Typography,
    Switch,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Chip,
    Collapse,
    IconButton,
    Link,
} from '@mui/material';


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StatusChip = ({ status }) => {
    let color;
    switch (status) {
        case 'APPLIED':
            color = 'primary';
            break;
        case 'PENDING_VALIDATION':
            color = 'warning';
            break;
        case 'APPROVED':
            color = 'success';
            break;
        case 'PENDING_CONTRACT':
            color = 'info';
            break;
        case 'MISSING_SCORING_DATA':
            color = 'error';
            break;
        default:
            color = 'default';
    }
    return <Chip label={status} color={color} size="small" style={{ margin: '2px' }} />;
};

const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    
    return age;
};


const ScoreItemRow = ({ item, isOpen, onToggle, application, authToken, isProduction }) => {
    const [isLoadingPayslip, setIsLoadingPayslip] = useState(false);
    const [payslipError, setPayslipError] = useState(null);

    const fetchUserDocuments = async (userId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/user-documents?userId=${userId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching user documents:', error);
            return null;
        }
    };

    const handleViewPayslip = async () => {
        setIsLoadingPayslip(true);
        setPayslipError(null);

        try {
            const response = await fetchUserDocuments(application.user._id);
            if (response && response.data) {
                const payslipDocument = response.data.find(doc => doc.type === 'payslip');

                if (payslipDocument) {
                    const fileResponse = await fetch(`${API_BASE_URL}/mydra/user-documents/${payslipDocument._id}/file`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'X-Environment': isProduction ? 'Production' : 'Staging',
                        },
                    });

                    if (fileResponse.ok) {
                        const blob = await fileResponse.blob();
                        const url = window.URL.createObjectURL(blob);
                        window.open(url, '_blank');
                    } else {
                        throw new Error('Failed to fetch payslip file');
                    }
                } else {
                    setPayslipError('No payslip available for this user');
                }
            } else {
                throw new Error('Invalid response format from user documents API');
            }
        } catch (error) {
            console.error('Error fetching payslip:', error);
            setPayslipError('Error fetching payslip');
        } finally {
            setIsLoadingPayslip(false);
        }
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={onToggle}>
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {item.scoreItem}
                </TableCell>
                <TableCell>
                    <Chip 
                        label={item.scoreResult} 
                        color={item.scoreResult === 'PASSED' ? 'success' : 'error'}
                        size="small"
                    />
                </TableCell>
                <TableCell>{item.message}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Additional Information
                            </Typography>
                            <Table size="small" aria-label="additional info">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Key</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(item.inputKeyData).map(([key, value]) => (
                                        <TableRow key={key}>
                                            <TableCell component="th" scope="row">
                                                {key}
                                            </TableCell>
                                            <TableCell>
                                                {JSON.stringify(value)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {item.scoreItem === 'GROSS_ANNUAL_SALARY' && (
                                        <TableRow>
                                            <TableCell>Payslip</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleViewPayslip}
                                                    disabled={isLoadingPayslip}
                                                >
                                                    View Payslip
                                                </Button>
                                                {isLoadingPayslip && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
                                                {payslipError && <Typography color="error">{payslipError}</Typography>}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Created At
                                        </TableCell>
                                        <TableCell>{new Date(item.createdAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Updated At
                                        </TableCell>
                                        <TableCell>{new Date(item.updatedAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};


const ApplicationRow = ({ application, authToken, isProduction, refreshApplications }) => {
    const [open, setOpen] = useState(false);
    const [scoreItems, setScoreItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reviewStatus, setReviewStatus] = useState('');
    const [reviewComments, setReviewComments] = useState('');
    const [openReviewDialog, setOpenReviewDialog] = useState(false);
    const [openScoreItemId, setOpenScoreItemId] = useState(null);


    const fetchScoreItems = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/score-item-reviews?applicationId=${application._id}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to fetch score items: ${errorData.error || response.statusText}`);
            }
            const data = await response.json();
            setScoreItems(data['data']);

            console.log('Score items:', data);
        } catch (error) {
            console.error('Error fetching score items:', error);
            // Optionally, you can set an error state here to display to the user
            // setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [application._id, authToken, isProduction]);


    const handleToggle = () => {
        setOpen(!open);
        if (!open && !scoreItems) {
            fetchScoreItems();
        }
    };

    const handleScoreItemReview = async (item, status) => {
        try {
            const response = await fetch(`${API_BASE_URL}/applications/${application._id}/validations/scoring/reviews`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: status,
                    comments: `${item} ${status}`,
                }),
            });
            if (!response.ok) throw new Error('Failed to update score item');
            await fetchScoreItems();
            refreshApplications();
        } catch (error) {
            console.error('Error updating score item:', error);
        }
    };

    const handleReviewOpen = () => {
        setOpenReviewDialog(true);
    };

    const handleReviewClose = () => {
        setOpenReviewDialog(false);
        setReviewStatus('');
        setReviewComments('');
    };

    const handleReviewSubmit = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/applications/${application._id}/validations/scoring/reviews`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: reviewStatus,
                    comments: reviewComments,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();
            handleReviewClose();
            refreshApplications();
        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };



    const renderScoreItems = () => {
        if (!scoreItems) return <Typography>No score items available</Typography>;

        if (Array.isArray(scoreItems)) {
            return (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Item</TableCell>
                                <TableCell>Result</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {scoreItems.map((item) => (
                                // <ScoreItemRow 
                                //     key={item._id} 
                                //     item={item} 
                                //     isOpen={openScoreItemId === item._id}
                                //     onToggle={() => setOpenScoreItemId(prevId => prevId === item._id ? null : item._id)}
                                // />

                                <ScoreItemRow 
                                    key={item._id} 
                                    item={item} 
                                    isOpen={openScoreItemId === item._id}
                                    onToggle={() => setOpenScoreItemId(prevId => prevId === item._id ? null : item._id)}
                                    application={application}
                                    authToken={authToken}
                                    isProduction={isProduction}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else if (typeof scoreItems === 'object') {
            // If scoreItems is an object, we'll display its properties
            return (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(scoreItems).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{JSON.stringify(value)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else {
            return <Typography>Unexpected score items format</Typography>;
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Function to get the course URL
    const getCourseUrl = (courseId) => {
        const baseUrl = isProduction
            ? 'https://marketplace.mydra.io'
            : 'https://marketplace.staging.mydra.io';
        return `${baseUrl}/courses/${courseId}`;
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={handleToggle}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{application._id}</TableCell>
                <TableCell>
                    <Typography variant="body2">{`${application.user.firstName} ${application.user.lastName}`}</Typography>
                    <Typography variant="caption" color="textSecondary">{application.user.email}</Typography>
                </TableCell>
                <TableCell>
                    <Link 
                        href={getCourseUrl(application.course._id)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {application.course.name}
                    </Link>
                </TableCell>
                <TableCell>{formatDate(application.createdAt)}</TableCell>

                <TableCell>
                    <Box display="flex" flexWrap="wrap">
                        {application.statuses.map((status, index) => (
                            <StatusChip key={index} status={status} />
                        ))}
                    </Box>
                </TableCell>
                <TableCell>
                    <Button variant="contained" color="primary" onClick={handleReviewOpen}>
                        Review
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Score Items
                            </Typography>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                renderScoreItems()
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <Dialog open={openReviewDialog} onClose={handleReviewClose} fullWidth maxWidth="md">
                <DialogTitle>Review Application</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="review-status-label">Status</InputLabel>
                        <Select
                            labelId="review-status-label"
                            value={reviewStatus}
                            onChange={(e) => setReviewStatus(e.target.value)}
                            label="Status"
                        >
                            <MenuItem value="approved">Approve</MenuItem>
                            <MenuItem value="rejected">Reject</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Comments"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={reviewComments}
                        onChange={(e) => setReviewComments(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReviewClose}>Cancel</Button>
                    <Button onClick={handleReviewSubmit} variant="contained" color="primary">
                        Submit Review
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};




const MydraUserScoring = () => {
    const [applications, setApplications] = useState([]);
    const [authToken, setAuthToken] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 20,
        totalPages: 1,
    });
    const [filters, setFilters] = useState({
        scoreResult: '',
    });
    const [openReviewDialog, setOpenReviewDialog] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [reviewStatus, setReviewStatus] = useState('');
    const [reviewComments, setReviewComments] = useState('');

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    console.log('User authenticated');
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    const fetchApplications = useCallback(async () => {
        console.log('Fetching applications...');
        try {
            setIsLoading(true);
            const baseParams = new URLSearchParams({
                page: pagination.page.toString(),
                limit: pagination.limit.toString(),
            });

            if (filters.scoreResult) baseParams.append('scoreResult', filters.scoreResult);

            const url = `${API_BASE_URL}/user-scoring?${baseParams}`;
            console.log('Fetching from URL:', url);

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response:', errorData);
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);

            setApplications(responseData.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: responseData.totalPages,
            }));
        } catch (error) {
            console.error('Error fetching applications:', error);
            setApplications([]);
        } finally {
            setIsLoading(false);
        }
    }, [authToken, isProduction, pagination.page, pagination.limit, filters]);

    useEffect(() => {
        if (authToken) {
            fetchApplications();
        }
    }, [authToken, isProduction, pagination.page, filters, fetchApplications]);

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handlePageChange = (event, newPage) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: newPage,
        }));
    };

    const handleFilterChange = (event) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [event.target.name]: event.target.value,
        }));
    };

    const handleReviewOpen = (application) => {
        setSelectedApplication(application);
        setOpenReviewDialog(true);
    };

    const handleReviewClose = () => {
        setOpenReviewDialog(false);
        setSelectedApplication(null);
        setReviewStatus('');
        setReviewComments('');
    };

    const handleReviewSubmit = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/applications/${selectedApplication._id}/validations/scoring/reviews`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: reviewStatus,
                    comments: reviewComments,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();
            handleReviewClose();
            fetchApplications();
        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };

   
      

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" align="center" sx={{ mt: 4, mb: 4 }}>
                User Scoring Dashboard
            </Typography>

            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            <FormControl sx={{ mb: 2, minWidth: 120 }}>
                <InputLabel id="score-result-label">Score Result</InputLabel>
                <Select
                    labelId="score-result-label"
                    value={filters.scoreResult}
                    onChange={handleFilterChange}
                    name="scoreResult"
                    label="Score Result"
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="PASSED">Passed</MenuItem>
                    <MenuItem value="FAILED">Failed</MenuItem>
                    <MenuItem value="MISSING_DATA">Missing Data</MenuItem>
                </Select>
            </FormControl>

            {isLoading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : applications.length > 0 ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Application ID</TableCell>
                                    <TableCell>User</TableCell>
                                    <TableCell>Course</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Statuses</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applications.map((application) => (
                                    <ApplicationRow
                                        key={application._id}
                                        application={application}
                                        authToken={authToken}
                                        isProduction={isProduction}
                                        refreshApplications={fetchApplications}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                            count={pagination.totalPages}
                            page={pagination.page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            ) : (
                <Alert severity="info">No applications found.</Alert>
            )}

            <Dialog open={openReviewDialog} onClose={handleReviewClose}>
                <DialogTitle>Review Application</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="review-status-label">Status</InputLabel>
                        <Select
                            labelId="review-status-label"
                            value={reviewStatus}
                            onChange={(e) => setReviewStatus(e.target.value)}
                            label="Status"
                        >
                            <MenuItem value="approved">Approve</MenuItem>
                            <MenuItem value="rejected">Reject</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Comments"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={reviewComments}
                        onChange={(e) => setReviewComments(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReviewClose}>Cancel</Button>
                    <Button onClick={handleReviewSubmit} variant="contained" color="primary">
                        Submit Review
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default MydraUserScoring;