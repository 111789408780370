import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { 
    Container, 
    Typography, 
    Switch, 
    Box, 
    CircularProgress, 
    Button,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    TextField,
    IconButton, 
    Select,
    MenuItem,
    FormControl,
    Drawer,
    Divider,
    Grid,
    Link,
    InputLabel,
    FormHelperText, 
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';


import CompanyUsers from '../../components_mydra/CompanyUsers/CompanyUsers';

const MydraCompaniesPage = () => {
    const [companies, setCompanies] = useState([]);
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isEditingInDrawer, setIsEditingInDrawer] = useState(false);
    const [editedCompanyDataInDrawer, setEditedCompanyDataInDrawer] = useState({});

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
    const [companyToVerify, setCompanyToVerify] = useState(null);
    const [isVerifying, setIsVerifying] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleRowClick = (company) => {
        setSelectedCompany(company);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedCompany(null);
    };

    const handleEditInDrawer = () => {
        setIsEditingInDrawer(true);
        setEditedCompanyDataInDrawer({ ...selectedCompany });
    };

    const handleCancelEditInDrawer = () => {
        setIsEditingInDrawer(false);
        setEditedCompanyDataInDrawer({});
    };

    const handleInputChangeInDrawer = (e, field) => {
        setEditedCompanyDataInDrawer({ ...editedCompanyDataInDrawer, [field]: e.target.value });
    };

    const handleSaveCompanyInDrawer = async () => {
        setIsSaving(true);
        try {
            const dataToSend = {
                ...editedCompanyDataInDrawer,
                tier: Number(editedCompanyDataInDrawer.tier)
            };

            const response = await fetch(`${API_BASE_URL}/mydra/companies/${selectedCompany._id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (response.ok) {
                setCompanies(companies.map(company => 
                    company._id === selectedCompany._id ? { ...company, ...dataToSend } : company
                ));
                setSelectedCompany({ ...selectedCompany, ...dataToSend });
                showSnackbar('Company updated successfully', 'success');
                setIsEditingInDrawer(false);
            } else {
                const errorData = await response.json();
                throw new Error(`Failed to update company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error updating company:', error);
            showSnackbar(`Failed to update company: ${error.message}`, 'error');
        } finally {
            setIsSaving(false);
        }
    };

    const tierOptions = [
        { value: '1', label: '1 - Premium' },
        { value: '2', label: '2 - Standard' },
        { value: '3', label: '3 - Basic' },
    ];
    
    const getTierLabel = (value) => {
        let mappedValue;
        switch(value) {
            case 1:
            case '1':
                mappedValue = '1';
                break;
            case 2:
            case '2':
                mappedValue = '2';
                break;
            case 3:
            case '3':
                mappedValue = '3';
                break;
            default:
                mappedValue = null;
        }
        const tier = tierOptions.find(option => option.value === String(mappedValue));
        return tier ? tier.label : 'Unknown';
    };

    const [isAddingCompany, setIsAddingCompany] = useState(false);
    const [newCompanyData, setNewCompanyData] = useState({
        name: '',
        legalName: '',
        tier: '',
        vat: '',
        address: '',
        domain: '',
    });

    const [formErrors, setFormErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!newCompanyData.name.trim()) {
            errors.name = 'Company Name is required';
        }
        if (!newCompanyData.legalName.trim()) {
            errors.legalName = 'Legal Name is required';
        }
        if (!newCompanyData.tier) {
            errors.tier = 'Tier is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleAddCompany = async () => {
        if (!validateForm()) {
            return;
        }
    
        setIsAddingCompany(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/companies`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCompanyData),
            });
    
            if (response.ok) {
                const createdCompany = await response.json();
                showSnackbar('Company created successfully', 'success');
                fetchCompanies(); // Refetch the list of companies
                setNewCompanyData({
                    name: '',
                    legalName: '',
                    tier: '',
                    vat: '',
                    address: '',
                    domain: '',
                });
                setFormErrors({});
                setAddCompanyDialogOpen(false); // Close the dialog after successful creation
            } else {
                const errorData = await response.json();
                throw new Error(`Failed to create company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error creating company:', error);
            showSnackbar(`Failed to create company: ${error.message}`, 'error');
        } finally {
            setIsAddingCompany(false);
        }
    };
    


    const handleNewCompanyInputChange = (e) => {
        const { name, value } = e.target;
        setNewCompanyData(prevData => ({
            ...prevData,
            [name]: value
        }));
        // Clear the error for this field when the user starts typing
        if (formErrors[name]) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        }
    };


    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const openConfirmDialog = (company) => {
        setCompanyToVerify(company);
        setConfirmDialogOpen(true);
    };
    
    const closeConfirmDialog = () => {
        setCompanyToVerify(null);
        setConfirmDialogOpen(false);
    };


    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        if (authToken && userEmail) {
            console.log('Fetching companies');
            fetchCompanies();
        }
    }, [authToken, userEmail, isProduction]);

    const fetchCompanies = async () => {
        try {
            setIsLoadingCompanies(true);
            const response = await fetch(`${API_BASE_URL}/mydra/companies`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            const responseData = await response.json();
            console.log('Companies:', responseData);
            setCompanies(responseData);
        } catch (error) {
            console.error('Error fetching companies:', error);
            showSnackbar('Failed to fetch companies', 'error');
        } finally {
            setIsLoadingCompanies(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

   
    const handleVerifyCompany = async () => {
        if (!companyToVerify) return;
    
        setIsVerifying(true);    
        try {
            const updateData = {
                verified: true,
                name: companyToVerify.name,
                legalName: companyToVerify.legalName,
                tier: companyToVerify.tier,
                vat: companyToVerify.vat,
                address: companyToVerify.address,
                domain: companyToVerify.domain,
                taxBenefitsProvider: companyToVerify.taxBenefitsProvider,
                bankName: companyToVerify.bankName,
                iban: companyToVerify.iban
            };
    
            // Remove undefined fields
            Object.keys(updateData).forEach(key => updateData[key] === undefined && delete updateData[key]);
    
            const response = await fetch(`${API_BASE_URL}/mydra/companies/${companyToVerify._id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
    
            if (response.ok) {
                setCompanies(companies.map(company => 
                    company._id === companyToVerify._id ? { ...company, ...updateData } : company
                ));
                showSnackbar('Company verified and updated successfully', 'success');
                fetchCompanies(); // Refresh the company list
            } else {
                const errorData = await response.json();
                throw new Error(`Failed to verify company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error verifying company:', error);
            showSnackbar(`Failed to verify company: ${error.message}`, 'error');
        } finally {
            setCompanyToVerify(null);
            setConfirmDialogOpen(false);
            setIsVerifying(false);
        }
    };


    const renderHeaderCell = (label, tooltip = null) => (
        <TableCell 
          key={label}
          sx={{
            fontWeight: 600,
            color: '#1a237e',
            fontSize: '0.9rem',
            padding: '14px 20px',
            letterSpacing: '0.3px',
          }}
        >
          {label}
          {tooltip && (
            <Tooltip
              title={
                <React.Fragment>
                  {Array.isArray(tooltip) ? (
                    tooltip.map((line, index) => (
                      <Typography key={index} variant="body2">
                        {line}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">{tooltip}</Typography>
                  )}
                </React.Fragment>
              }
              arrow
              placement="top"
            >
              <InfoIcon 
                fontSize="small" 
                sx={{ 
                  marginLeft: 1, 
                  verticalAlign: 'middle', 
                  cursor: 'help',
                  color: '#3f51b5',
                }} 
              />
            </Tooltip>
          )}
        </TableCell>
      );


    const headerCells = [
        { label: '#', tooltip: null },
        { label: 'Name', tooltip: null },
        { label: 'Legal Name', tooltip: null },
        // vat
        { label: 'VAT number', tooltip: null},
        { label: 'Code', tooltip: null },
       
        { label: 'Domain', tooltip: null },
        { label: 'Tax Benefits Provider', tooltip: null },
        { label: 'Tier', tooltip: [
            'How does the tier impact Fixpay?',
            '• Tier 1: Maximum loan amount €10k',
            '• Tier 2: Maximum loan amount €7k',
            '• Tier 3: Maximum loan amount €3k'
          ]},
        { label: 'Verification Status', tooltip: null },
        { label: 'Created At', tooltip: null },
        { label: 'Actions', tooltip: null },
      ];

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>

            <Container maxWidth={false} sx={{ mt: 4, px: { xs: 1, sm: 2, md: 3 } }}>
                <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
                    Companies
                </Typography>

                {/* <Box display="flex" alignItems="center" mb={2} ml={2}>
                    <Typography variant="body1">Staging</Typography>
                    <Switch
                        checked={isProduction}
                        onChange={toggleEnvironment}
                        inputProps={{ 'aria-label': 'Environment toggle' }}
                    />
                    <Typography variant="body1">Production</Typography>
                </Box> */}

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1">Staging</Typography>
                        <Switch
                            checked={isProduction}
                            onChange={toggleEnvironment}
                            inputProps={{ 'aria-label': 'Environment toggle' }}
                        />
                        <Typography variant="body1">Production</Typography>
                    </Box>
                    
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setAddCompanyDialogOpen(true)}
                    >
                        Add Company
                    </Button>
                </Box>
                
                {isProduction && (
                    <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                        Warning: You are in Production mode. Changes may impact live data.
                    </Typography>
                )}
    
                {authToken && userEmail ? (
                    isLoadingCompanies ? (
                        <Box display="flex" justifyContent="center" mt={5}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>      
                            <TableHead>
                                <TableRow sx={{
                                    backgroundColor: '#f0f4f8',
                                    borderBottom: '2px solid #3f51b5',
                                    fontWeight: 'bold',
                                }}>
                                    {headerCells.map(cell => renderHeaderCell(cell.label, cell.tooltip))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    {companies.map((company, index) => (
                                        <TableRow 
                                            key={company._id}
                                            onClick={() => handleRowClick(company)}
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                            }}
                                        >
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{company.name}</TableCell>
                                            <TableCell>{company.legalName}</TableCell>
                                            <TableCell>{company.vat}</TableCell>
                                            <TableCell>{company.code}</TableCell>
                                            <TableCell>{company.domain}</TableCell>
                                            <TableCell>{company.taxBenefitsProvider}</TableCell>
                                            <TableCell>{getTierLabel(company.tier)}</TableCell>
                                            <TableCell>
                                                {company.verified ? (
                                                    <Tooltip title="Verified">
                                                        <Box display="flex" alignItems="center">
                                                            <CheckCircleIcon color="success" />
                                                            <Typography variant="body2" sx={{ ml: 1 }}>Verified</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Pending Verification">
                                                        <Box display="flex" alignItems="center">
                                                            <PendingIcon color="warning" />
                                                            <Typography variant="body2" sx={{ ml: 1 }}>Pending</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                            <TableCell>{new Date(company.createdAt).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                {!company.verified && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openConfirmDialog(company);
                                                        }}
                                                        size="small"
                                                    >
                                                        Verify
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    )
                ) : (
                    <Typography variant="body1">Please log in to view the companies.</Typography>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                
                {/* Verify company dialog */}
                <Dialog
                    open={confirmDialogOpen}
                    onClose={closeConfirmDialog}
                    aria-labelledby="verify-dialog-title"
                    aria-describedby="verify-dialog-description"
                >
                    <DialogTitle id="verify-dialog-title">{"Confirm Company Verification"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="verify-dialog-description">
                            Are you sure you want to verify the company "{companyToVerify?.name}"?
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog} color="primary" disabled={isVerifying}>
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleVerifyCompany} 
                            color="primary" 
                            autoFocus 
                            disabled={isVerifying}
                        >
                            {isVerifying ? 'Verifying...' : 'Verify'}
                        </Button>
                    </DialogActions>
                </Dialog>



                {/* dialog to craete a company */}
                {/* <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                    <DialogTitle>Add New Company</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.name}
                            onChange={handleNewCompanyInputChange}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            required
                        />
                        <TextField
                            margin="dense"
                            name="legalName"
                            label="Legal Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.legalName}
                            onChange={handleNewCompanyInputChange}
                            error={!!formErrors.legalName}
                            helperText={formErrors.legalName}
                            required
                        />
                        <FormControl fullWidth margin="dense" error={!!formErrors.tier} required>
                            <InputLabel id="tier-select-label">Tier</InputLabel>
                            <Select
                                labelId="tier-select-label"
                                name="tier"
                                value={newCompanyData.tier}
                                onChange={handleNewCompanyInputChange}
                            >
                                <MenuItem value={1}>1 - Premium</MenuItem>
                                <MenuItem value={2}>2 - Standard</MenuItem>
                                <MenuItem value={3}>3 - Basic</MenuItem>
                            </Select>
                            {formErrors.tier && <FormHelperText>{formErrors.tier}</FormHelperText>}
                        </FormControl>
                        <TextField
                            margin="dense"
                            name="vat"
                            label="VAT Number"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.vat}
                            onChange={handleNewCompanyInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="address"
                            label="Address"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.address}
                            onChange={handleNewCompanyInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="domain"
                            label="Domain"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.domain}
                            onChange={handleNewCompanyInputChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
                        <Button 
                            onClick={handleAddCompany} 
                            variant="contained" 
                            color="primary"
                            disabled={isAddingCompany || !newCompanyData.name || !newCompanyData.legalName || !newCompanyData.tier}
                        >
                            {isAddingCompany ? 'Creating...' : 'Create Company'}
                        </Button>
                    </DialogActions>
                </Dialog> */}

                <Dialog
                    open={confirmDialogOpen}
                    onClose={closeConfirmDialog}
                    aria-labelledby="verify-dialog-title"
                    aria-describedby="verify-dialog-description"
                >
                    <DialogTitle id="verify-dialog-title">{"Confirm Company Verification"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="verify-dialog-description">
                            Are you sure you want to verify the company "{companyToVerify?.name}"?
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog} color="primary" disabled={isVerifying}>
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleVerifyCompany} 
                            color="primary" 
                            autoFocus 
                            disabled={isVerifying}
                        >
                            {isVerifying ? 'Verifying...' : 'Verify'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add Company Dialog */}
                <Dialog open={addCompanyDialogOpen} onClose={() => setAddCompanyDialogOpen(false)}>
                    <DialogTitle>Add New Company</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.name}
                            onChange={handleNewCompanyInputChange}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            required
                        />
                        <TextField
                            margin="dense"
                            name="legalName"
                            label="Legal Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.legalName}
                            onChange={handleNewCompanyInputChange}
                            error={!!formErrors.legalName}
                            helperText={formErrors.legalName}
                            required
                        />
                        <FormControl fullWidth margin="dense" error={!!formErrors.tier} required>
                            <InputLabel id="tier-select-label">Tier</InputLabel>
                            <Select
                                labelId="tier-select-label"
                                name="tier"
                                value={newCompanyData.tier}
                                onChange={handleNewCompanyInputChange}
                            >
                                <MenuItem value={1}>1 - Premium</MenuItem>
                                <MenuItem value={2}>2 - Standard</MenuItem>
                                <MenuItem value={3}>3 - Basic</MenuItem>
                            </Select>
                            {formErrors.tier && <FormHelperText>{formErrors.tier}</FormHelperText>}
                        </FormControl>
                        <TextField
                            margin="dense"
                            name="vat"
                            label="VAT Number"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.vat}
                            onChange={handleNewCompanyInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="address"
                            label="Address"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.address}
                            onChange={handleNewCompanyInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="domain"
                            label="Domain"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.domain}
                            onChange={handleNewCompanyInputChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddCompanyDialogOpen(false)}>Cancel</Button>
                        <Button 
                            onClick={handleAddCompany} 
                            variant="contained" 
                            color="primary"
                            disabled={isAddingCompany || !newCompanyData.name || !newCompanyData.legalName || !newCompanyData.tier}
                        >
                            {isAddingCompany ? 'Creating...' : 'Create Company'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </Container>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: { width: { xs: '100%', sm: 900 } }
                }}
            >
          
                <Box sx={{ p: 3 }}>
                    {selectedCompany && (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Company Details
                                {!isEditingInDrawer ? (
                                    <Button
                                        startIcon={<EditIcon />}
                                        onClick={handleEditInDrawer}
                                        sx={{ ml: 2 }}
                                    >
                                        Edit
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            startIcon={<SaveIcon />}
                                            onClick={handleSaveCompanyInDrawer}
                                            sx={{ ml: 2 }}
                                            disabled={isSaving}
                                        >
                                            {isSaving ? 'Saving...' : 'Save'}
                                        </Button>
                                        <Button
                                            startIcon={<CancelIcon />}
                                            onClick={handleCancelEditInDrawer}
                                            sx={{ ml: 2 }}
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                )}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Name</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.name || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'name')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.name}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Domain</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.domain || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'domain')}
                                        />
                                    ) : (
                                        selectedCompany.domain ? (
                                            <Link 
                                                href={`https://${selectedCompany.domain}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                sx={{ 
                                                    color: 'primary.main',
                                                    textDecoration: 'none',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                {selectedCompany.domain}
                                            </Link>
                                        ) : (
                                            <Typography>N/A</Typography>
                                        )
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Code</Typography>
                                    <Typography>{selectedCompany.code}</Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Legal Name</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.legalName || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'legalName')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.legalName || 'N/A'}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">VAT Number</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.vat || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'vat')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.vat || 'N/A'}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Address</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.address || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'address')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.address || 'N/A'}</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Tier</Typography>
                                    {isEditingInDrawer ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="tier-select-label">Tier</InputLabel>
                                            <Select
                                                labelId="tier-select-label"
                                                value={editedCompanyDataInDrawer.tier || ''}
                                                onChange={(e) => handleInputChangeInDrawer(e, 'tier')}
                                            >
                                                <MenuItem value={1}>1 - Premium</MenuItem>
                                                <MenuItem value={2}>2 - Standard</MenuItem>
                                                <MenuItem value={3}>3 - Basic</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography>{getTierLabel(selectedCompany.tier)}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Verification Status</Typography>
                                    <Box display="flex" alignItems="center">
                                        {selectedCompany.verified ? (
                                            <>
                                                <CheckCircleIcon color="success" fontSize="small" />
                                                <Typography variant="body2" sx={{ ml: 1 }}>Verified</Typography>
                                            </>
                                        ) : (
                                            <>
                                                <PendingIcon color="warning" fontSize="small" />
                                                <Typography variant="body2" sx={{ ml: 1 }}>Pending</Typography>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2">Created At</Typography>
                                    <Typography>{new Date(selectedCompany.createdAt).toLocaleDateString()}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Tax Benefits Provider</Typography>
                                    <Typography>{selectedCompany.taxBenefitsProvider || 'N/A'}</Typography>
                                </Grid>
                            </Grid>

                            <Divider sx={{ my: 2 }} />
                            <Typography variant="h5" gutterBottom>
                                Company Users
                            </Typography>
                            <CompanyUsers
                                companyId={selectedCompany._id}
                                authToken={authToken}
                                userEmail={userEmail}
                                isProduction={isProduction}
                            />
                        </>
                    )}
                </Box>
            </Drawer>
        </Box>
    );
};

export default MydraCompaniesPage;