import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon from Material UI
import Button from '@mui/material/Button'; // Import Button from Material UI
import { TableCell, TableRow, Chip, Box, Typography, TextField, Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // For the dot icon


import { useState } from 'react'; // Import useState from React

const MydraCategoryItem = ({ category, onSubcategoryAdded, authToken, userEmail, isProduction }) => {

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Ensure this is defined in your environment variables

    // Example handlers for edit, delete, and add subcategory actions
    const [isAddSubcategoryModalOpen, setAddSubcategoryModalOpen] = useState(false);
    const [subCategoryName, setSubCategoryName] = useState('');
    
    const [isAdding, setIsAdding] = useState(false); // New state for loading indication


    const handleOpenAddSubcategoryModal = (categoryId) => {
        console.log('Adding subcategory to category:', categoryId);
        setAddSubcategoryModalOpen(true);
    }

    const handleCloseAddSubcategoryModal = () => {
        setAddSubcategoryModalOpen(false);
        setSubCategoryName(''); // Reset the input field
    };

    const handleAddSubcategory = async () => {
        setIsAdding(true); // Start loading
        console.log('Adding subcategory:', subCategoryName);
        // Make the API call to add the subcategory
        // Assuming you have a function to make the API call and handle the response
        const API_URL = `${API_BASE_URL}/mydra/subcategories`;

        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail, // Replace with the user's email
                    'X-Environment': isProduction ? 'Production' : 'Staging', // Replace with your actual environment variable
                },
                body: JSON.stringify({
                    categoryId: category._id,
                    subcategoryName: subCategoryName,
                }),
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message || 'Failed to add subcategory');    
            }
            const result = await response.json();
            console.log('Subcategory added:', result);
            onSubcategoryAdded(); // This would be a callback to refresh the categories in the parent component
            handleCloseAddSubcategoryModal();
        } catch (error) {
            console.error('Error adding subcategory:', error);
            alert(error.message); // Show an alert with the error message
            // Handle the error
        } finally {
            setIsAdding(false); // Stop loading
        }
    };

 
    return (
        <>
        <TableRow>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {category.icon && (
                        <img
                            src={`data:${category.icon.contentType};base64,${category.icon.data}`}
                            alt={`${category.name} Icon`}
                            style={{ width: '35px', height: '35px', marginRight: '10px' }} // Adjust size as needed                      
                        />
                        )}             
                    <Link href={`/mydra/category/${category._id}/${encodeURIComponent(category.name)}`} target="_blank" rel="noopener noreferrer">
                        {category.name}
                    </Link>              
                </Box>
            </TableCell>           
            <TableCell>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                    {category.subCategories.map(subCategory => (
                        <Chip
                            key={subCategory._id}
                            label={
                                <>
                                    <FiberManualRecordIcon 
                                        fontSize="very-small" 
                                        sx={{ color: subCategory.isActive ? 'success.main' : 'error.main', mr: 0.5 }} // Green or red dot
                                    />
                                    {subCategory.name}
                                </>
                            }
                            // onDelete={() => onDeleteSubCategory(subCategory._id)}
                            deleteIcon={<DeleteIcon />}
                            variant="outlined"
                            sx={{ margin: '5px', display: 'flex', alignItems: 'center' }} // Align items in the chip
                        />
                    ))}
                </Box>
            </TableCell>
            <TableCell>
                {/* Assuming you have a method to handle adding a subcategory */}
                    {/* <Chip label="Add SubCategory" clickable color="primary" onClick={onAddSubcategory} /> */}
                    <Chip label="Add SubCategory" clickable color="primary" onClick={() => handleOpenAddSubcategoryModal(category._id)} />

            </TableCell>
        </TableRow>

        {/* <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Deactivate Subcategory</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to deactivate this subcategory? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleDeactivateSubCategory} autoFocus>
                    Deactivate
                </Button>
            </DialogActions>
        </Dialog> */}

        {/* Add Subcategory Modal */}
        <Dialog open={isAddSubcategoryModalOpen} onClose={handleCloseAddSubcategoryModal}>
                <DialogTitle>Add Subcategory to {category.name}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="subcategory-name"
                        label="Subcategory Name"
                        type="text"
                        fullWidth
                        value={subCategoryName}
                        onChange={(e) => setSubCategoryName(e.target.value)}
                        placeholder="E.g. 'Advanced Algorithms'"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddSubcategoryModal} disabled={isAdding}>Cancel</Button>
                    <Button onClick={handleAddSubcategory} disabled={isAdding}>
                        {isAdding ? 'Adding...' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MydraCategoryItem;

