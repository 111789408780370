// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6', // Customize your primary color
    },
    secondary: {
      main: '#19857b', // Customize your secondary color
    },
    // Add more colors as needed
  },
  typography: {
    // Define your typography adjustments here
    h1: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    // Add more typography styles as needed
  },
  // You can also customize other theme aspects such as spacing, breakpoints, etc.
});

export default theme;


