import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, IconButton, List, ListItem, TextField, Typography, ListItemIcon } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import StarBorderIcon from '@mui/icons-material/StarBorder'; // Icon for Achievements
import BuildIcon from '@mui/icons-material/Build'; // Icon for Skills
import ExploreIcon from '@mui/icons-material/Explore'; // Icon for Learning Areas
import GroupIcon from '@mui/icons-material/Group'; // Icon for Targets


const CourseEngagementOutcomesSection = ({ course, selectedLanguage, isSectionEditable, onEditSection, onSaveSection, isSaving }) => {

    // const [originalLearningDescription, setOriginalLearningDescription] = useState(course.courseDetailTranslations?.find(detail => detail.language === selectedLanguage)?.learningDescription || '');

    const [editableTargets, setEditableTargets] = useState([]);
    const [editableLearningAreas, setEditableLearningAreas] = useState([]);
    const [editablePrerequisites, setEditablePrerequisites] = useState([]);
    const [editableSkills, setEditableSkills] = useState([]);

    const [newSkill, setNewSkill] = useState('');
    const [newLearningArea, setNewLearningArea] = useState('');
    // const [newTarget, setNewTarget] = useState('');

    const [originalTargets, setOriginalTargets] = useState([]);
    const [originalSkills, setOriginalSkills] = useState([]);
    const [originalLearningAreas, setOriginalLearningAreas] = useState([]);
    const [originalPrerequisites, setOriginalPrerequisites] = useState([]);

    const [newPrerequisite, setNewPrerequisite] = useState('');

    const editable = isSectionEditable('engagementOutcomes');

    useEffect(() => {
        const courseDetail = getCourseDetailByLanguage(course.courseDetailTranslations, selectedLanguage);
        console.log('courseDetail:', courseDetail);

        setOriginalTargets(courseDetail?.targets || []);
        setOriginalSkills(course.skills || []);
        setOriginalLearningAreas(courseDetail?.learningAreas || []);
        setOriginalPrerequisites(courseDetail?.prerequisites || []);

        setEditableTargets(courseDetail?.targets || []);
        setEditableSkills(course.skills || []);
        setEditableLearningAreas(courseDetail?.learningAreas || []);
        setEditablePrerequisites(courseDetail?.prerequisites || []);
    }, [course, selectedLanguage]);

    const getCourseDetailByLanguage = (courseDetailTranslations, selectedLanguage) => {
        if (!courseDetailTranslations || !Array.isArray(courseDetailTranslations)) {
            return null;
        }
        const courseDetail = courseDetailTranslations.find(detail => detail.language === selectedLanguage);
        return courseDetail ? { ...courseDetail, targets: courseDetail.targets || [] } : {};
    };

    // print selected language in course engagement outcomes section
    console.log('selectedLanguage in CourseEngagementOutcomesSection:', selectedLanguage);

    const currentCourseDetail = getCourseDetailByLanguage(course.courseDetailTranslations, selectedLanguage);
    console.log('currentCourseDetail in CourseEngagementOutcomesSection:', currentCourseDetail);



    const handleCancel = () => {
        setEditableTargets(originalTargets);
        setEditableSkills(originalSkills);
        setEditableLearningAreas(originalLearningAreas);
        setEditablePrerequisites(originalPrerequisites);
        onEditSection(null);
    };


    const handleAddTarget = () => {
        setEditableTargets([...editableTargets, { title: '', description: '' }]);
    };

    const handleRemoveTarget = (index) => {
        const newTargets = [...editableTargets];
        newTargets.splice(index, 1);
        setEditableTargets(newTargets);
    };

    const handleTargetChange = (index, field, value) => {
        const newTargets = [...editableTargets];
        newTargets[index][field] = value;
        setEditableTargets(newTargets);
    };


    const handleRemoveSkill = (index) => {
        const newSkills = [...editableSkills];
        newSkills.splice(index, 1);
        setEditableSkills(newSkills);
    };

    const handleAddSkill = () => {
        if (newSkill.trim()) {
            setEditableSkills(prev => [...prev, newSkill]);
            setNewSkill('');
        }
    };

    const handleAddLearningArea = () => {
        if (newLearningArea.trim()) {
            setEditableLearningAreas(prev => [...prev, newLearningArea]);
            setNewLearningArea('');
        }
    };

    const handleRemoveLearningArea = (index) => {
        const newAreas = [...editableLearningAreas];
        newAreas.splice(index, 1);
        setEditableLearningAreas(newAreas);
    };

    const handleAddPrerequisite = () => {
        if (newPrerequisite.trim()) {
            setEditablePrerequisites(prev => [...prev, newPrerequisite]);
            setNewPrerequisite('');
        }
    };

    const handleRemovePrerequisite = (index) => {
        const newPrerequisites = [...editablePrerequisites];
        newPrerequisites.splice(index, 1);
        setEditablePrerequisites(newPrerequisites);
    };

    const handleSave = () => {
        const translationFields = {
            targets: editableTargets,
            learningAreas: editableLearningAreas,
            prerequisites: editablePrerequisites,
        };

        const nonTranslationFields = {
            skills: editableSkills,
        };

        const updatePayload = {
            language: selectedLanguage,
            translationFields,
            nonTranslationFields,
        };

        console.log('Saving course with data:', updatePayload);
        onSaveSection('engagementOutcomes', updatePayload);
    };

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    editable ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: 'common.white',
                                    borderColor: 'common.white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'common.white',
                                    },
                                }}
                                onClick={handleCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <IconButton onClick={() => onEditSection('engagementOutcomes')} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
                title="Engagement and Outcomes"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{ backgroundColor: 'primary.light', color: 'common.white' }}
            />
            <CardContent>    
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <GroupIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Targets
                </Typography>
                {editable ? (
                    <List>
                    {editableTargets.map((target, index) => (
                        <ListItem key={index} disableGutters>
                            <TextField
                                label="Title"
                                fullWidth
                                variant="outlined"
                                value={target.title}
                                onChange={(e) => handleTargetChange(index, 'title', e.target.value)}
                                size="small"
                                sx={{ mr: 1 }}
                            />
                            <TextField
                                label="Description"
                                fullWidth
                                variant="outlined"
                                value={target.description}
                                onChange={(e) => handleTargetChange(index, 'description', e.target.value)}
                                size="small"
                                multiline
                                rows={4}
                                sx={{ mr: 1 }}
                            />
                            <IconButton onClick={() => handleRemoveTarget(index)}>
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </ListItem>
                    ))}
                    <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddTarget}>
                        Add Target
                    </Button>
                </List>    
                ) : (
                    <>
                    {/* // currentCourseDetail?.targets?.map((target, index) => (
                    //     <Box key={index} sx={{ mb: 2 }}>
                    //         <Typography variant="subtitle2" gutterBottom>{target.title}</Typography>
                    //         <Typography variant="body1">{target.description}</Typography>
                    //     </Box>
                    
                    // )) */}
                    {(currentCourseDetail?.targets || []).map((target, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>{target.title}</Typography>
                            <Typography variant="body1">{target.description}</Typography>
                        </Box>
                    ))}
                    </>
                )}

                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt:2, mb:2  }}>
                        <BuildIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                       Skills
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {editableSkills.map((skill, index) => (
                        <Chip
                            key={index}
                            label={skill}
                            onDelete={editable ? () => setEditableSkills(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]) : undefined}
                            color="primary"
                            variant="outlined"
                        />
                    ))}
                </Box>
                {editable && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt:2 }}>
                        <TextField
                            label="New Skill"
                            variant="outlined"
                            size="small"
                            value={newSkill}
                            onChange={(e) => setNewSkill(e.target.value)}
                            sx={{ mr: 1 }}
                        />
                        <Button variant="outlined" onClick={handleAddSkill} startIcon={<AddCircleOutlineIcon />}>
                            Add Skill
                        </Button>
                    </Box>

                )}

                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt:2, mb:2  }}>
                        <ExploreIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                       Learning areas
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {editableLearningAreas.map((area, index) => (
                        <Chip
                            key={index}
                            label={area}
                            onDelete={editable ? () => setEditableLearningAreas(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]) : undefined}
                            color="secondary"
                            variant="outlined"
                        />
                    ))}
                </Box>
                {editable && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt:2 }}>
                        <TextField
                            label="New Learning Area"
                            variant="outlined"
                            size="small"
                            value={newLearningArea}
                            onChange={(e) => setNewLearningArea(e.target.value)}
                            sx={{ mr: 1 }}
                        />
                        <Button variant="outlined" onClick={handleAddLearningArea} startIcon={<AddCircleOutlineIcon />}>
                            Add Learning Area
                        </Button>
                    </Box>
                )}
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <StarBorderIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Prerequisites
                </Typography>
                {editable ? (
                    <>
                        {editablePrerequisites.map((prerequisite, index) => (
                            <Chip
                                key={index}
                                label={prerequisite}
                                onDelete={() => handleRemovePrerequisite(index)}
                                color="primary"
                                variant="outlined"
                                sx={{ mr: 1, mb: 1 }}
                            />
                        ))}
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <TextField
                                label="New Prerequisite"
                                variant="outlined"
                                size="small"
                                value={newPrerequisite}
                                onChange={(e) => setNewPrerequisite(e.target.value)}
                                sx={{ mr: 1 }}
                            />
                            <Button variant="outlined" onClick={handleAddPrerequisite} startIcon={<AddCircleOutlineIcon />}>
                                Add Prerequisite
                            </Button>
                        </Box>
                    </>
                ) : (
                    editablePrerequisites.map((prerequisite, index) => (
                        <Chip key={index} label={prerequisite} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                    ))
                )}

            </CardContent>
        </Card>
    );
};


export default CourseEngagementOutcomesSection;

