import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Container, Typography, Button, Switch, Box } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { CircularProgress } from '@mui/material';


import MydraCategoryList from '../../components_mydra/MydraCategoryList';

const MydraCategoryPage = () => {
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null); // Assuming you want to store the user's email as well
    const [isProduction, setIsProduction] = useState(true); // Assuming default environment is Staging

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Ensure this is defined in your environment variables

    const [categories, setCategories] = useState([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);

    const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryFile, setNewCategoryFile] = useState(null);
    const [newCategoryFileName, setNewCategoryFileName] = useState('');


    // Manage category dialog    
    const [isAddingCategory, setIsAddingCategory] = useState(false);
    const [error, setError] = useState('');
    
    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleAddCategory = async () => {
        setIsAddingCategory(true); // Start loading
        setError(''); // Reset error message
    
        const formData = new FormData();
        formData.append('name', newCategoryName);
        if (newCategoryFile) {
            formData.append('file', newCategoryFile);
        }
    
        try {
            console.log('Adding category:', newCategoryName);
            console.log('File:', newCategoryFileName);
            const response = await fetch(`${API_BASE_URL}/mydra/categories`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
                body: formData,
            });
    
            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.error || 'Failed to add category');
            }
    
            const result = await response.json();
            console.log('Category added:', result);
            fetchCategories(); // Refresh categories list
            handleCloseModal(); // Close the add category dialog
        } catch (error) {
            console.error('Error adding category:', error);
            // clear the file input
            setNewCategoryFile(null);
            setNewCategoryFileName('');
            
            setError(error.message); // Set error message to display
            alert(error.message); // Simple alert, replace with a more sophisticated notification system in production
        } finally {
            setIsAddingCategory(false); // Stop loading
        }
    };

    const handleAddCategoryClick = () => {
        setOpenAddCategoryModal(true);
    };
    
    const handleCloseModal = () => {
        setOpenAddCategoryModal(false);
        setNewCategoryName('');
        setNewCategoryFile(null);
        // clear the file input
        setNewCategoryFileName('');
        setError(''); // Reset error message
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setNewCategoryFile(file);
            setNewCategoryFileName(file.name);
        }
    };


    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                // Handle user not logged in or redirect to login
                console.log('User not logged in');
            }       
        });
    }, []);

    const handleSubcategoryAdded = () => {
        // Logic to refresh categories
        console.log("Subcategory added. Refreshing categories...");
        // You might need to call the API to fetch categories again here
        fetchCategories(); // Assuming fetchCategories is the function that fetches categories
    };


    const fetchCategories = async () => {
        if (!authToken) return; // Ensure authToken is available

        setIsLoadingCategories(true); // Start loading

        try {
            const response = await fetch(`${API_BASE_URL}/mydra/categories`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail, // Custom header for user email
                    'X-Environment': isProduction ? 'Production' : 'Staging', // Custom header for environment
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }

            const result = await response.json();
            console.log('Fetched categories:', result.data); // Logging the fetched categories

            // Sort the categories by name before setting the state
            const sortedCategories = result.data.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

            setCategories(sortedCategories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setIsLoadingCategories(false); // Stop loading
        }  
    };

    useEffect(() => {
        console.log('Fetching categories...');
        
        fetchCategories();
    }, [authToken, userEmail, isProduction]); // Add userEmail and isProduction as dependencies


    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
                Category Management
            </Typography>
            <Button variant="contained" color="primary" onClick={handleAddCategoryClick} sx={{ mb: 2 }}>
                Add Category
            </Button>
            <Box display="flex" alignItems="center" mb={2} ml={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>
            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes impact all live courses.
                </Typography>
            )}

            {isLoadingCategories ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <MydraCategoryList 
                    authToken={authToken} 
                    userEmail={userEmail}
                    isProduction={isProduction}
                    categories={categories} 
                    // onAddSubcategory={handleOpenAddSubcategoryDialog} 
                    onSubcategoryAdded={handleSubcategoryAdded} // Passing the function down
                    />
            )}

    
            {/* Add Category Dialog */}
            <Dialog open={openAddCategoryModal} onClose={handleCloseModal}>
                <DialogTitle>Add New Category</DialogTitle>
                <DialogContent>
                    {/* Warning for production mode */}
                    {isProduction && (
                        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                            Warning: You are in Production mode. Adding a category here will impact all live courses. Please proceed with caution.
                        </Typography>
                    )}
                    
                    {/* Notice about access rights */}
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Note: Only selected users have the rights to add or edit categories and subcategories. Please ensure you have the appropriate permissions.
                    </Typography>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Category Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        placeholder="E.g. 'Artificial Intelligence'" // Add this line for placeholder
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
                        <Typography variant="subtitle1" component="span" sx={{ mr: 2 }}>
                            Category Icon:
                        </Typography>
                        <label htmlFor="upload-file">
                            <input
                                style={{ display: 'none' }}
                                id="upload-file"
                                name="upload-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <Button
                                variant="contained"
                                component="span"
                                sx={{ mt: 0 }}
                            >
                                {newCategoryFileName || 'Upload File'}
                            </Button>
                        </label>
                    </Box>
                    {newCategoryFileName && (
                        <Typography color="primary" sx={{ mt: 2 }}>
                            {newCategoryFileName}
                        </Typography>
                    )}
                    {error && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} disabled={isAddingCategory}>Cancel</Button>
                    <Button onClick={handleAddCategory} disabled={isAddingCategory || !newCategoryName.trim()}>
                        {isAddingCategory ? 'Adding...' : 'Add'}
                    </Button>                
                </DialogActions>
            </Dialog>

            
                        
        </Container>
    );
}

    

            
export default MydraCategoryPage;


