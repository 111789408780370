import React,  { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    Container, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Typography, 
    Avatar, 
    Chip,
    Tooltip, 
    Button,
    CircularProgress, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
    
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';


const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

const TableHeader = ( { onSort, sortAscending }) => (
    <TableHead sx={{ backgroundColor: 'primary.main' }}>
      <TableRow>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>#</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Logo</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
        <TableCell sx={{ 
          color: 'white', 
          fontWeight: 'bold', 
          cursor: 'pointer',
          '&:hover': { color: 'grey.300' }
        }} onClick={onSort}>
          # of courses
          {sortAscending ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
        </TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Country</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Has Agreement</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Is Affiliate</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Created At</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Updated At</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  // const MydraProvidersList = ({ providers, onEditProvider, isLoading, authToken, userEmail, environment, onDeleteSuccess, onDeleteError }) => {
  const MydraProvidersList = ({
      providers, 
      onEditProvider, 
      isLoading, 
      authToken, 
      userEmail, 
      environment, 
      onDeleteSuccess, 
      onDeleteError, 
      setProviders // Accept setProviders as a prop
  }) => {
    const navigate = useNavigate();
    const [sortAscending, setSortAscending] = useState(false);
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [providerToDelete, setProviderToDelete] = useState(null);



    const handleSort = () => {
      setSortAscending(!sortAscending);
    };

    const sortedProviders = [...providers].sort((a, b) => {
      if (sortAscending) {
          return a.totalCourses - b.totalCourses;
      } else {
          return b.totalCourses - a.totalCourses;
      }
  });

  const handleConfirmOpen = (provider) => {
    setProviderToDelete(provider);
    setOpenConfirmDialog(true);
  };
  
  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };
  
  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      deleteProvider(providerToDelete._id);
      handleConfirmClose();
    }
  };

  const deleteProvider = async (providerId) => {
    try {
        // print authtoken
        console.log('authToken:', authToken );

        const response = await fetch(`${REACT_APP_API_BASE_URL}/mydra/education-providers/${providerId}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'X-User-Email': userEmail,
              'X-Environment': environment ? 'Production' : 'Staging',
            },
        });
        if (response.status === 204 || response.status === 200) {
          onDeleteSuccess(`Provider deleted successfully.`);
          // Remove the deleted provider from the list
          const updatedProviders = providers.filter(provider => provider._id !== providerId);
          setProviders(updatedProviders);
        } else {
          throw new Error('Failed to delete provider');
        }
    } catch (error) {
        console.error('Error deleting provider:', error);
        onDeleteError('Failed to delete provider');
    }
  };









    const handleProviderRowClick = (providerId, providerName) => {
        navigate(`/mydra/provider/${providerId}/${providerName}`);
      };

    if (isLoading) {
        return (
          <Container maxWidth="lg" sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Container>
        );
    }


    const handleProviderClick = (providerId, providerName) => {
        navigate(`/mydra/provider/${providerId}/${providerName}`);
    };

    if (!providers || providers.length === 0) {
        return (
          <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="body1">No providers available</Typography>
          </Container>
        );
    }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <TableContainer component={Paper}>
        <Table aria-label="provider table">
          <TableHeader onSort={handleSort} />
            <TableBody>
                {sortedProviders.map((provider, index) => (
                
                <TableRow 
                    key={provider._id}
                    hover
                    onClick={() => handleProviderClick(provider._id, provider.name)}
                    style={{ cursor: 'pointer' }}
                    
                    >
                    <TableCell>{index + 1}</TableCell> {/* Display Counter */}

                    <TableCell>
                    <Avatar alt={provider.name} src={provider.logoUrl} />
                    </TableCell>
                    <TableCell>
                    <Typography variant="subtitle1" component="div">
                        {provider.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {provider.slug}
                    </Typography>
                    </TableCell>
                    <TableCell>{provider.totalCourses}</TableCell>

                    <TableCell>{provider.country}</TableCell>
                    <TableCell>
                    <Chip
                        icon={provider.hasAgreement ? <CheckCircleIcon /> : <CancelIcon />}
                        label={provider.hasAgreement ? 'Yes' : 'No'}
                        color={provider.hasAgreement ? 'success' : 'default'}
                    />
                    </TableCell>
                    <TableCell>
                    <Chip
                        icon={provider.isAffiliate ? <CheckCircleIcon /> : <CancelIcon />}
                        label={provider.isAffiliate ? 'Yes' : 'No'}
                        color={provider.isAffiliate ? 'success' : 'default'}
                    />
                    </TableCell>
                    <TableCell>{formatDate(provider.createdAt)}</TableCell>
                    <TableCell>{formatDate(provider.updatedAt)}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            onEditProvider(provider);
                          }}
                          size="small"
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleConfirmOpen(provider);
                        }}
                        size="small"
                      >
                        <DeleteIcon style={{ color: 'red' }} />
                      </IconButton>
                      </Tooltip>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this provider? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProvider} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MydraProvidersList;
