import React, { useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  Container,
  Typography,
  Switch,
  Box,
  CircularProgress,
  Button,
  Snackbar,
  Alert
} from '@mui/material';
import MydraAgreementsList from '../../components_mydra/MydraAgreementsList/MydraAgreementsList';
import MydraAddEditAgreementModal from '../../components_mydra/MydraAddEditAgreementModal/MydraAddEditAgreementModal';

import AddIcon from '@mui/icons-material/Add';

const MydraAgreementsPage = () => {
  const [agreements, setAgreements] = useState([]);
  const [authToken, setAuthToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [isProduction, setIsProduction] = useState(true);
  const [isLoadingAgreements, setIsLoadingAgreements] = useState(false);
  const [openAddEditAgreementModal, setOpenAddEditAgreementModal] = useState(false);
  const [editAgreement, setEditAgreement] = useState(null);

//   const [selectedAgreement, setSelectedAgreement] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  
  const handleEditAgreement = (agreement) => {
    const editedAgreement = {
        ...agreement,
        startAt: agreement.startAt ? agreement.startAt.slice(0, 10) : '', // Fallback to empty string if undefined
        endAt: agreement.endAt ? agreement.endAt.slice(0, 10) : '', // Fallback to empty string if undefined
    };
    setEditAgreement(editedAgreement);
    setOpenAddEditAgreementModal(true);
  };

  const handleUpdateAgreement = async (updatedAgreement) => {
    console.log('Updating agreement:', updatedAgreement);
    const agreementId = updatedAgreement.agreementId; // Assuming the agreement ID is stored in _id

    if (!agreementId) {
      console.error('Agreement ID is undefined');
      showSnackbar('Failed to update agreement', 'error');
      return;
  } 

    const transformedData = {
      agreementId: updatedAgreement._id, // Ensure correct ID is passed
      name: updatedAgreement.name,
      contractType: updatedAgreement.contractType,
      fees: updatedAgreement.fees,
      startAt: updatedAgreement.startAt,
      status: updatedAgreement.status,
      contractUrl: updatedAgreement.contractUrl,
      emailForNotifications: updatedAgreement.emailForNotifications, // Include emailForNotifications
      educationProviderId: updatedAgreement.educationProvider._id,
      dropoutPolicy: updatedAgreement.dropoutPolicy,
      vat: updatedAgreement.vat,
      assignToAllCourses: updatedAgreement.assignToAllCourses // Ensure assignToAllCourses is included
    };
  
    try {
      const response = await fetch(`${API_BASE_URL}/mydra/education-provider-agreements/${agreementId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
          'X-User-Email': userEmail,
          'X-Environment': isProduction ? 'Production' : 'Staging',
        },
        // body: JSON.stringify(updatedAgreement),
        body: JSON.stringify(transformedData), // Send the transformed data
      });
  
      if (response.ok) {
        const updated_agreement_data = await response.json();
        console.log('Agreement updated:', updated_agreement_data);
        
        // Update the local state
        setAgreements(agreements.map(agreement => 
          agreement._id === agreementId ? updated_agreement_data.agreement.data : agreement
        ));
  
        showSnackbar('Agreement updated successfully', 'success');
        handleCloseModal(); // Close the modal after update
      } else {
        console.error('Failed to update agreement:', response.statusText);
        showSnackbar('Failed to update agreement', 'error');
      }
    } catch (error) {
      console.error('Error updating agreement:', error);
      showSnackbar('Failed to update agreement', 'error');
    }
  };

  const handleAssignToAllCourses = (agreement) => {
    // Set the assignToAllCourses flag to true and call handleEditAgreement
    const updatedAgreement = { ...agreement, assignToAllCourses: true };
    // include the agreementId in the updatedAgreement object
    updatedAgreement.agreementId = agreement._id;
    handleUpdateAgreement(updatedAgreement);
};

  

  // Function to add a new agreement
    const handleAddAgreement = async (newAgreementData) => {
        console.log('Adding new agreement:', newAgreementData);
        const { educationProvider, ...dataForBackend } = newAgreementData;

        try {
          const response = await fetch(`${API_BASE_URL}/mydra/education-provider-agreements`, {
              method: 'POST',
              headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
              'X-User-Email': userEmail,
              'X-Environment': isProduction ? 'Production' : 'Staging',
              },
              body: JSON.stringify(dataForBackend), // Convert the new agreement data to JSON
          });

          if (response.ok) {
              const newAgreementResponse = await response.json();
              console.log('New agreement added:', newAgreementResponse);

              // Reconstruct the full agreement data including the education provider details
              const fullNewAgreementData = {
                ...newAgreementResponse.agreement.data,
                educationProvider, // Add the education provider details back
              };

              setAgreements(agreements => [...agreements, fullNewAgreementData]);

              showSnackbar('Agreement added successfully', 'success');
          } else {
              console.error('Failed to add new agreement:', response.statusText);
              showSnackbar('Failed to add new agreement', 'error');
          }
        } catch (error) {
        console.error('Error adding new agreement:', error);
        showSnackbar('Failed to add new agreement', 'error');
        }
    };



    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
        if (user) {
            user.getIdToken().then((token) => {
            setAuthToken(token);
            setUserEmail(user.email);
            });
        } else {
            console.log('User not logged in');
        }
        });
    }, []);

    

    const fetchAgreements = useCallback(async () => {
        setIsLoadingAgreements(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/education-provider-agreements`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
        
            if (response.ok) {
                const data = await response.json();
                console.log('Agreements fetched:', data);
                setAgreements(data.data);
            } else {
                showSnackbar('Failed to fetch agreements', 'error');
            }
        }
        catch (error) {
            console.error('Error fetching agreements:', error);
            showSnackbar('Failed to fetch agreements', 'error');
        }
        finally {
            setIsLoadingAgreements(false);
        }
    }, [authToken, userEmail, isProduction, API_BASE_URL]);
    
        
    useEffect(() => {
        // Fetch education provider agreements data from the backend API
        if (authToken && userEmail) {
            console.log('Fetching education provider agreements');
            fetchAgreements();
        }
    }, [authToken, userEmail, fetchAgreements]);


    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleOpenModal = () => {
        setOpenAddEditAgreementModal(true);
    };

    const handleCloseModal = () => {
        setOpenAddEditAgreementModal(false);
        setEditAgreement(null);
    };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
        Education Provider Agreements
      </Typography>
      <Button variant="contained" startIcon={<AddIcon />} onClick={ handleOpenModal} sx={{ mb: 2 }}>
        Add Agreement
      </Button>

      <Box display="flex" alignItems="center" mb={2} ml={2}>
        <Typography variant="body1">Staging</Typography>
        <Switch checked={isProduction} onChange={toggleEnvironment} inputProps={{ 'aria-label': 'Environment toggle' }} />
        <Typography variant="body1">Production</Typography>
      </Box>

      {isProduction && (
        <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
          Warning: You are in Production mode. Changes may impact all live courses.
        </Typography>
      )}

      {authToken && userEmail ? (
        isLoadingAgreements ? (
          <Box display="flex" justifyContent="center" mt={5}>
            <CircularProgress />
          </Box>
        ) : (
          <MydraAgreementsList 
            agreements={agreements} 
            onEditAgreement={handleEditAgreement} 
            isLoading={isLoadingAgreements} 
            onAssignToAllCourses={handleAssignToAllCourses} // Pass the new handler

            />
        )
      ) : (
        <Typography variant="body1">Please log in to view the agreements.</Typography>
      )}

      <MydraAddEditAgreementModal
        open={openAddEditAgreementModal}
        onClose={handleCloseModal}
        onAdd={handleAddAgreement}
        onUpdate={handleUpdateAgreement}
        authToken={authToken}
        userEmail={userEmail}
        isProduction={isProduction}  
        agreement={editAgreement}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MydraAgreementsPage;


