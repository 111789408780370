import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Grid, CircularProgress, Box, Avatar, Container, LinearProgress,  } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useTheme } from '@mui/material/styles'; // Import useTheme hook
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'; // More specific back icon
import ScoreExplanation from '../ScoreExplanation';
import ScoreList from '../ScoreList/ScoreList';
import { getAuth, onAuthStateChanged } from 'firebase/auth';




const EPScores = () => {
    const [scores, setScores] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate(); // Initialize the navigate function
    const theme = useTheme(); // Access theme for consistent styling
    // Find the highest score to set as a benchmark for 100% progress bar
    const highestScore = Math.max(...scores.map(ep => ep.score));

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    // const API_BASE_URL = 'http://localhost:8080';

    const getToken = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            return await user.getIdToken();
        } else {
            console.log('User not logged in');
            navigate('/login'); // Redirect to login page if user is not logged in
            return null;
        }
    };
    
    useEffect(() => {
        fetchScores();
    }, []);


    const fetchScores = async () => {
        setIsLoading(true);
        const token = await getToken();
        if (!token) {
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/get-scores`, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });    
        
            if (!response.ok) {
                throw new Error('Failed to fetch scores');
            }
            const data = await response.json();
            console.log('Scores:', data);
    
            // Sort the scores in descending order based on the score property
            const sortedScores = data.sort((a, b) => b.score - a.score);
    
            setScores(sortedScores); // Set the sorted scores to state
        } catch (error) {
            console.error('Error fetching scores:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateScores = async () => {
        setIsLoading(true);

        const token = await getToken();
        if (!token) {
            return;
        }
        try {
            const response = await fetch(`${API_BASE_URL}/update-ep-scores`, { 
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }); // Assuming the endpoint is accessible via a GET request
            if (!response.ok) {
                throw new Error('Failed to update scores');
            }
            fetchScores(); // Re-fetch scores after updating
        } catch (error) {
            console.error('Error updating scores:', error);
            setIsLoading(false); // Ensure loading state is reset even if there's an error
        }
    };

    const handleProviderDetails = (site_id) => {
        navigate(`/provider/${site_id}`); // Navigate to the provider details route
    };

      // Function to navigate back to the previous page
      const handleBack = () => {
        navigate(-1);
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ mt: 2 }}>
            <Button
                variant="outlined" // Outlined button style
                startIcon={<ArrowBackIosNewIcon />} // Icon at the start
                onClick={handleBack}
                sx={{
                    mb: 2, // Margin bottom to space it from the title area
                    color: theme.palette.secondary.main, // Button text color
                    borderColor: theme.palette.secondary.light, // Button border color
                    position: 'relative', // Absolutely positioned relative to the parent Box
                    top: 0, left: 0, // Positioned at the top-left corner of the parent Box
                }}
            >
                Go Back
            </Button>

            <Container component="main" maxWidth="lg" sx={{ mt: 2}}>
                <Box sx={{
                    p: 2, // Padding inside the box
                    bgcolor: theme.palette.background.default, // Background color from the theme
                    boxShadow: theme.shadows[2], // Box shadow for depth
                    borderRadius: theme.shape.borderRadius, // Rounded corners
                    textAlign: 'center', // Center the title text
                    mb: 4, // Margin bottom for spacing between the title box and content
                }}>
                    <Typography variant="h4" component="h1" sx={{
                        fontWeight: 'bold', // Make the title bold
                        color: theme.palette.primary.main, // Use the primary color
                    }}>
                        Education Providers Scores
                    </Typography>
                </Box>

            </Container>

            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <ScoreList scores={scores} handleProviderDetails={handleProviderDetails} highestScore={highestScore} theme={theme} />
                    <Button variant="contained" onClick={updateScores} disabled={isLoading} sx={{ mt: 2 }}>
                        Update Scores
                    </Button>
                    <ScoreExplanation />
                </>
            )}
        </Container>
        
    );
};





export default EPScores;


