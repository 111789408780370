import React, {useState, useEffect} from 'react';
import { 
    Container,
    Snackbar, 
    Alert,

} from '@mui/material';

import CourseInformationSection from '../CourseInformationSection/CourseInformationSection';
import CourseCategorySection from '../CourseCategorySection/CourseCategorySection';
import CourseContentDescriptionSection from '../CourseContentDescriptionSection/CourseContentDescriptionSection';
import CourseValidationAgreementSection from '../CourseValidationAgreementSection/CourseValidationAgreementSection';
import CourseEngagementOutcomesSection from '../CourseEngagementOutcomesSection/CourseEngagementOutcomesSection';

import CourseInstructorsSection from '../CourseInstructorsSection/CourseInstructorsSection';
import CourseTestimonialsSection from '../CourseTestimonialsSection/CourseTestimonialsSection';
import CourseAdditionalResourcesSection from '../CourseAdditionalResourcesSection/CourseAdditionalResourcesSection';
import CourseAdministrativeDetailsSection from '../CourseAdministrativeDetailsSection/CourseAdministrativeDetailsSection';
import CourseMotivationSection from '../CourseMotivationSection';

const MydraCourse = ({ key, course, authToken, userEmail, isProduction, onCourseUpdate, selectedLanguage }) => {

    const courseId = course._id;

    const [editedCourse, setEditedCourse] = useState(course); // Assuming course data is passed as a prop
    const [editingSection, setEditingSection] = useState(null);
    const [updatedCourseData, setUpdatedCourseData] = useState(course);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Ensure this is defined in your environment variables

    // Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // This effect will run whenever the `course` prop changes
        setUpdatedCourseData(course);
    }, [course]); // Dependency array with `course` to listen for its changes

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (!course) return <div>No course data available</div>;

    const handleSectionUpdate = async (section, newSectionData) => {
        // Update the local state with the new section data
        setIsSaving(true); // Set isSaving to true to show "Saving..." on the button

        console.log('Received section to update:', section); // Log the section being updated
        console.log('Received new data for section:', newSectionData); // Log the new data for the section
    
        let payload;

        if (    section === 'courseInformation' || 
                section === 'validationAgreement' || 
                section === 'contentDescription' || 
                section === 'motivation' ||
                section === 'engagementOutcomes' || 
                section === 'administrativeDetails' || 
                section === 'additionalResources' || 
                section === 'testimonials' ||
                section === 'categorySelection' ||
                section === 'courseMotivation' ||
                section === 'instructors' 
            ) {
                 
            // Directly use newSectionData without wrapping it in 'courseInformation'M
            payload = { ...newSectionData };
        } else {
            payload = { [section]: newSectionData };
        }

        // Check if the 'category' section is being updated, then include 'categoryId'
        if (section === 'category' && updatedCourseData.category && updatedCourseData.category._id) {
            payload.categoryId = updatedCourseData.category._id;
        }

        // Merge the updated section data with the existing course data
        const newCourseData = { ...updatedCourseData, ...payload };
        setUpdatedCourseData(newCourseData);
        console.log('Updating section:', section, 'with data:', payload);
        
        // Save the updated course data to the backend
        try {
            console.log('Updating course wtih id:', courseId);
            console.log('Updated course data:', payload);

            const response = await fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                method: 'PUT',
                headers: {
                  'Authorization': `Bearer ${authToken}`, // Assuming you're using a Bearer token for authentication
                  'Content-Type': 'application/json',
                  'X-User-Email': userEmail, // Use the email parameter
                  'X-Environment': isProduction ? 'Production' : 'Staging',
                },
                body: JSON.stringify(payload),
            });
                if (response.ok) {
                    // Handle successful response
                    const responseData = await response.json();
                    console.log('Section updated successfully:', responseData);
                    // setEditedCourse(newCourseData); // Update the local state with the updated course data
                
                    setEditedCourse(responseData['data']); // Update the local state with the updated course data
                    onCourseUpdate(responseData['data']); // Call the callback with the updated course data

                    // After successful update
                    setSnackbarMessage('Changes saved successfully!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true); // Open the snackbar with the success message

                    // Optionally show a success message to the user
                } else {
                    // Handle errors
                    console.error('Failed to update section:', response.statusText);
                    // Optionally show an error message to the user
                    setSnackbarMessage('Failed to save changes.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true); // Open the snackbar with the error message
                }

                setIsSaving(false); // Reset isSaving to false after the operation
                setEditingSection(null); // Reset the editing section

            } catch (error) {
                console.error('Error updating section:', error);
                // Optionally show an error message to the user
            }
    };


    const handleEditSection = (section) => {
        setEditingSection(section);
    };

    const isSectionEditable = (section) => {
        return editingSection === section;
    };

    return (
        <Container maxWidth="lg" disableGutters={true}>       
            {/* CourseInformationSection */}
            <CourseInformationSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />    
            {/* CourseCategorySection */}
            <CourseCategorySection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
                apiBaseUrl={API_BASE_URL} // Assuming this is defined somewhere in your parent component
                authToken={authToken} // Pass authToken as a prop
                userEmail={userEmail} // Pass userEmail as a prop
                isProduction={isProduction} // Pass isProduction as a prop
            />
            <CourseValidationAgreementSection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
                authToken={authToken} // Pass authToken as a prop
                userEmail={userEmail} // Pass userEmail as a prop
                isProduction={isProduction} // Pass isProduction as a prop
            />

            <CourseContentDescriptionSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <CourseMotivationSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />  

            <CourseEngagementOutcomesSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <CourseInstructorsSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}                
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
                authToken={authToken} // Pass authToken as a prop
                userEmail={userEmail} // Pass userEmail as a prop
                isProduction={isProduction} // Pass isProduction as a prop
                onCourseUpdate={(isInstructorUpdate) => onCourseUpdate(null, isInstructorUpdate)} // Pass isInstructorUpdate as a parameter                
            />

            <CourseTestimonialsSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <CourseAdditionalResourcesSection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <CourseAdministrativeDetailsSection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            {/* Snackb */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
    
        </Container>
    );

}

export default MydraCourse;