import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Box } from '@mui/material';

const QualityScoreExplanationModal = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Quality Score Explanation</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography paragraph>
            The Quality Score is calculated based on the presence or absence of certain elements. 
            Points are awarded when an element is present. The total score is the sum of these points.
          </Typography>
          <Typography variant="h6" gutterBottom>Scoring Criteria:</Typography>
          <Box component="ul" sx={{ pl: 2 }}>
            <li><Typography><strong>Upcoming Cohorts:</strong> 10 points if present, 0 if absent</Typography></li>
            <li><Typography><strong>Agreement:</strong> 10 points if present, 0 if absent</Typography></li>
            <li><Typography><strong>Price:</strong> 10 points if present, 0 if absent</Typography></li>
            <li><Typography><strong>All LinkedIn Profiles have Images:</strong> 2 points if all profiles have images, 0 if any are missing</Typography></li>
          </Box>
          <Typography paragraph sx={{ mt: 2 }}>
            <strong>Score Calculation:</strong> Sum of all points awarded
          </Typography>
          <Typography>
            <strong>Minimum Score:</strong> 0 (if all elements are absent)
          </Typography>
          <Typography>
            <strong>Maximum Score:</strong> 32 (if all elements are present)
          </Typography>
          <Typography paragraph sx={{ mt: 2 }}>
            The final score is then normalized to a percentage scale from 0% to 100%:
          </Typography>
          <Typography component="div" sx={{ pl: 2 }}>
            <Box component="pre" sx={{ backgroundColor: '#f5f5f5', p: 1, borderRadius: 1 }}>
              Normalized Score (%) = (Raw Score / Maximum Score) * 100
            </Box>
          </Typography>
          <Typography paragraph sx={{ mt: 2 }}>
            This normalization ensures that:
          </Typography>
          <Box component="ul" sx={{ pl: 2 }}>
            <li><Typography>A raw score of 0 corresponds to 0%</Typography></li>
            <li><Typography>A raw score of 32 (the current maximum) corresponds to 100%</Typography></li>
            <li><Typography>All other scores are linearly distributed between these extremes</Typography></li>
          </Box>
          <Typography paragraph sx={{ mt: 2 }}>
            A higher percentage indicates a more complete and higher quality course listing. This normalized score allows for easy comparison between courses and provides a clear indication of the listing's completeness relative to the ideal (100%) state.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QualityScoreExplanationModal;
