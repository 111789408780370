

import React, { useState } from 'react';
import InputModule from '../../components_upskilling_framework/InputModule';
import OutputModule from '../../components_upskilling_framework/OutputModule';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const HighlightedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));


const AIUpskillingPage = () => {
  const [userData, setUserData] = useState(null);
  const [showOutput, setShowOutput] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


  const handleFormSubmit = async (formData) => {
      console.log('Form Data Submitted:', formData);
      try {
          const endpoint = `${API_BASE_URL}/generate_upskilling_path`;
          // Make a POST request to the Flask endpoint
      const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Parse the JSON response
        const upskillingPath = await response.json();
        console.log('Received upskilling path:', upskillingPath);
        // Here you can set the received upskilling path to your state and show the output component
        setUserData(upskillingPath); // Assuming you will use the response to set user data
        setShowOutput(true); // Show the output component with the received upskilling path
      } catch (error) {
        console.error('Error submitting form data:', error);
        // Handle errors here, such as showing an error message to the user
      }
  };



  return (
    <Container maxWidth="md">
      <Box textAlign="center" my={2}> {/* Reduce the top and bottom margin */}
        <Paper elevation={4} sx={{ display: 'inline-block', padding: 2 }}>
          <HighlightedTypography variant="h3" component="h1">
            AI Upskilling Tool
          </HighlightedTypography>
        </Paper>
      </Box>
      {!showOutput ? (
        <InputModule onSubmit={handleFormSubmit} />
      ) : (
        <Box mt={5}>
          {showOutput && <OutputModule upskillingPath={userData} />}
        </Box>
      )}
    </Container>
  );
};

export default AIUpskillingPage;

