import { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
    Container,
    Typography,
    Switch,
    Box,
    CircularProgress,
    Button,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Tooltip,
    Drawer,
    Grid,
    Link,
    List,
    ListItem,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const MydraBenefitProvidersPage = () => {
    const [benefitProviders, setBenefitProviders] = useState([]);
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddingProvider, setIsAddingProvider] = useState(false);
    const [isAddingAdmin, setIsAddingAdmin] = useState(false);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedProviderData, setEditedProviderData] = useState({});
    const [addProviderDialogOpen, setAddProviderDialogOpen] = useState(false);
    const [newProviderData, setNewProviderData] = useState({
        name: '',
        legalName: '',
        address: '',
        domain: '',
        logoUrl: '',
        adminUsers: [],
    });
    const [formErrors, setFormErrors] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [providerToDelete, setProviderToDelete] = useState(null);
    const [newAdminUser, setNewAdminUser] = useState({ firstName: '', lastName: '', email: '' });
    const [isLoadingLinks, setIsLoadingLinks] = useState(false);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
    const [companyDetails, setCompanyDetails] = useState({});
    const [providerLinks, setProviderLinks] = useState([]);
    const [isLoadingCompanyDetails, setIsLoadingCompanyDetails] = useState({});


    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    

    const showSnackbar = useCallback((message, severity) => {
        setSnackbar({ open: true, message, severity });
    }, []);

    


    const fetchBenefitProviders = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch benefit providers');
            }
            const data = await response.json();
            setBenefitProviders(data);
        } catch (error) {
            console.error('Error fetching benefit providers:', error);
            showSnackbar('Failed to fetch benefit providers', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [authToken, userEmail, isProduction, showSnackbar, API_BASE_URL]);

    useEffect(() => {
        if (authToken && userEmail) {
            fetchBenefitProviders();
        }
    }, [authToken, userEmail, isProduction, fetchBenefitProviders]);



    const handleRowClick = async (provider) => {
        setSelectedProvider(provider);
        setDrawerOpen(true);
        setProviderLinks([]); // Reset provider links
        setCompanyDetails({}); // Reset company details
        await fetchProviderLinks(provider._id);
    };

    const fetchProviderLinks = async (providerId) => {
        console.log("Fetching links for provider ID:", providerId);
        setIsLoadingLinks(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers/company-links?taxBenefitsProvider=${providerId}&include=company`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch provider links');
            }
            const data = await response.json();
            console.log('Provider links fetched:', data);
            setProviderLinks(data);
            
            // Immediately fetch company details for each link
            await Promise.all(data.map(link => fetchCompanyDetails(link.company)));
        } catch (error) {
            console.error('Error fetching provider links:', error);
            showSnackbar('Failed to fetch provider links', 'error');
        } finally {
            setIsLoadingLinks(false);
        }
    };

    


    const fetchCompanyDetails = useCallback(async (companyId) => {
        setIsLoadingCompanyDetails(prev => ({ ...prev, [companyId]: true }));
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/companies/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch company details for ID: ${companyId}`);
            }
            const data = await response.json();
            console.log(`Company details for ID ${companyId}:`, data);
            setCompanyDetails(prev => ({ ...prev, [companyId]: data }));
        } catch (error) {
            console.error(`Error fetching company details for ID ${companyId}:`, error);
            showSnackbar(`Failed to fetch details for company ${companyId}`, 'error');
        } finally {
            setIsLoadingCompanyDetails(prev => ({ ...prev, [companyId]: false }));
        }
    }, [API_BASE_URL, authToken, userEmail, isProduction, showSnackbar]);
    

    const getStatusColor = (status) => {
        switch (status) {
            case 'ACCEPTED': return 'success';
            case 'INVITED': return 'warning';
            case 'CANCELED': return 'error';
            default: return 'default';
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedProvider(null);
        setIsEditing(false);
        setEditedProviderData({});
    };

    const handleEdit = () => {
        setIsEditing(true);
        setEditedProviderData({ ...selectedProvider });
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedProviderData({});
    };

   

    const handleSaveProvider = async () => {
        try {
            // Filter out unnecessary fields and format adminUsers
            const updateData = {
                name: editedProviderData.name,
                legalName: editedProviderData.legalName,
                address: editedProviderData.address,
                domain: editedProviderData.domain,
                logoUrl: editedProviderData.logoUrl,
                // Only send adminUsers if it has been changed
                ...(editedProviderData.adminUsers && {
                    adminUsers: editedProviderData.adminUsers.map(user => user._id)
                })
            };
    
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers/${selectedProvider._id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to update benefit provider');
            }
    
            const updatedProvider = await response.json();
            setBenefitProviders(benefitProviders.map(provider => 
                provider._id === updatedProvider._id ? updatedProvider : provider
            ));
            setSelectedProvider(updatedProvider);
            setIsEditing(false);
            showSnackbar('Benefit provider updated successfully', 'success');
        } catch (error) {
            console.error('Error updating benefit provider:', error);
            showSnackbar('Failed to update benefit provider', 'error');
        }
    };

    const handleAddProvider = async () => {
        setIsAddingProvider(true);
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            // Handle errors, maybe set them to state and display them
            console.log(errors);
            setIsAddingProvider(false);
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newProviderData),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Provider created:', data);
                // Handle successful creation (e.g., close dialog, refresh list)
                setSnackbar({ open: true, message: 'Provider created successfully', severity: 'success' });
                setIsAddingProvider(false);
                setAddProviderDialogOpen(false);
                fetchBenefitProviders();
                
            } else {
                const errorData = await response.json();
                console.error('Failed to create provider:', errorData);
                setSnackbar({ open: true, message: 'Failed to create provider', severity: 'error' });
                setIsAddingProvider(false);
                setAddProviderDialogOpen(false);
            }
        } catch (error) {
            console.error('Error creating provider:', error);
            setSnackbar({ open: true, message: 'Failed to create provider', severity: 'error' });
            setIsAddingProvider(false);
            setAddProviderDialogOpen(false);
        }
    };

    
    const handleDeleteProvider = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers/${providerToDelete._id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete benefit provider');
            }

            setBenefitProviders(benefitProviders.filter(provider => provider._id !== providerToDelete._id));
            setDeleteDialogOpen(false);
            setProviderToDelete(null);
            showSnackbar('Benefit provider deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting benefit provider:', error);
            showSnackbar('Failed to delete benefit provider', 'error');
        }
    };

    const handleAddAdminUser = async () => {
        if (!newAdminUser.firstName || !newAdminUser.lastName || !newAdminUser.email) {
            showSnackbar('Please fill in all fields for the new admin user', 'error');
            return;
        }

        setIsAddingAdmin(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers/${selectedProvider._id}/admin-users`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newAdminUser),
            });

            if (!response.ok) {
                throw new Error('Failed to add admin user');
            }

            const updatedProvider = await response.json();
            setSelectedProvider(updatedProvider);
            setBenefitProviders(benefitProviders.map(provider => 
                provider._id === updatedProvider._id ? updatedProvider : provider
            ));
            setNewAdminUser({ firstName: '', lastName: '', email: '' });
            showSnackbar('Admin user added successfully', 'success');
            
            // Refetch the provider data to ensure we have the most up-to-date information
            await fetchProviderDetails(selectedProvider._id);
        } catch (error) {
            console.error('Error adding admin user:', error);
            showSnackbar('Failed to add admin user', 'error');
        } finally {
            setIsAddingAdmin(false);
        }
    };

    const fetchProviderDetails = async (providerId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers/${providerId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch provider details');
            }

            const providerData = await response.json();
            setSelectedProvider(providerData);
            setBenefitProviders(benefitProviders.map(provider => 
                provider._id === providerData._id ? providerData : provider
            ));
        } catch (error) {
            console.error('Error fetching provider details:', error);
            showSnackbar('Failed to fetch updated provider details', 'error');
        }
    };

    const handleRemoveAdminUser = async (userId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tax-benefit-providers/${selectedProvider._id}/admin-users/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to remove admin user');
            }

            const updatedProvider = await response.json();
            setSelectedProvider(updatedProvider);
            setBenefitProviders(benefitProviders.map(provider => 
                provider._id === updatedProvider._id ? updatedProvider : provider
            ));
            showSnackbar('Admin user removed successfully', 'success');
        } catch (error) {
            console.error('Error removing admin user:', error);
            showSnackbar('Failed to remove admin user', 'error');
        }
    };

    const fetchUsers = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/users`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (response.ok) {
                const users = await response.json();
                console.log('Users fetched:', users);   
                // setAvailableUsers(users);
            } else {
                console.error('Failed to fetch users');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, [API_BASE_URL, authToken, userEmail, isProduction]);



    useEffect(() => {
        if (authToken && userEmail) {
            fetchUsers();
        }
    }, [authToken, userEmail, isProduction, fetchUsers]);

    

    const handleAddAdmin = () => {
        setNewProviderData(prevData => ({
            ...prevData,
            adminUsers: [
                ...prevData.adminUsers,
                { firstName: '', lastName: '', email: '' }
            ],
        }));
    };

    const handleRemoveAdmin = (index) => {
        setNewProviderData(prevData => ({
            ...prevData,
            adminUsers: prevData.adminUsers.filter((_, i) => i !== index),
        }));
    };


    const handleAdminInputChange = (index, field, value) => {
        setNewProviderData(prevData => ({
            ...prevData,
            adminUsers: prevData.adminUsers.map((admin, i) => 
                i === index ? { ...admin, [field]: value } : admin
            ),
        }));
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProviderData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };




    const validateForm = () => {
        const errors = {};
        if (!newProviderData.name) errors.name = 'Name is required';
        if (!newProviderData.legalName) errors.legalName = 'Legal Name is required';
        if (!newProviderData.address) errors.address = 'Address is required';
        if (!newProviderData.domain) errors.domain = 'Domain is required';
        if (newProviderData.adminUsers.length === 0) {
            errors.adminUsers = 'At least one admin user is required';
        } else {
            newProviderData.adminUsers.forEach((admin, index) => {
                if (!admin.firstName) errors[`adminFirstName${index}`] = 'First name is required';
                if (!admin.lastName) errors[`adminLastName${index}`] = 'Last name is required';
                if (!admin.email) errors[`adminEmail${index}`] = 'Email is required';
                // You might want to add email format validation here
            });
        }
        return errors;
    };

    
    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const renderHeaderCell = (label, tooltip = null) => (
        <TableCell
            key={label}
            sx={{
                fontWeight: 600,
                color: '#1a237e',
                fontSize: '0.9rem',
                padding: '14px 20px',
                letterSpacing: '0.3px',
            }}
        >
            {label}
            {tooltip && (
                <Tooltip title={tooltip} arrow placement="top">
                    <InfoIcon
                        fontSize="small"
                        sx={{
                            marginLeft: 1,
                            verticalAlign: 'middle',
                            cursor: 'help',
                            color: '#3f51b5',
                        }}
                    />
                </Tooltip>
            )}
        </TableCell>
    );

    const headerCells = [
        { label: '#', tooltip: null },
        { label: 'Name', tooltip: null },
        { label: 'Legal Name', tooltip: null },
        { label: 'Domain', tooltip: null },
        { label: 'Address', tooltip: null },
        { label: 'Created At', tooltip: null },
        { label: 'Actions', tooltip: null },
    ];

    // const renderAdminUsers = (adminUsers) => {
    //     if (!adminUsers || adminUsers.length === 0) {
    //         return <Typography>No admin users assigned</Typography>;
    //     }

    //     return (
    //         <Box>
    //             {adminUsers.map((user, index) => (
    //                 <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
    //                     <PersonIcon sx={{ mr: 1 }} />
    //                     <Typography>
    //                         {user.firstName} {user.lastName} ({user.email})
    //                     </Typography>
    //                 </Box>
    //             ))}
    //         </Box>
    //     );
    // };
    

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            <Container maxWidth={false} sx={{ mt: 4, px: { xs: 1, sm: 2, md: 3 } }}>
                <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
                    Tax Benefit Providers
                </Typography>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1">Staging</Typography>
                        <Switch
                            checked={isProduction}
                            onChange={toggleEnvironment}
                            inputProps={{ 'aria-label': 'Environment toggle' }}
                        />
                        <Typography variant="body1">Production</Typography>
                    </Box>
                    
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setAddProviderDialogOpen(true)}
                    >
                        Add Provider
                    </Button>
                </Box>

                {isProduction && (
                    <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                        Warning: You are in Production mode. Changes may impact live data.
                    </Typography>
                )}

                {isLoading ? (
                    <Box display="flex" justifyContent="center" mt={5}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{
                                    backgroundColor: '#f0f4f8',
                                    borderBottom: '2px solid #3f51b5',
                                }}>
                                    {headerCells.map(cell => renderHeaderCell(cell.label, cell.tooltip))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {benefitProviders.map((provider, index) => (
                                    <TableRow
                                        key={provider._id}
                                        onClick={() => handleRowClick(provider)}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                        }}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{provider.name}</TableCell>
                                        <TableCell>{provider.legalName}</TableCell>
                                        <TableCell>{provider.domain}</TableCell>
                                        <TableCell>{provider.address}</TableCell>
                                        <TableCell>{new Date(provider.createdAt).toLocaleDateString()}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setProviderToDelete(provider);
                                                    setDeleteDialogOpen(true);
                                                }}
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleCloseDrawer}
                    PaperProps={{
                        sx: { width: { xs: '100%', sm: 600 } }  // Increased width to accommodate more information
                    }}
                >
                    <Box sx={{ p: 3 }}>
                        {selectedProvider && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Provider Details
                                    {!isEditing ? (
                                        <IconButton onClick={handleEdit} size="small" sx={{ ml: 1 }}>
                                            <EditIcon />
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton onClick={handleSaveProvider} size="small" sx={{ ml: 1 }}>
                                                <SaveIcon />
                                            </IconButton>
                                            <IconButton onClick={handleCancelEdit} size="small">
                                                <CancelIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Name</Typography>
                                        {isEditing ? (
                                            <TextField
                                                fullWidth
                                                name="name"
                                                value={editedProviderData.name || ''}
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <Typography>{selectedProvider.name}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Legal Name</Typography>
                                        {isEditing ? (
                                            <TextField
                                                fullWidth
                                                name="legalName"
                                                value={editedProviderData.legalName || ''}
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <Typography>{selectedProvider.legalName}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Domain</Typography>
                                        {isEditing ? (
                                            <TextField
                                                fullWidth
                                                name="domain"
                                                value={editedProviderData.domain || ''}
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <Link href={`https://${selectedProvider.domain}`} target="_blank" rel="noopener noreferrer">
                                                {selectedProvider.domain}
                                            </Link>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Address</Typography>
                                        {isEditing ? (
                                            <TextField
                                                fullWidth
                                                name="address"
                                                value={editedProviderData.address || ''}
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <Typography>{selectedProvider.address}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Logo URL</Typography>
                                        {isEditing ? (
                                            <TextField
                                                fullWidth
                                                name="logoUrl"
                                                value={editedProviderData.logoUrl || ''}
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <Typography>{selectedProvider.logoUrl || 'N/A'}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Created At</Typography>
                                        <Typography>{new Date(selectedProvider.createdAt).toLocaleString()}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">Admin Users</Typography>
                                        {selectedProvider.adminUsers && selectedProvider.adminUsers.map((user) => (
                                            <Box key={user._id} display="flex" alignItems="center" mb={1}>
                                                <Typography>{user.firstName} {user.lastName} ({user.email})</Typography>
                                                <IconButton onClick={() => handleRemoveAdminUser(user._id)} size="small">
                                                    {/* <RemoveIcon /> */}
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        ))}
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="add-admin-content"
                                                id="add-admin-header"
                                            >
                                                <Typography>Add New Admin User</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box>
                                                    <TextField
                                                        label="First Name"
                                                        value={newAdminUser.firstName}
                                                        onChange={(e) => setNewAdminUser({...newAdminUser, firstName: e.target.value})}
                                                        size="small"
                                                        sx={{ mr: 1, mb: 1 }}
                                                    />
                                                    <TextField
                                                        label="Last Name"
                                                        value={newAdminUser.lastName}
                                                        onChange={(e) => setNewAdminUser({...newAdminUser, lastName: e.target.value})}
                                                        size="small"
                                                        sx={{ mr: 1, mb: 1 }}
                                                    />
                                                    <TextField
                                                        label="Email"
                                                        value={newAdminUser.email}
                                                        onChange={(e) => setNewAdminUser({...newAdminUser, email: e.target.value})}
                                                        size="small"
                                                        sx={{ mr: 1, mb: 1 }}
                                                    />
                                                    <Button
                                                        startIcon={isAddingAdmin ? <CircularProgress size={20} /> : <AddIcon />}
                                                        onClick={handleAddAdminUser}
                                                        variant="contained"
                                                        size="small"
                                                        disabled={isAddingAdmin}
                                                        sx={{ mt: 1 }}
                                                    >
                                                        {isAddingAdmin ? 'Adding...' : 'Add Admin'}
                                                    </Button>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Company Links</Typography>
                                    {isLoadingLinks ? (
                                        <CircularProgress />
                                    ) : providerLinks.length > 0 ? (
                                        <List>
                                            {providerLinks.map((link) => {
                                                const company = companyDetails[link.company] || {};
                                                const isLoadingDetails = isLoadingCompanyDetails[link.company];
                                                return (
                                                    <ListItem key={link._id} sx={{ flexDirection: 'column', alignItems: 'flex-start', border: '1px solid #e0e0e0', borderRadius: '4px', mb: 2, p: 2 }}>
                                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                                            <Typography variant="h6">
                                                                {isLoadingDetails ? <CircularProgress size={20} /> : company.name || 'N/A'}
                                                            </Typography>
                                                            <Chip 
                                                                label={link.status} 
                                                                color={getStatusColor(link.status)} 
                                                                size="small" 
                                                            />
                                                        </Box>
                                                        <Typography variant="body2" color="textSecondary">Legal Name: {isLoadingDetails ? '...' : company.legalName || 'N/A'}</Typography>
                                                        <Typography variant="body2" color="textSecondary">Domain: {isLoadingDetails ? '...' : company.domain || 'N/A'}</Typography>
                                                        <Typography variant="body2" color="textSecondary">Company ID: {company._id || 'N/A'}</Typography>
                                                        <Typography variant="body2" color="textSecondary">Link ID: {link._id}</Typography>
                                                        <Typography variant="body2" color="textSecondary">Created: {formatDate(link.createdAt)}</Typography>
                                                        <Typography variant="body2" color="textSecondary">Updated: {formatDate(link.updatedAt)}</Typography>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    ) : (
                                        <Typography>No company links found for this provider.</Typography>
                                    )}
                            </>
                        )}
                    </Box>
                </Drawer>

                <Dialog open={addProviderDialogOpen} onClose={() => setAddProviderDialogOpen(false)}>
                    <DialogTitle>Add New Tax Benefit Provider</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="name"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newProviderData.name}
                            onChange={(e) => setNewProviderData({...newProviderData, name: e.target.value})}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            required
                        />
                        <TextField
                            margin="dense"
                            name="legalName"
                            label="Legal Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newProviderData.legalName}
                            onChange={(e) => setNewProviderData({...newProviderData, legalName: e.target.value})}
                            error={!!formErrors.legalName}
                            helperText={formErrors.legalName}
                            required
                        />
                        <TextField
                            margin="dense"
                            name="address"
                            label="Address"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newProviderData.address}
                            onChange={(e) => setNewProviderData({...newProviderData, address: e.target.value})}
                        />
                        <TextField
                            margin="dense"
                            name="domain"
                            label="Domain"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newProviderData.domain}
                            onChange={(e) => setNewProviderData({...newProviderData, domain: e.target.value})}
                        />
                        <TextField
                            margin="dense"
                            name="logoUrl"
                            label="Logo URL"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newProviderData.logoUrl}
                            onChange={(e) => setNewProviderData({...newProviderData, logoUrl: e.target.value})}
                        />
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1">Admin Users</Typography>
                            {newProviderData.adminUsers.map((admin, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <TextField
                                        size="small"
                                        label="First Name"
                                        value={admin.firstName}
                                        onChange={(e) => handleAdminInputChange(index, 'firstName', e.target.value)}
                                        sx={{ mr: 1 }}
                                    />
                                    <TextField
                                        size="small"
                                        label="Last Name"
                                        value={admin.lastName}
                                        onChange={(e) => handleAdminInputChange(index, 'lastName', e.target.value)}
                                        sx={{ mr: 1 }}
                                    />
                                    <TextField
                                        size="small"
                                        label="Email"
                                        value={admin.email}
                                        onChange={(e) => handleAdminInputChange(index, 'email', e.target.value)}
                                        sx={{ mr: 1 }}
                                    />
                                    <IconButton onClick={() => handleRemoveAdmin(index)} size="small">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button 
                                startIcon={<PersonAddIcon />} 
                                onClick={handleAddAdmin}
                                sx={{ mt: 2 }}
                            >
                                Add Admin User
                            </Button>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddProviderDialogOpen(false)}>Cancel</Button>
                        <Button
                            onClick={handleAddProvider}
                            variant="contained"
                            color="primary"
                            disabled={isAddingProvider}
                        >
                            {isAddingProvider ? 'Adding...' : 'Add Provider'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Deletion"}
                    </DialogTitle>
                    <DialogContent>
                        <Typography id="alert-dialog-description">
                            Are you sure you want to delete the tax benefit provider "{providerToDelete?.name}"? This action cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleDeleteProvider} color="error" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
};

export default MydraBenefitProvidersPage;