// // EducationProviderInfo.js
// import React from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   CardMedia,
//   Avatar,
//   Typography,
//   Link,
//   Grid,
//   Paper,
//   Chip,
//   CircularProgress,
//   Button,
//   TextField,
//   LinearProgress,
//   Collapse,
//   IconButton,

// } from '@mui/material';
// import SchoolIcon from '@mui/icons-material/School';
// import PublicIcon from '@mui/icons-material/Public';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import LanguageIcon from '@mui/icons-material/Language';
// import PeopleIcon from '@mui/icons-material/People';
// import WorkIcon from '@mui/icons-material/Work';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import MapIcon from '@mui/icons-material/Map'; // Import the Map icon (or your Google Maps icon)
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// import RatingsReviews from '../RatingsReviews/RatingsReviews';
// import { green, red, yellow } from '@mui/material/colors';

// // import use state
// import { useState } from 'react';
// import { useParams } from 'react-router-dom';

// // Helper function to determine the color based on the score
// const getScoreColor = (score) => {
//     if (score < 40) return red[500];
//     if (score < 70) return yellow[700];
//     return green[500];
//   };

  
// const EducationProviderInfo = ({ provider, statusAnalysis, handleRetryClick, getContentTypeDescription, handleAnalyzeClick, refreshProviderDetails, token }) => {
//     const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//     const { site_id } = useParams(); // Get the site_id from the URL parameters

//     const cleanUrl = (url) => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

//     const [status, setStatus] = useState(provider.status); 
//     // const [statusClassification, setStatusClassification] = useState(provider.status_classification);
//     const [expanded, setExpanded] = useState(true); // State for managing collapse


//     // New state for edit mode
//     const [isEditMode, setIsEditMode] = useState(false);

//     const [buttonText, setButtonText] = useState('Edit'); // New state for managing button text
//     const [isSaving, setIsSaving] = useState(false);

//     // Score
//     const score = provider.score || 0; // Ensure there's a default value
//     const scoreColor = getScoreColor(score);

//     // New state for updated provider details
//     const [updatedProviderDetails, setUpdatedProviderDetails] = useState({
//         companyLocation: provider.linkedin_data?.company_location || '',
//         yearFounded: provider.linkedin_data?.year_founded || '',
//         numberOfEmployees: provider.linkedin_data?.number_of_employees || '',
//         numberOfAlumniOrFollowers: provider.linkedin_data?.number_of_alumni_or_followers || '',
//     });

//     // Function to toggle edit mode
//     const toggleEditMode = () => {
//         setIsEditMode(!isEditMode);
//     };

//     // Function to handle input change
//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setUpdatedProviderDetails(prevDetails => ({
//             ...prevDetails,
//             [name]: value,
//         }));
//     };
    
//     const handleExpandClick = () => {
//         setExpanded(!expanded);
//       };


//     // Function to submit updated details
//     const submitUpdates = async () => {
//         // set status to loading
//         setIsEditMode(false); // Exit edit mode immediately
//         setIsSaving(true); // Start saving operation

//         setButtonText('Saving...'); // Change button text to "Saving..."
//         setStatus('loading'); // Optional: Indicate loading status

//         // Simulate a save operation
//         setTimeout(async () => {
//             // Your existing save logic here
//             const payload = {
//                 company_location: updatedProviderDetails.companyLocation,
//                 year_founded: updatedProviderDetails.yearFounded,
//                 number_of_employees: updatedProviderDetails.numberOfEmployees,
//                 number_of_alumni_or_followers: updatedProviderDetails.numberOfAlumniOrFollowers,
//             };

//             const response = await fetch(`${API_BASE_URL}/update-education-provider/${site_id}`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(payload),
//             });

//             if (response.ok) {
//                 // Update the status to completed
//                 setStatus('completed');
//                 // After simulating the save operation, call the refreshProviderDetails callback
//                 refreshProviderDetails(); // Use the prop to refresh provider details
//                 // Optionally wait a few seconds to ensure the data has been refreshed before resetting the button text
//                 setTimeout(() => {
//                     setIsSaving(false); // Saving operation completed
//                     setButtonText('Edit');
//                     // Reset any other states as needed
//                     setStatus('completed');
//                 }, 5000); // Adjust this time as needed based on your application

//             } else {
//                 // Handle error case
//                 setStatus('error');
//                 console.error('Failed to update provider details');
//             }

//             // After save operation and potential data refresh, reset button text to "Edit"
//             setButtonText('Edit');

//         }, 5000); // Simulate save operation taking 5 seconds
//     }

//   return (
//     <>
//      {provider && (
//             <>
//                 <Card sx={{ mb: 2 }}>
//                     {
//                         provider.linkedin_data?.background_cover_image_url ? (
//                             <CardMedia
//                                 component="img"
//                                 height="140"
//                                 image={provider.linkedin_data.background_cover_image_url}
//                                 alt="LinkedIn Cover"
//                             />
//                         ) : (
//                             <Box
//                                 width="100%" // Ensures the Box takes the full width
//                                 sx={{
//                                     backgroundImage: 'linear-gradient(to bottom, #E0E0E0, #FFFFFF)',
//                                     display: 'flex',
//                                     justifyContent: 'center',
//                                     alignItems: 'center',
//                                     height: '140px', // Set a minimum height
//                                     border: '1px solid #E0E0E0', // Optional: add a border
//                                     boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Optional: add a shadow
//                                     // More styling here to match your CardMedia or overall design
//                                 }}
//                             >
//                                 {/* Placeholder content, e.g., an icon or text */}
//                                 <Typography variant="caption" color="textSecondary">No LinkedIn Cover Available</Typography>
//                                 {/* You can also use MUI icons */}
//                             </Box>
//                         )
//                     }
//                     <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 2 }}>
//                         <Avatar
//                             alt={provider.name || provider.linkedin_data?.company_name}
//                             src={provider.linkedin_data?.profile_pic_url || "/path/to/default/profile/image.jpg"} // Placeholder image if no URL is provided
//                             sx={{ width: 60, height: 60, mr: 2, mt: 2}}
//                         />
//                         <Typography 
//                             variant="h5" 
//                             sx = {{
//                                 mt: 2
//                             }} 
//                             >{provider.name || 'Unknown Provider'}</Typography>
//                     </Box>
//                 </Card>
                
                
//                 <Grid container spacing={2}>
//                     <Grid item xs={12}>
//                         <Card sx={{ bgcolor: '#f5f5f5' }}>
//                             <CardContent>
                        
//                                 <Box display="flex" justifyContent="space-between" alignItems="center">
//                                     <Typography variant="h6" gutterBottom component="div">
//                                         <PublicIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }} />
//                                         General Info
//                                     </Typography>
//                                     <IconButton onClick={handleExpandClick}>
//                                         {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                                     </IconButton>
//                                 </Box>
//                                 <Collapse in={expanded} timeout="auto" unmountOnExit>
//                                     <Box sx={{ pl: 2 }}>
//                                         <Link href={provider.url} target="_blank" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                                             <LanguageIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />{cleanUrl(provider.url)}
//                                         </Link>
//                                         <Link href={provider.linkedin_url} target="_blank" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                                             <LinkedInIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />{cleanUrl(provider.linkedin_url)}
//                                         </Link>
//                                         {provider?.google_reviews?.google_maps_url ? (
//                                             <Link href={provider.google_reviews.google_maps_url} target="_blank" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                                                 <MapIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />Open in Google Maps
//                                             </Link>
//                                         ) : (
//                                             <Typography sx={{ display: 'flex', alignItems: 'center', mb: 1, color: 'text.secondary' }}>
//                                                 <MapIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />Google Maps URL not available
//                                             </Typography>
//                                         )}
//                                         {/* Provider Score */}
//                                         <Box sx={{ mt: 2, p: 2, border: '1px solid', borderColor: scoreColor, borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                                             <Typography variant="h7" component="span">
//                                                 Provider Score:
//                                             </Typography>
//                                             {provider.score ? (
//                                                 <>
//                                                 <Typography variant="h4" component="span" sx={{ color: scoreColor, ml: 2 }}>
//                                                     {score.toFixed(2)}
//                                                 </Typography>
//                                                 <Box sx={{ width: '100%', ml: 2 }}>
//                                                     <LinearProgress
//                                                     variant="determinate"
//                                                     value={score}
//                                                     sx={{
//                                                         height: 10,
//                                                         borderRadius: 5,
//                                                         [`& .MuiLinearProgress-bar`]: {
//                                                         backgroundColor: scoreColor,
//                                                         },
//                                                     }}
//                                                     />
//                                                 </Box>
//                                                 </>
//                                             ) : (
//                                                 <Typography variant="body2" color="text.secondary">
//                                                 Score not available
//                                                 </Typography>
//                                             )}
//                                         </Box>
//                                     </Box>
//                                 </Collapse>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                 </Grid>

//                 <Grid item xs={12} mt={2}>
//                     <Card sx={{ borderLeft: 5, borderColor: 'primary.main' }}>
//                     <CardContent>
//                         <Box display="flex" justifyContent="space-between" alignItems="center">
//                         <Typography variant="h6">Summary</Typography>
//                         <IconButton onClick={() => handleExpandClick('summary')}>
//                             {expandedSummary ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                         </IconButton>
//                         {isEditMode ? (
//                             <Box>
//                             <Button variant="contained" size="small" color="primary" onClick={submitUpdates}>Save</Button>
//                             <Button variant="outlined" size="small" color="secondary" onClick={() => setIsEditMode(false)} sx={{ ml: 1 }}>Cancel</Button>
//                             </Box>
//                         ) : (
//                             <Button variant="contained" size="small" onClick={toggleEditMode} disabled={isSaving}>{buttonText}</Button>
//                         )}
//                         </Box>
//                         <Grid container spacing={1} mb={0} mt={0} justifyContent="space-around">
//                         <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
//                             <LocationOnIcon color="primary" />
//                             <Typography variant="subtitle2" gutterBottom>
//                             Location
//                             </Typography>
//                             {isEditMode ? (
//                             <TextField
//                                 variant="outlined"
//                                 label="Location"
//                                 name="companyLocation"
//                                 value={updatedProviderDetails.companyLocation}
//                                 onChange={handleInputChange}
//                                 fullWidth
//                                 margin="dense"
//                             />
//                             ) : (
//                             <Typography variant="body1">{provider.linkedin_data?.company_location}</Typography>
//                             )}
//                         </Grid>
//                         <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
//                             <CalendarTodayIcon color="primary" />
//                             <Typography variant="subtitle2" gutterBottom>
//                             Founded
//                             </Typography>
//                             {isEditMode ? (
//                             <TextField
//                                 type="number"
//                                 variant="outlined"
//                                 label="Year founded"
//                                 name="yearFounded"
//                                 value={updatedProviderDetails.yearFounded}
//                                 onChange={handleInputChange}
//                                 fullWidth
//                                 margin="dense"
//                             />
//                             ) : (
//                             <Typography variant="h6">{provider.linkedin_data?.year_founded}</Typography>
//                             )}
//                         </Grid>
//                         <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
//                             <WorkIcon color="primary" />
//                             <Typography variant="subtitle2" gutterBottom>
//                             Employees
//                             </Typography>
//                             {isEditMode ? (
//                             <TextField
//                                 type="number"
//                                 variant="outlined"
//                                 label="Number of employees"
//                                 name="numberOfEmployees"
//                                 value={updatedProviderDetails.numberOfEmployees}
//                                 onChange={handleInputChange}
//                                 fullWidth
//                                 margin="dense"
//                             />
//                             ) : (
//                             <Typography variant="h6">{provider.linkedin_data?.number_of_employees}</Typography>
//                             )}
//                         </Grid>
//                         <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
//                             <PeopleIcon color="primary" />
//                             <Typography variant="subtitle2" gutterBottom>
//                             Alumni/Followers
//                             </Typography>
//                             {isEditMode ? (
//                             <TextField
//                                 type="number"
//                                 variant="outlined"
//                                 label="Number of Alumni/Followers"
//                                 name="numberOfAlumniOrFollowers"
//                                 value={updatedProviderDetails.numberOfAlumniOrFollowers}
//                                 onChange={handleInputChange}
//                                 fullWidth
//                                 margin="dense"
//                             />
//                             ) : (
//                             <Typography variant="h6">{provider.linkedin_data?.number_of_alumni_or_followers}</Typography>
//                             )}
//                         </Grid>
//                         </Grid>
//                     </CardContent>
//                     </Card>
//                 </Grid>

                
                

//                 <RatingsReviews providerData={provider} />

//                 <Grid item xs={12} mt={2}>
//                     <Card sx={{ borderLeft: 5, borderColor: 'primary.main' }}>
//                         <CardContent>
//                             <Box display="flex" justifyContent="space-between" alignItems="center">
//                                 <Typography variant="h6">Reviews</Typography>
//                                 <IconButton onClick={() => handleExpandClick('reviews')}>
//                                     {expandedReviews ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                                 </IconButton>
//                             </Box>
//                             <Collapse in={expandedReviews} timeout="auto" unmountOnExit>
//                                 <RatingsReviews providerData={provider} />
//                             </Collapse>
//                         </CardContent>
//                     </Card>
//                 </Grid>


//                 <Grid item xs={12} mt={2} mb={4}>
//                     <Card sx={{ borderLeft: 5, borderColor: 'primary.main' }}>
//                         <CardContent>
//                             <Box display="flex" justifyContent="space-between" alignItems="center">
//                                 <Typography variant="h6">Education Provider Analysis</Typography>
//                                 <IconButton onClick={() => handleExpandClick('analysis')}>
//                                     {expandedAnalysis ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                                 </IconButton>
//                             </Box>
//                             <Collapse in={expandedAnalysis} timeout="auto" unmountOnExit>
//                                 <Paper elevation={2} sx={{ my: 2, p: 2, bgcolor: 'grey.100' }}>
//                                     <Typography variant="h6" gutterBottom component="div">
//                                     <SchoolIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }} />
//                                     Education Provider Analysis
//                                     </Typography>
//                                     <Box sx={{ pl: 2 }}>
//                                     {provider.status_ep_analysis === 'processing' ? (
//                                         <Box display="flex" alignItems="center" justifyContent="center">
//                                         <CircularProgress />
//                                         <Typography variant="body2" sx={{ ml: 2, mb: 2, mt: 2 }}>Processing...</Typography>
//                                         </Box>
//                                     ) : provider.status_ep_analysis === 'completed' ? (
//                                         <>
//                                         <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                             Description:
//                                         </Typography>
//                                         <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
//                                             {provider.education_provider_analysis?.business_description}
//                                         </Typography>
//                                         <Box display="flex" flexWrap="wrap" sx={{ mb: 2 }}>
//                                             <Box mr={4} mb={2}>
//                                                 <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                                 Accredited:
//                                                 </Typography>
//                                                 <Typography variant="body2" gutterBottom>
//                                                 {provider.education_provider_analysis?.accredited ? 'Yes' : 'No'}
//                                                 </Typography>
//                                             </Box>
//                                             <Box mr={4} mb={2}>
//                                                 <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                                 Content Type:
//                                                 </Typography>
//                                                 <Typography variant="body2" gutterBottom>
//                                                 {getContentTypeDescription(provider.education_provider_analysis?.content_type)}
//                                                 </Typography>
//                                             </Box>
//                                             <Box mb={0}>
//                                                 <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                                 Accreditations:
//                                                 </Typography>
//                                                 <Box display="flex" flexWrap="wrap" sx={{ mb: 1 }}>
//                                                 {provider.education_provider_analysis?.accreditations?.map((accreditation, index) => (
//                                                     <Chip key={index} label={accreditation} variant="outlined" color="primary" size="small" sx={{ mr: 0.5, mb: 0.5 }} />
//                                                 ))}
//                                                 </Box>
//                                             </Box>
//                                         </Box>
                                    
//                                         <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                             Explanation:
//                                         </Typography>
//                                         <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
//                                             {provider.education_provider_analysis?.explanation_for_course_type}
//                                         </Typography>
//                                         </>
//                                     ) : (
//                                         <Typography variant="body2">Analysis not provided.</Typography>
//                                     )}
//                                     </Box>
//                                 </Paper>
//                             </Collapse>
//                         </CardContent>
//                     </Card>
//                 </Grid>





//                 {/* Education Provider Analysis Section */}
//                 {/* {provider && (
//                     <Paper elevation={2} sx={{ my: 2, p: 2, bgcolor: 'grey.100' }}>
//                     <Typography variant="h6" gutterBottom component="div">
//                     <SchoolIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }} />
//                     Education Provider Analysis
//                     </Typography>
//                     <Box sx={{ pl: 2 }}>
//                     {provider.status_ep_analysis === 'processing' ? (
//                         <Box display="flex" alignItems="center" justifyContent="center">
//                         <CircularProgress />
//                         <Typography variant="body2" sx={{ ml: 2, mb: 2, mt: 2 }}>Processing...</Typography>
//                         </Box>
//                     ) : provider.status_ep_analysis === 'completed' ? (
//                         <>
//                         <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                             Description:
//                         </Typography>
//                         <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
//                             {provider.education_provider_analysis?.business_description}
//                         </Typography>
//                         <Box display="flex" flexWrap="wrap" sx={{ mb: 2 }}>
//                             <Box mr={4} mb={2}>
//                                 <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                 Accredited:
//                                 </Typography>
//                                 <Typography variant="body2" gutterBottom>
//                                 {provider.education_provider_analysis?.accredited ? 'Yes' : 'No'}
//                                 </Typography>
//                             </Box>
//                             <Box mr={4} mb={2}>
//                                 <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                 Content Type:
//                                 </Typography>
//                                 <Typography variant="body2" gutterBottom>
//                                 {getContentTypeDescription(provider.education_provider_analysis?.content_type)}
//                                 </Typography>
//                             </Box>
//                             <Box mb={0}>
//                                 <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                                 Accreditations:
//                                 </Typography>
//                                 <Box display="flex" flexWrap="wrap" sx={{ mb: 1 }}>
//                                 {provider.education_provider_analysis?.accreditations?.map((accreditation, index) => (
//                                     <Chip key={index} label={accreditation} variant="outlined" color="primary" size="small" sx={{ mr: 0.5, mb: 0.5 }} />
//                                 ))}
//                                 </Box>
//                             </Box>
//                         </Box>
                    
//                         <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
//                             Explanation:
//                         </Typography>
//                         <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
//                             {provider.education_provider_analysis?.explanation_for_course_type}
//                         </Typography>
//                         </>
//                     ) : (
//                         <Typography variant="body2">Analysis not provided.</Typography>
//                     )}
//                     </Box>
//                     </Paper>
        
//                 )} */}
//             </>
//         )}
//     </>
//     );
// }


 
// export default EducationProviderInfo;



// EducationProviderInfo.js
import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Avatar,
  Typography,
  Link,
  Grid,
  Paper,
  Chip,
  CircularProgress,
  Button,
  TextField,
  LinearProgress,
  Collapse,
  IconButton,
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PublicIcon from '@mui/icons-material/Public';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleIcon from '@mui/icons-material/People';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map'; // Import the Map icon (or your Google Maps icon)
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RatingsReviews from '../RatingsReviews/RatingsReviews';
import { green, red, yellow } from '@mui/material/colors';
import { useParams } from 'react-router-dom';

// Helper function to determine the color based on the score
const getScoreColor = (score) => {
  if (score < 40) return red[500];
  if (score < 70) return yellow[700];
  return green[500];
};

const EducationProviderInfo = ({ provider, statusAnalysis, handleRetryClick, getContentTypeDescription, handleAnalyzeClick, refreshProviderDetails, token }) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { site_id } = useParams(); // Get the site_id from the URL parameters

  const cleanUrl = (url) => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

  const [status, setStatus] = useState(provider.status); 
  const [isEditMode, setIsEditMode] = useState(false);
  const [buttonText, setButtonText] = useState('Edit'); // New state for managing button text
  const [isSaving, setIsSaving] = useState(false);
  const [expanded, setExpanded] = useState(true); // State for managing collapse

  // Score
  const score = provider.score || 0; // Ensure there's a default value
  const scoreColor = getScoreColor(score);

  // New state for updated provider details
  const [updatedProviderDetails, setUpdatedProviderDetails] = useState({
    companyLocation: provider.linkedin_data?.company_location || '',
    yearFounded: provider.linkedin_data?.year_founded || '',
    numberOfEmployees: provider.linkedin_data?.number_of_employees || '',
    numberOfAlumniOrFollowers: provider.linkedin_data?.number_of_alumni_or_followers || '',
  });

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProviderDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Function to submit updated details
  const submitUpdates = async () => {
    // set status to loading
    setIsEditMode(false); // Exit edit mode immediately
    setIsSaving(true); // Start saving operation

    setButtonText('Saving...'); // Change button text to "Saving..."
    setStatus('loading'); // Optional: Indicate loading status

    // Simulate a save operation
    setTimeout(async () => {
      // Your existing save logic here
      const payload = {
        company_location: updatedProviderDetails.companyLocation,
        year_founded: updatedProviderDetails.yearFounded,
        number_of_employees: updatedProviderDetails.numberOfEmployees,
        number_of_alumni_or_followers: updatedProviderDetails.numberOfAlumniOrFollowers,
      };

      const response = await fetch(`${API_BASE_URL}/update-education-provider/${site_id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Update the status to completed
        setStatus('completed');
        // After simulating the save operation, call the refreshProviderDetails callback
        refreshProviderDetails(); // Use the prop to refresh provider details
        // Optionally wait a few seconds to ensure the data has been refreshed before resetting the button text
        setTimeout(() => {
          setIsSaving(false); // Saving operation completed
          setButtonText('Edit');
          // Reset any other states as needed
          setStatus('completed');
        }, 5000); // Adjust this time as needed based on your application

      } else {
        // Handle error case
        setStatus('error');
        console.error('Failed to update provider details');
      }

      // After save operation and potential data refresh, reset button text to "Edit"
      setButtonText('Edit');

    }, 5000); // Simulate save operation taking 5 seconds
  }

  return (
    <>
      {provider && (
        <>
          <Card sx={{ mb: 2 }}>
            {
              provider.linkedin_data?.background_cover_image_url ? (
                <CardMedia
                  component="img"
                  height="140"
                  image={provider.linkedin_data.background_cover_image_url}
                  alt="LinkedIn Cover"
                />
              ) : (
                <Box
                  width="100%" // Ensures the Box takes the full width
                  sx={{
                    backgroundImage: 'linear-gradient(to bottom, #E0E0E0, #FFFFFF)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '140px', // Set a minimum height
                    border: '1px solid #E0E0E0', // Optional: add a border
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Optional: add a shadow
                    // More styling here to match your CardMedia or overall design
                  }}
                >
                  {/* Placeholder content, e.g., an icon or text */}
                  <Typography variant="caption" color="textSecondary">No LinkedIn Cover Available</Typography>
                  {/* You can also use MUI icons */}
                </Box>
              )
            }
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 2 }}>
              <Avatar
                alt={provider.name || provider.linkedin_data?.company_name}
                src={provider.linkedin_data?.profile_pic_url || "/path/to/default/profile/image.jpg"} // Placeholder image if no URL is provided
                sx={{ width: 60, height: 60, mr: 2, mt: 2 }}
              />
              <Typography
                variant="h5"
                sx={{
                  mt: 2
                }}
              >{provider.name || 'Unknown Provider'}</Typography>
            </Box>
          </Card>

          <Card sx={{ bgcolor: '#f5f5f5', mb: 4 }}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom component="div">
                  <PublicIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }} />
                  Education Provider Information
                </Typography>
                <IconButton onClick={handleExpandClick}>
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 2 }}>
                  <Link href={provider.url} target="_blank" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LanguageIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />{cleanUrl(provider.url)}
                  </Link>
                  <Link href={provider.linkedin_url} target="_blank" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LinkedInIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />{cleanUrl(provider.linkedin_url)}
                  </Link>
                  {provider?.google_reviews?.google_maps_url ? (
                    <Link href={provider.google_reviews.google_maps_url} target="_blank" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <MapIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />Open in Google Maps
                    </Link>
                  ) : (
                    <Typography sx={{ display: 'flex', alignItems: 'center', mb: 1, color: 'text.secondary' }}>
                      <MapIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />Google Maps URL not available
                    </Typography>
                  )}
                  {/* Provider Score */}
                  <Box sx={{ mt: 2, p: 2, border: '1px solid', borderColor: scoreColor, borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h7" component="span">
                      Provider Score:
                    </Typography>
                    {provider.score ? (
                      <>
                        <Typography variant="h4" component="span" sx={{ color: scoreColor, ml: 2 }}>
                          {score.toFixed(2)}
                        </Typography>
                        <Box sx={{ width: '100%', ml: 2 }}>
                          <LinearProgress
                            variant="determinate"
                            value={score}
                            sx={{
                              height: 10,
                              borderRadius: 5,
                              [`& .MuiLinearProgress-bar`]: {
                                backgroundColor: scoreColor,
                              },
                            }}
                          />
                        </Box>
                      </>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        Score not available
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box mt={2}>
                  <Card sx={{ borderLeft: 5, borderColor: 'primary.main' }}>
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                         <Typography variant="h6">Summary</Typography>
                        <Box>    
                            {isEditMode ? (
                            <Box>
                                <Button variant="contained" size="small" color="primary" onClick={submitUpdates}>Save</Button>
                                <Button variant="outlined" size="small" color="secondary" onClick={() => setIsEditMode(false)} sx={{ ml: 1 }}>Cancel</Button>
                            </Box>
                            ) : (
                            <Button variant="contained" size="small" onClick={toggleEditMode} disabled={isSaving}>{buttonText}</Button>
                            )}
                        </Box>  
                      </Box>
                      <Grid container spacing={1} mb={0} mt={0} justifyContent="space-around">
                        <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
                          <LocationOnIcon color="primary" />
                          <Typography variant="subtitle2" gutterBottom>
                            Location
                          </Typography>
                          {isEditMode ? (
                            <TextField
                              variant="outlined"
                              label="Location"
                              name="companyLocation"
                              value={updatedProviderDetails.companyLocation}
                              onChange={handleInputChange}
                              fullWidth
                              margin="dense"
                            />
                          ) : (
                            <Typography variant="body1">{provider.linkedin_data?.company_location}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
                          <CalendarTodayIcon color="primary" />
                          <Typography variant="subtitle2" gutterBottom>
                            Founded
                          </Typography>
                          {isEditMode ? (
                            <TextField
                              type="number"
                              variant="outlined"
                              label="Year founded"
                              name="yearFounded"
                              value={updatedProviderDetails.yearFounded}
                              onChange={handleInputChange}
                              fullWidth
                              margin="dense"
                            />
                          ) : (
                            <Typography variant="h6">{provider.linkedin_data?.year_founded}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
                          <WorkIcon color="primary" />
                          <Typography variant="subtitle2" gutterBottom>
                            Employees
                          </Typography>
                          {isEditMode ? (
                            <TextField
                              type="number"
                              variant="outlined"
                              label="Number of employees"
                              name="numberOfEmployees"
                              value={updatedProviderDetails.numberOfEmployees}
                              onChange={handleInputChange}
                              fullWidth
                              margin="dense"
                            />
                          ) : (
                            <Typography variant="h6">{provider.linkedin_data?.number_of_employees}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">
                          <PeopleIcon color="primary" />
                          <Typography variant="subtitle2" gutterBottom>
                            Alumni/Followers
                          </Typography>
                          {isEditMode ? (
                            <TextField
                              type="number"
                              variant="outlined"
                              label="Number of Alumni/Followers"
                              name="numberOfAlumniOrFollowers"
                              value={updatedProviderDetails.numberOfAlumniOrFollowers}
                              onChange={handleInputChange}
                              fullWidth
                              margin="dense"
                            />
                          ) : (
                            <Typography variant="h6">{provider.linkedin_data?.number_of_alumni_or_followers}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>

                <Box mt={2}>
                  {/* <Card sx={{ borderLeft: 5, borderColor: 'primary.main' }}> */}
                    {/* <CardContent> */}
                      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Reviews</Typography>
                      </Box> */}
                      <RatingsReviews providerData={provider} />
                    {/* </CardContent> */}
                  {/* </Card> */}
                </Box>

                <Box mt={2}>
                  {/* <Card sx={{ borderLeft: 5, borderColor: 'primary.main' }}>
                    <CardContent> */}
                      <Typography variant="h6" gutterBottom component="div">
                        <SchoolIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }} />
                        Education Provider Analysis
                      </Typography>
                      <Box sx={{ pl: 2 }}>
                        {provider.status_ep_analysis === 'processing' ? (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress />
                            <Typography variant="body2" sx={{ ml: 2, mb: 2, mt: 2 }}>Processing...</Typography>
                          </Box>
                        ) : provider.status_ep_analysis === 'completed' ? (
                          <>
                            <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                              Description:
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
                              {provider.education_provider_analysis?.business_description}
                            </Typography>
                            <Box display="flex" flexWrap="wrap" sx={{ mb: 2 }}>
                              <Box mr={4} mb={2}>
                                <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                  Accredited:
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {provider.education_provider_analysis?.accredited ? 'Yes' : 'No'}
                                </Typography>
                              </Box>
                              <Box mr={4} mb={2}>
                                <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                  Content Type:
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {getContentTypeDescription(provider.education_provider_analysis?.content_type)}
                                </Typography>
                              </Box>
                              <Box mb={0}>
                                <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                  Accreditations:
                                </Typography>
                                <Box display="flex" flexWrap="wrap" sx={{ mb: 1 }}>
                                  {provider.education_provider_analysis?.accreditations?.map((accreditation, index) => (
                                    <Chip key={index} label={accreditation} variant="outlined" color="primary" size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                                  ))}
                                </Box>
                              </Box>
                            </Box>

                            <Typography variant="subtitle1" gutterBottom component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                              Explanation:
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
                              {provider.education_provider_analysis?.explanation_for_course_type}
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body2">Analysis not provided.</Typography>
                        )}
                      </Box>
                    {/* </CardContent>
                  </Card> */}
                </Box>
              </Collapse>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}

export default EducationProviderInfo;

