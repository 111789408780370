import React, { useState, useEffect } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';


function ContactsList({ companyId, authToken }) {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchContacts = async () => {
        console.log("Getting contacts for company id:", companyId);
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/get-contacts/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            });

            if (!response.ok) {
            throw new Error('Failed to fetch contacts');
            }

            const contactsData = await response.json();
            console.log('Contacts:', contactsData);

            // Convert the object of contacts into an array of contacts
            const contactsArray = Object.keys(contactsData).map(key => ({
                id: key,
                ...contactsData[key]
            }));

            // Sort the contacts array based on status
            const sortedContacts = contactsArray.sort((a, b) => {
                if (a.status === 'sent_to_pipedrive' && b.status !== 'sent_to_pipedrive') {
                  return -1;
                } else if (a.status !== 'sent_to_pipedrive' && b.status === 'sent_to_pipedrive') {
                  return 1;
                } else if (a.status === 'ai_discarded' && b.status !== 'ai_discarded') {
                  return 1;
                } else if (a.status !== 'ai_discarded' && b.status === 'ai_discarded') {
                  return -1;
                } else {
                  return 0;
                }
            });
            setContacts(sortedContacts);
        } catch (error) {
            console.error("Error fetching contacts:", error);
            setContacts([]); // Reset contacts on error

        } finally {
            setLoading(false);
        }
    };
    if (companyId) {
        fetchContacts();
    }
    fetchContacts();
  }, [companyId, authToken]);


  return (
    <TableContainer component={Paper} sx={{ marginTop: 2, boxShadow: 3 }}>
      <Table aria-label="contacts table">
        <TableHead>
          <TableRow sx={{ backgroundColor: 'primary.main', '& .MuiTableCell-root': { color: 'white', fontWeight: 'bold' } }}>
            <TableCell>Name</TableCell>
            <TableCell>Job Role</TableCell>
            <TableCell>Emails</TableCell>
            <TableCell>AI Validation</TableCell>
            <TableCell>Overall Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : contacts.length > 0 ? (
            contacts.map((contact, index) => (
                <TableRow key={index} hover>
                <TableCell>
                    {contact.linkedin_url ? (
                        <Link href={contact.linkedin_url} target="_blank" rel="noopener noreferrer">
                        <Typography>{contact.name}</Typography>
                        </Link>
                    ) : (
                        <Typography>{contact.name}</Typography>
                    )}
                </TableCell>
                <TableCell>
                    <Typography>
                        {`${contact.current_title || 'N/A'} @ ${contact.current_employer || 'N/A'} in ${contact.location || contact.country || 'N/A'}`}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {[contact.current_personal_email, contact.current_work_email]
                        .filter(email => email)  // Filter out null or undefined emails
                        .join(', ') || '-'}  
                    </Typography>
                </TableCell>
                
                <TableCell>
                    {[
                        { key: 'job_role_match', label: 'Job Role' },
                        { key: 'company_match', label: 'Company' },
                        { key: 'location_match', label: 'Location' },
                    ].map(({ key, label }) => (
                        <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                            <Typography component="span" sx={{ minWidth: '75px' }}>
                                {label}:
                            </Typography>
                            {contact.ai_validation?.[key] ? (
                                <CheckCircleIcon style={{ color: green[500] }} fontSize="small" />
                            ) : (
                                <CancelIcon style={{ color: red[500] }} fontSize="small" />
                            )}
                        </Box>
                    ))}
                </TableCell>
                <TableCell>
                    {contact.status === 'ai_discarded' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Typography>Discarded</Typography>
                            <CancelIcon color="error" />
                        </Box>
                    ) : contact.status === 'sent_to_pipedrive' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Typography>Sent to </Typography>
                            <img src={`${process.env.PUBLIC_URL}/pipedrive_logo.png`} alt="Pipedrive" style={{ height: '25px' }} />
                        </Box>
                    ) : (
                        <Typography>{contact.status}</Typography>
                    )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center"><Typography>No contacts found</Typography></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ContactsList;
