import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const QualityScoreBreakdown = ({ breakdown, qualityScoreCalculatedAt }) => {
  console.log("qualityScoreCalculatedAt", qualityScoreCalculatedAt);  
  const items = [
    { key: 'hasAgreement', label: 'Has Agreement' },
    { key: 'hasLinkedInProfilesWithImage', label: 'All LinkedIn Profiles Have Images' },
    { key: 'hasPrice', label: 'Has Price' },
    { key: 'hasUpcomingCohorts', label: 'Has Upcoming Cohorts' },
  ];

  if (!breakdown) {
    return (
      <Box sx={{ p: 1, bgcolor: 'background.paper', color: 'text.primary' }}>
        <Typography variant="subtitle2" gutterBottom>Quality Score Breakdown:</Typography>
        <Typography variant="body2">No data available</Typography>
      </Box>
    );
  }

  const getTimeAgo = (date) => {
    const now = new Date();
    const diff = now - new Date(date);
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return 'Just now';
  };

  return (
    <Box sx={{ p: 1, bgcolor: 'background.paper', color: 'text.primary' }}>
      <Typography variant="subtitle2" gutterBottom>Quality Score Breakdown:</Typography>
      {items?.map(item => (
        <Box key={item?.key} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          {breakdown[item?.key] > 0 ? (
            <CheckCircleIcon color="success" fontSize="small" sx={{ mr: 1 }} />
          ) : (
            <CancelIcon color="error" fontSize="small" sx={{ mr: 1 }} />
          )}
          <Typography variant="body2">{item?.label}</Typography>
        </Box>
      ))}
      {qualityScoreCalculatedAt && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="caption" display="block">
            Last calculated: {new Date(qualityScoreCalculatedAt).toLocaleString()}
          </Typography>
          <Typography variant="caption" display="block">
            ({getTimeAgo(qualityScoreCalculatedAt)})
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default QualityScoreBreakdown;
