
import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Paper, Box, CircularProgress } from '@mui/material'; // Import CircularProgress for loading spinner

import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for redirection
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import getAuth and onAuthStateChanged from firebase/auth

function EducationProviderForm() {
    const theme = useTheme();
    const navigate = useNavigate(); // Initialize the navigate function
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [authLoading, setAuthLoading] = useState(true); // New state for authentication loading


    const [provider, setProvider] = useState({
        name: '',
        url: '',
        linkedin_url: '',
        // Add other fields as necessary
    });

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, can proceed to show the form
                setAuthLoading(false);
            } else {
                // No user is signed in, redirect to login
                navigate('/login');
            }
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]);


    const getToken = async () => {
        setAuthLoading(true); // Start loading
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            const token = await user.getIdToken();
            setAuthLoading(false); // Stop loading
            return token;
        } else {
            console.log('User not logged in');
            navigate('/login'); // Redirect to login page if user is not logged in
            setAuthLoading(false); // Stop loading
            return null;
        }
    };

    const [loading, setLoading] = useState(false); // State to manage loading spinner visibility
    const [error, setError] = useState(''); // State to handle any error


    const handleChange = (e) => {
        const { name, value } = e.target;
        setProvider(prevState => ({
        ...prevState,
        [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        setError(''); // Reset error state
    
        const token = await getToken(); 
        if (!token) {
            setLoading(false); // Stop loading if there's an error
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/add-education-provider`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(provider),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const data = await response.json();
            console.log('Success:', data);
    
            // Use setTimeout to delay the redirection for 5 seconds
            setTimeout(() => {
                setLoading(false); // Stop loading after the delay

                // if data has "site_id" property, it means the provider was added successfully, redirect to the provider details page
                if (data.site_id) {
                    navigate(`/provider/${data.site_id}`); // Redirect to the provider details page
                } else {
                    navigate('/providers'); // Redirect to the providers page
                }

            }, 6000); 
    
        } catch (error) {
            console.error('Error:', error);
            // Display error message and stop loading after 5 seconds, giving the user time to see the spinner
            setTimeout(() => {
                setLoading(false); // Stop loading
                setError('Failed to add provider. Please try again.'); // Set error message
            }, 5000);
        }
    };



    return (

        <Container maxWidth="sm">

            <Typography variant="h4" align="center" sx={{ my: 4, fontWeight: 'bold', color: 'primary.main' }}>
                Add New Provider
            </Typography>
            {authLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>       
            ) : (
                <>
                    <Paper elevation={3} sx={{ p: 4, mt: 4, mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>    
                        {loading && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <CircularProgress sx={{ mb: 2 }} />
                                <Typography variant="h6">Adding Provider...</Typography>
                            </Box>
                        )}
                        {!loading && (
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="url"
                                    label="URL"
                                    name="url"
                                    autoComplete="url"
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="linkedin_url"
                                    label="LinkedIn Page URL"
                                    name="linkedin_url"
                                    autoComplete="url"
                                    onChange={handleChange}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </Button>
                                {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
                            </Box>
                        )}
                        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
                    </Paper>
                </>
            )}
        </Container>
    );
}

export default EducationProviderForm;
